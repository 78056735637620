// import './testStyles.css'
import './index.css';
import {useEffect} from 'react'
import ElevatorAccess from 'Components/World/ElevatorAccess'
import KeyGiver from 'Components/World/KeyGiver'
import Return from 'Pages/Return'
import { BrowserRouter as Router,Route,Routes } from 'react-router-dom'
import routerHistory from 'misc/routerHistory.js'
import Home from 'Pages/Home'
import HooksForZustand from 'Components/HooksForZustand';


//analytics
import ReactGA from 'react-ga4';
import useWorldState from 'Components/World/WorldStateManager';
import Two from 'Pages/Two';
import L2SubscriptionConfirmation from 'Pages/SubscriptionConfirmations/L2SubscriptionConfirmation';
const TRACKING_ID = "G-3R8WJPRNSR";
ReactGA.initialize(TRACKING_ID,{
  debug: process.env.NODE_ENV==="development",
  siteSpeedSampleRate: '100%'
});

// console.log('!! process.env',process.env)

const App = (props)=>{
  const {requestFace,fetchDisteudo} = useWorldState(state=>({requestFace:state.requestFace,fetchDisteudo:state.fetchDisteudo}))

  // return (<Router history={routerHistory}><Hub /></Router>)
  // return <KeyGiver />
  
  // useEffect(() => {
  //   ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  if (process.env.NODE_ENV === "development") { //render with test routes
    return (
      <Router history={routerHistory}>
          <HooksForZustand />
          {/* <PageViewTracker /> */}
          <Routes>
            {/* l2 subscription confirmation */}
            <Route path="/L2sc" element={<L2SubscriptionConfirmation />} />
            {/* Elevator Test */}
            <Route path="/panel" element={<ElevatorAccess />} />
            {/* KeyGiver Test */}
            <Route path="/keygiver" element={<KeyGiver />} />
            {/* 2 */}
            <Route path="/2" element={<Two />} />
            {/* RETURN */}
            <Route path="/return" element={<Return/>} />
            {/* HUB */}
            {/* <Route path="/hub" element={<Hub />} /> */}
            {/* <Route path="/hub/" element={<Home />} />
            <Route path="/hub/:subpage" element={<Home />} /> */}
            <Route path="/hub?/:subpage?/:postSlug?" element={<Home />} />
            {/* MAIN */}
            <Route path="/" element={<Home />} />
          </Routes>
        </Router>   
    )
  }
  return ( // no test routes for you
  <Router history={routerHistory}>
    <HooksForZustand />
    {/* <PageViewTracker /> */}
    <Routes>
      {/* l2 subscription confirmation */}
      <Route path="/L2sc" element={<L2SubscriptionConfirmation />} />
      {/* 2 */}
      <Route path="/2" element={<Two />} />
      {/* RETURN */}
      <Route path="/return" element={<Return/>} />
      {/* HUB */}
      {/* <Route path="/hub" element={<Hub />} /> */}
      {/* <Route path="/hub/" element={<Home />} />
      <Route path="/hub/:subpage" element={<Home />} /> */}
      <Route path="/hub?/:subpage?/:postSlug?" element={<Home />} />
      {/* MAIN */}
      <Route path="/" element={<Home />} />
    </Routes>
  </Router>   
  )


}

export default App

// export {useWorldState as useSharedWorldState};
// export {useUIState as useSharedUIState};