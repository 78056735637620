import { Link, useLocation } from 'react-router-dom';
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';

const HubNav=({})=>{
  const location = useLocation();
  const [currentPage,setCurrentPage] = useState("news");

  useEffect(()=>{
    if (location.pathname.indexOf("news") !== -1) {
      setCurrentPage("news")
    }
    else {
      setCurrentPage("who")
    }
  },[location])

  return (
    <div className={styles.HubNavWrapper}>
      <div className={styles.TLDCBadge}><p><span>TLDC</span> <span>The Portfolio Site of Teo Litto</span></p></div>
      <div className={styles.HubNav}>
        <Link className={currentPage==="news"? styles.currentPageLink : ''} to="/hub/news">News</Link>
        <Link className={currentPage==="who"? styles.currentPageLink : ''} to="/hub/who">Who?</Link>
      </div>
    </div>
  )
}

export default HubNav