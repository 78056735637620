import { useKTX2 } from '@react-three/drei';
// import useWorldState from "../WorldStateManager"; 
import { Suspense } from 'react';
import EleAlbedo from '../../../assets/tex/elePlane2.ktx2'
const ElePlane=({position,scale=[0.89,-0.89,0.89],...props})=>{
  // const worldRenderer = useWorldState(state=>state.worldRenderer)
  const [elePlaneDiffTex] = useKTX2([EleAlbedo]) 
  // const [elePlaneDiffTex,wallTextTex] = useLoader(KTX2Loader, ['assets/tex/elePlane2.ktx2','assets/tex/wallTextTex1024.ktx2'], (loader) => {
  //   loader.setTranscoderPath('/basis/')
  //   loader.detectSupport(gl)
  // })
  return (
    <>
    <Suspense fallback={null}>
       <mesh {...props} position={position} rotation={[0,Math.PI/2,0]} scale={scale}>
        {/* <meshBasicMaterial map={elePlaneDiffTex} color={0xff0000} transparent={false} alphaTest={0.5}/> */}
        {/* <planeBufferGeometry args={[10,10,1]} /> */}
        <planeGeometry attach="geometry" args={[61, 41, 1, 1]} />
        {/* <meshLambertMaterial attach="material" color="#ff0000" /> */}
        {/* <meshStandardMaterial attach="material" map={elePlaneDiffTex} alphaMap={elePlaneDiffTex} transparent={true} alphaTest={0.5}/> */}
        <meshStandardMaterial attach="material" map={elePlaneDiffTex} transparent={true}/>
      </mesh>
    </Suspense>
    </>
  )
}

export default ElePlane