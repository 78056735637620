import { useFrame } from '@react-three/fiber';
import { useEffect, useRef, useState } from 'react';

const SpriteAnim=({opacity=1,name='',onCompleteCallback=()=>{},numFrames=0,position=[0,0,0],scale=[10,10,10],spriteSheet,tilesX,tilesY,playMode="once",playSpeed=1,widthPx,heightPx,...props})=>{
  const spriteRef = useRef(); 
  const spriteTex = spriteSheet
  const privateSpriteTex = useRef(spriteTex.clone())
  const currentTile = useRef(0);
  const frameDelta = useRef(0);
  const isDone = useRef(false)
  // const [localOpacity,setLocalOpacity] = useState(0);
  useEffect(()=>{
    privateSpriteTex.current.repeat.set(1/tilesX,1/tilesY);
    privateSpriteTex.current.needsUpdate = true;
    // setTimeout(()=>{setLocalOpacity(opacity)},300)
  },[])

  useFrame((state,delta)=>{
    if (isDone.current) return
    if (frameDelta.current + (delta*1000) > 40) { // 25fps
      if (currentTile.current < numFrames-1) {
        currentTile.current = currentTile.current+1;

        //animate tex based on new currentTile
        const offsetX = (currentTile.current % tilesX) / tilesX
        const offsetY = (tilesY - Math.floor(currentTile.current/tilesX) - 1) / tilesY;
        privateSpriteTex.current.offset.x = offsetX;
        privateSpriteTex.current.offset.y = offsetY;

        // reset frame delta for next frame
        frameDelta.current = 0;
      }
      else if (currentTile.current === numFrames - 1) {
        isDone.current = true;
        onCompleteCallback();
      }
    }
    else {
      frameDelta.current = frameDelta.current + (delta*1000);
    }
  })
  return (
    <>
      <sprite name={name} key={name} ref={spriteRef} position={position} scale={scale} visible={!isDone.current} transparent={true} opacity={0}>
        <spriteMaterial name={`${name}_mat`} key={`${name}_mat`} map={privateSpriteTex.current}  />
      </sprite>
    </>
  )
}

export default SpriteAnim