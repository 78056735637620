import DisteudoGate from 'Components/World/DisteudoGate';
import styles from './styles.module.scss';
import TWO from '../../assets/img/TWO.jpg'
import NotifyForm from './NotifyForm';
const Two=({})=>{
  return (
    <div className={styles.Two}>
      <DisteudoGate>
        <img src={TWO} />
        <h3>Get notified when it's ready:</h3>
        <NotifyForm />
        <p>One email only will be sent.</p> 
        <p>There will be no spamming, selling/sharing of info, or other such bullshit.</p>
        <p>Don't lose your key in the meanwhile... &lt;_&lt; </p>
      </DisteudoGate>
    </div>
  )
}

export default Two