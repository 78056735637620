// import {useSphere} from '@react-three/cannon'
import { clamp } from 'lodash'
import { useState,useEffect, useRef, useMemo, useCallback, Suspense } from 'react'
import useWorldState from 'Components/World/WorldStateManager'
import { RigidBody } from '@react-three/rapier'
import SpriteAnim from '../SpriteAnim';
import { useLoader } from '@react-three/fiber';
import { TextureLoader } from 'three';
import SmokePuffDark from '../../../assets/sprite/smokePuffDark1.png'
import SmokePuffLight from '../../../assets/sprite/smokePuffLight1.png'

const colorSets = [
  ['#cccccc',0x616161,SmokePuffLight],
  ['#383838',0x151515,SmokePuffDark]
]

const BasicBall = ({name=`basicBall_${Date.now()}`,position=[0,0,0],mass=1,vel={x:0,y:0,z:0}})=>{
    const ballBodyRef = useRef();
    const {roomScale,addAccelAffectedBody,removeAccelAffectedBody} = useWorldState(state=>({roomScale:state.roomScale,addAccelAffectedBody:state.addAccelAffectedBody,removeAccelAffectedBody:state.removeAccelAffectedBody}))
    const [radius,setRadius] = useState(clamp((roomScale.h+roomScale.w)/32,3.14,6));
    const [deathAnim,setDeathAnim] = useState(null)
    // const [physRef, physApi] = useSphere(()=>({mass:mass,position:position, args:[radius], material:{restitution:1}}))
    // const ballName = useMemo(()=>{
    //   return `ball_${Date.now()}`
    // },[])
    useEffect(()=>{
      ballBodyRef.current.userData = !!ballBodyRef.current.userData? ballBodyRef.current.userData : {}
      ballBodyRef.current.userData.accelAmount = 1;
      ballBodyRef.current.userData.name = name;
      addAccelAffectedBody(ballBodyRef.current)
      return ()=>{
        removeAccelAffectedBody(ballBodyRef.current)
      }
    },[])


    const colorSet = useMemo(()=>{
      const colorSet =  colorSets[Math.round(Math.random()* (colorSets.length-1))]
      return colorSet;
    },[])

    // const deathSpriteTex = useMemo(()=>{
    //   const tex = deathTexSet[Math.round(Math.random())]
    //   return tex
    // },[])

    const deathSpriteTex = useLoader(TextureLoader,colorSet[2])

    const vaporize = useCallback((onCompleteCallback, deathPosition)=>{
      if (deathAnim) return;
      ballBodyRef.current.setEnabled(false);
      let deathPos = ballBodyRef.current.translation()
      deathPos = [deathPos.x,deathPos.y,deathPos.z]
      const deathAnimName = `${name}_deathAnim`
      setDeathAnim(<Suspense><SpriteAnim key={deathAnimName} name={deathAnimName} opacity={0.9} spriteSheet={deathSpriteTex} scale={[19.9,19.9,19.9]} position={deathPos} tilesX={8} tilesY={4} numFrames={25} onCompleteCallback={onCompleteCallback}/> </Suspense>)
    },[deathSpriteTex])

    return (
      <>
        {deathAnim}
        <RigidBody ref={ballBodyRef} colliders={"ball"} position={position} angularDamping={0.1} linearVelocity={[vel.x,vel.y,vel.z]} density={1} restitution={1.2} >
          <mesh castShadow receiveShadow name={name} body={ballBodyRef.current} vaporize={vaporize} visible={!deathAnim}>
            <sphereGeometry attach="geometry" args={[radius, 20, 20]} />  
            {/* <meshLambertMaterial attach="material" color="#cccccc" emissive={0x616161} /> */}
            <meshLambertMaterial attach="material" color={colorSet[0]} emissive={colorSet[1]} />
            {/* <BurnDecal /> */}
          </mesh>
        </RigidBody>
      </>
    )
}

export default BasicBall