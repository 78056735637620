import styles from './styles.module.scss';
import ModalClose from '../../../assets/img/ModalClose.png'
import { useCallback, useEffect } from 'react';
import useWorldState from 'Components/World/WorldStateManager';
import ReactGA from 'react-ga4';

const ExpandedPostModal=({content,setContent,subpage})=>{
  
  const {navTo} = useWorldState(state=>({navTo:state.navTo})) 

  useEffect(()=>{
    ReactGA.send({ hitType: "pageview", page: window.location.path, title: "Expanded Post View" });
  },[])

  const closeModal = useCallback(()=>{
    // setContent(null);
    navTo(`/hub/${subpage}`)
  },[])
  useEffect(()=>{
    const closeOnEsc = (e)=>{
      if (e.key === "Escape") {
        closeModal();
      }
      
    }
    window.addEventListener('keyup',closeOnEsc)
    return ()=>{
      window.removeEventListener('keyup',closeOnEsc)
    }
  },[closeModal])
  return (
    <div className={styles.ExpandedPostModal}>
      <img src={ModalClose} className={styles.closeBtn} onClick={closeModal} />
      <div className={styles.contentWrapper}>
        {content}
      </div>
    </div> 
  )
}

export default ExpandedPostModal