import styles from './styles.module.scss';
import DotGrid from './dotGrid';
import HangInThere from '../../../assets/img/hangInThere1.png'
const ContentBorder=({children})=>{
  return (
    <>
      <div className={styles.ContentBorder} >
        {/* <div className={styles.dotGrid} /> */}
        <DotGrid xCount={12} yCount={12} width={100} height={100} patternPad={0.75} />
        {children}
        <img src={HangInThere} className={styles.hangInThere} />
      </div>
    </>
  )
}

export default ContentBorder