import styles from './styles.module.scss';
import { useLayoutEffect,useCallback,useRef,useMemo,useEffect } from 'react';
import useUIState from 'Components/UI/UIStateManager';
import {ReactComponent as ScrollBtnTop} from 'assets/img/upTri.svg'
import { throttle } from 'lodash';
import { useLocation } from 'react-router-dom';
const maxScrollGapAtBottom = 600;
const minThumbHeight = 50;

const maxInertialTrackingLength = 5;
const inertiaBrakeRate = 0.99;

const HubScroll=({scrollRef,scrollZone,overflowWrapperRef,doScrollRef,expandedPostContent})=>{
  const {hubContentRef,hubScrollZoneRef,hubScrollThumbHeight,setHubScrollThumbHeight} = useUIState(state=>({hubContentRef:state.hubContentRef,hubScrollZoneRef:state.hubScrollZoneRef,hubScrollThumbHeight:state.hubScrollThumbHeight,setHubScrollThumbHeight:state.setHubScrollThumbHeight}))
  const location = useLocation();
  const isMMBScrolling = useRef(false);
  const MMBScrollStartPos = useRef({x:0,y:0});
  const MMBStartMarker = useMemo(()=>{
    const startMarker = document.createElement('div');
    startMarker.classList.add('MMBStartMarker');
    return startMarker
  },[])
  const hubScrollRef = useRef();
  const thumbRef = useRef({});
  // const scrollThumbHeight = useRef(minThumbHeight);
  const cursorStartY = useRef(0);
  const thumbStartY = useRef(0);
  const lastYDist = useRef(0);
  const thumbTop = useRef(0)
  const thumbBoundsRef = useRef();
  const isTouchScrolling = useRef(false);
  const touchScrollStartRef = useRef();
  const touchInertiaTracking = useRef([]);
  const currentTouchInertia = useRef(0);
  const inertialScrollInterval = useRef(null);
  const clearStaleInertiaTimer = useRef(null)
  const expandedPostContentRef = useRef(null);

  useEffect(()=>{
    expandedPostContentRef.current = expandedPostContent;
  },[expandedPostContent])
 
  const doScroll = useCallback((dist,updateThumb=true)=>{
   
    if (!hubContentRef?.current || Math.abs(dist)< 0.002 || !!expandedPostContentRef.current) return;
    
    const minScrollSpace = document.body.clientHeight - maxScrollGapAtBottom;
    const contentHeight = hubContentRef.current.clientHeight;

    const maxYTravel = -(contentHeight-minScrollSpace);
    const startY =  Number(hubContentRef?.current?.style?.transform.slice(17,-8)) || 0;
    const htmlHeight = document.body.clientHeight
    let newY = startY+dist;
    if (newY > 0) {
      newY = 0;
      clearStaleInertia()
      setTimeout(()=>{navigator.vibrate([5])},150)
    }
    else if (newY < -hubContentRef.current.clientHeight + (htmlHeight - ( maxScrollGapAtBottom))) {
      newY = -hubContentRef.current.clientHeight + (htmlHeight - ( maxScrollGapAtBottom)); 
      clearStaleInertia();
      setTimeout(()=>{navigator.vibrate([5])},150)
    }
    // hubContentRef.current.style.marginTop = `${newY}px`;
    hubContentRef.current.style.transform = `translate3d(0,${newY}px,0)`

    // calc new height of scrollOverflowWrapper if needed
    const scrollSpace = window.innerHeight + 60; // TODO CORRECTLY CALC THIS VALUE? (WAS 314)
    const distToEnd = newY - maxYTravel
    const hubContentBB = hubContentRef.current.getBoundingClientRect();
    if (distToEnd < scrollSpace) {
      // set height of scrollOverflowWrapper to distToEnd+minScrollSpace
      // document.querySelector('.styles_scrollOverflowWrapper__3YnfA').style.height=`${distToEnd+minScrollSpace}px`
      try {
        overflowWrapperRef.current.style.height=`${distToEnd+minScrollSpace+30}px`
      }
      catch (err){}
      
    }
    else {
      try {
        overflowWrapperRef.current.style.height = '';
      }
      catch (err){}
    }

    if (thumbRef?.current && updateThumb) {
      const thumbHeight = Number(thumbRef.current.style.height.substr(0,thumbRef.current.style.height.length-2));
      let yTravelPct = Math.abs((maxYTravel / (maxYTravel-thumbHeight))*100) - Math.abs((newY / (maxYTravel-thumbHeight))*100);
      const hubScrollBounds = hubScrollRef.current.getBoundingClientRect();
      yTravelPct = newY/maxYTravel;
      const thumbYTravelSpace = hubScrollBounds.height - thumbHeight;
      const thumbYPos = yTravelPct * thumbYTravelSpace;
      thumbRef.current.style.top=`${thumbYPos}px`;
    }

  },[hubContentRef,thumbRef,expandedPostContentRef])

  useEffect(()=>{
    doScrollRef.current = doScroll
  },[doScroll])

  const doResize = useCallback(()=>{
    doScroll(0.01);
  },[doScroll])

  
  const doMMBScroll = useCallback((e)=>{
    
    let MMBScrollDist = 0;

    const calcMMBScrollDist = (e)=>{
      MMBScrollDist = MMBScrollStartPos.current.y - e.clientY;
    } 
    const autoScrollMMB = (e)=>{ 
      const MMBScrollSpeed = 0.6;
      doScroll(MMBScrollDist * MMBScrollSpeed)
      if (!!isMMBScrolling.current) {window.requestAnimationFrame(autoScrollMMB)}
    }

    if (e.button !== 1) return;
    if (!!isMMBScrolling.current) { //already scrolling, remove cursor, enable mouse interactions, and disable auto-scroll
      document.body.parentNode.classList.remove('mmbScrolling');
      document.body.classList.remove('mmbScrolling');
      document.body.parentNode.removeEventListener('mousemove',calcMMBScrollDist)
      MMBStartMarker.remove();
      // document.querySelector('.MMBStartMarker').remove();
      isMMBScrolling.current = false;
      MMBScrollStartPos.current = {x:0,y:0}
    }
    else if (e.target.nodeName !== "IMG" && e.target.nodeName !== "A") { // start scroll only if not over image or link
      document.body.parentNode.classList.add('mmbScrolling');
      document.body.classList.add('mmbScrolling');
      document.body.parentNode.addEventListener('mousemove',calcMMBScrollDist)
      // add start pos marker      
      MMBStartMarker.style.left = `${e.clientX}px`;
      MMBStartMarker.style.top = `${e.clientY}px`;
      document.body.prepend(MMBStartMarker)
      isMMBScrolling.current = true;
      MMBScrollStartPos.current = {x:e.clientX,y:e.clientY}
      window.requestAnimationFrame(autoScrollMMB)
    }
    
  },[doScroll])

  const doWheelScroll = useCallback((e)=>{doScroll(-e.deltaY)},[doScroll])

  const clearStaleInertia = useCallback(()=>{
    clearInterval(inertialScrollInterval.current);
    touchInertiaTracking.current = [];
    currentTouchInertia.current = 0;
  },[])

  const endTouchScroll = useCallback((e)=>{
    clearTimeout(clearStaleInertiaTimer.current)
    if (e.changedTouches[0].identifier !== touchScrollStartRef.current.identifier) return
    isTouchScrolling.current = false;
    //remove listeners
    document.body.addEventListener('touchmove',updateTouchScroll)
    document.body.addEventListener('touchend',endTouchScroll)
    
    // launch inertia
    let inertiaSum = touchInertiaTracking.current.reduce((inertia,inertiaRecord)=>{return inertia+inertiaRecord},0)
    currentTouchInertia.current = (inertiaSum / touchInertiaTracking.current.length)
    touchInertiaTracking.current = [];
    if (Math.abs(currentTouchInertia.current) < 4.5) return // do not inertia for micro movements
    currentTouchInertia.current = currentTouchInertia.current * 0.6; //too jumpy so brake at start
    inertialScrollInterval.current = setInterval(()=>{
      if (Math.abs(currentTouchInertia.current) > 0.001) {
        doScroll(currentTouchInertia.current,false);
        // currentTouchInertia.current -= inertiaBrakeRate;
        currentTouchInertia.current = currentTouchInertia.current * inertiaBrakeRate;
      }
      else {        
        clearStaleInertia()
      }
    },1)
  },[doScroll])

  const updateInertia = useCallback(throttle((dist)=>{
    clearTimeout(clearStaleInertiaTimer.current)
    touchInertiaTracking.current.push(dist);
    if (touchInertiaTracking.current.length > maxInertialTrackingLength) {touchInertiaTracking.current.shift();}
    // clearStaleInertiaTimer.current = setTimeout(clearStaleInertia,100)
  },10),[])

  const updateTouchScroll = useCallback((e)=>{
    if (!!isTouchScrolling.current && e.changedTouches[0].identifier === touchScrollStartRef.current.identifier) {
      const dist = e.changedTouches[0].clientY - touchScrollStartRef.current.clientY;
      if (Math.abs(dist) < 2) {
        return;
      }
      e.preventDefault();
      e.stopPropagation();
      
      doScroll(dist);
      // update scroll pos for next update
      touchScrollStartRef.current = {identifier: touchScrollStartRef.current.identifier,clientY:e.changedTouches[0].clientY};
      // store inertia
      updateInertia(dist);
    }
  },[doScroll])

  const startTouchScroll = useCallback((e)=>{
    // currentTouchInertia.current = 0;
    clearInterval(inertialScrollInterval.current);
    // clearStaleInertia()
    // touchInertiaTracking.current = [];
    if (e.touches.length == 1) {
      touchScrollStartRef.current = {identifier:e.touches[0].identifier,clientY:e.touches[0].clientY};
      isTouchScrolling.current = true;
      document.body.addEventListener('touchmove',updateTouchScroll)
      document.body.addEventListener('touchend',endTouchScroll)
    }
  },[doScroll])




  useLayoutEffect(()=>{
    if (!hubContentRef?.current || !hubScrollZoneRef?.current) return;
    const minScrollHeight = document.body.clientHeight - maxScrollGapAtBottom;
    document.body.addEventListener('wheel',doWheelScroll)
    document.body.parentNode.addEventListener('mousedown',doMMBScroll)
    document.body.addEventListener('touchstart',startTouchScroll);
    window.addEventListener('resize',doResize)
    return ()=>{
      document.body.removeEventListener('wheel',doWheelScroll);
      document.body.parentNode.removeEventListener('mousedown',doMMBScroll);
      window.removeEventListener('resize',doResize);
    }
  },[doWheelScroll,doMMBScroll]) // on route change we recalc thumb height

  
  
  const doDrag = useCallback((e)=>{
    doScroll(-e.movementY*2)
    return;
  },[doScroll])

  const endDrag = useCallback(()=>{
    lastYDist.current = 0;
    // add drag listener
    document.body.removeEventListener('mousemove',doDrag)
    // add mouseup listener
    document.body.removeEventListener('mouseup',endDrag)
  },[doDrag])

  const startDrag = useCallback((e)=>{
    cursorStartY.current = e.clientY;
    thumbStartY.current =  Number(thumbRef.current.style.top.slice(0,-2));
    thumbBoundsRef.current = thumbRef.current.getBoundingClientRect();
    // add drag listener
    document.body.addEventListener('mousemove',doDrag)
    // add mouseup listener
    document.body.addEventListener('mouseup',endDrag)
  },[endDrag])

  useLayoutEffect(()=>{
    if (!hubContentRef?.current) return;
    thumbRef.current.onmousedown = null;
    thumbRef.current.addEventListener('mousedown',startDrag)
    // return thumbRef.current.removeEventListener('mousedown',startDrag)
  },[startDrag])

  useLayoutEffect(()=>{
    // set thumb height
    if (!hubContentRef?.current) return;
    const minScrollHeight = document.body.clientHeight - maxScrollGapAtBottom;
    const availScrollDist = hubContentRef.current.clientHeight - minScrollHeight;
    const thumbHeight = hubContentRef.current.clientHeight - availScrollDist;
    setHubScrollThumbHeight(thumbHeight); 
  },[hubContentRef])
  useLayoutEffect(()=>{
    setTimeout(()=>{
      doScroll(0)
    },50)
  },[doScroll])
  return (
    <>
      <div className={styles.HubScrollWrapper}>
        {/* <img src={ScrollBtnTop} className={styles.hubScrollButtonTop} onClick={()=>{doScroll(10,thumbRef)}} alt="Scroll Up"/> */}
        <ScrollBtnTop className={styles.hubScrollButtonTop} onClick={()=>{doScroll(10)}} alt="Scroll Up" />
        <div className={styles.HubScroll} ref={hubScrollRef} >
          <div ref={thumbRef} className={styles.scrollThumb} style={{height:`${Math.max(hubScrollThumbHeight,minThumbHeight)}px`}}>
            <div className={styles.scrollThumbInner}/>
          </div>
          <div className={styles.scrollTrack}/>
        </div>
        <ScrollBtnTop className={styles.hubScrollButtonBottom} onClick={()=>{doScroll(-10)}} alt="Scroll Down" />
      </div>
    </>
  )
}

export default HubScroll