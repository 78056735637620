import styles from './styles.module.scss'
import EleVid from 'assets/vid/eleOpen1_c.mp4'
import { useCallback, useEffect, useRef, useState } from 'react'
import ElevatorInterior from '../ElevatorInterior'
import ReactGA from 'react-ga4';
const ElevatorAccess = ({})=>{
  const [openingVidClass,setOpeningVidClass] = useState(styles.openingVid)
  const [eleInteriorOpen,setEleInteriorOpen] = useState(false)
  const vidRef = useRef();
    // useEffect(()=>{setTimeout(hideOpeningVid,4000)},[])
  const hideOpeningVid = ()=>{
    // alert('hide opening vid')
    setTimeout(()=>{
      setOpeningVidClass(styles.openingVidDone)
      setTimeout(showEleInterior,650)
    },500)
  }
  const showEleInterior = useCallback(()=>{
      setEleInteriorOpen(true)
  },[])

  useEffect(()=>{
    vidRef?.current?.addEventListener('ended',(hideOpeningVid))
    setTimeout(()=>{vidRef?.current?.play()},500)

    //analytics
    ReactGA.event({
      category: 'elevator-access',
      action: 'opened-elevator',
    })
  },[])
  return (
    <>
      <div className={styles.wrapper}>
        {!eleInteriorOpen && <video muted ref={vidRef} className={openingVidClass} preload="true" playsInline={true}>
          <source src={EleVid} type="video/mp4" />
        </video>}
        {eleInteriorOpen && <ElevatorInterior />}
      </div>
    </>
  )
}

export default ElevatorAccess