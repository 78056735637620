import { CuboidCollider, RigidBody } from '@react-three/rapier';
import { useEffect, useRef } from 'react';
import { Euler, Quaternion } from 'three';


const CollisionPlane = ({rotation,position,restitution=0.1})=>{
  const rigidBody = useRef();
  useEffect(()=>{
    if (!rigidBody.current) return;
    rigidBody.current.setBodyType(2)
  },[])

  useEffect(()=>{
    rigidBody.current.setNextKinematicTranslation({x:position[0],y:position[1],z:position[2]});
    rigidBody.current.setNextKinematicRotation(new Quaternion().setFromEuler(new Euler(rotation[0],rotation[1],rotation[2])))
  },[position,rotation])
  return (
  <RigidBody position={[999,999,999]} ref={rigidBody} colliders={false} >
    <CuboidCollider args={[500, 500, 10]} position={[0,0,-10]} restitution={restitution}/>
  </RigidBody>)
}

export default CollisionPlane