import { useCallback, useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';

const phrases = [
  "Configuring Retro-Encabulator...",
  "Enhancing Jejeune Sentimentality Array...",
  "Cavitating Quantum-Newtonian Bridge Lattice...",
  "Harmonizing Frequency Harmonics...",
  "Re-calibrating Fractal Jank Harness...",
  "Entangling...",
  "Disentangling...",
  "Modulating Subquantum Probability Matrices...",
  "Optimizing Neural Oscillators...",
  "Augmenting Sensory Perceptions...",
  "Polishing Chromatic Dispersion Arrays...",
  "Deconstructing Subconscious Matter Manifestation...",
  "Phase-shifting Chrono-induction Units...",
  "Adjusting Spatial Continua...",
  "Circumventing Paradox...",
  "Re-carbonating Molecular Entropy...",
  "Synchronizing Bio-digital Interface...",
  "Fluxing Temporal Discontinuity Apparatus...",
  "Integrating Astral Cognizance Interface...",
  "Pinging Galactic Neural Network Node 'Wisdom Alliance'...",
  "Denoising Plutonic Field Modulation Units"
]

const MainLoadAnim=({})=>{
  const phraseRotationRef = useRef();
  const [phrase,setPhrase] = useState(phrases[Math.round(Math.random() * (phrases.length - 1))])
  const rotatePhrase = useCallback(()=>{
    let newPhrase = phrases[Math.round(Math.random() * (phrases.length - 1))];
    while (newPhrase === phrase) {
      newPhrase = phrases[Math.round(Math.random() * (phrases.length - 1))];
    }
    setPhrase(newPhrase);
    phraseRotationRef.current = setTimeout(()=>{rotatePhrase()},Math.random()* (2000 + (Math.random() * 1500)) )
  },[phrases,phrase,setPhrase])


  useEffect(rotatePhrase,[])
  return (
    <>
      <div className={styles.MainLoadAnim}>
       <div className={styles.LoadTile}></div>
       <h3>{phrase}</h3>
      </div>
    </>
  )
}

export default MainLoadAnim