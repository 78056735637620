import styles from './styles.module.scss';
import {useEffect} from 'react';
import useWorldState from '../WorldStateManager';
import Stranger from './Stranger';

const DisteudoGate=({children,altComponent=<Stranger />})=>{
  // console.log('disteudogate render')
  const {disteudo,seekDisteudo} = useWorldState((state)=>({disteudo:state.disteudo,seekDisteudo:state.seekDisteudo}))
  useEffect(()=>{
    // console.log('disteudogate disteudo:',disteudo)
    if (!disteudo){
      seekDisteudo();
    }
  },[disteudo])
  return (
    <>
      {!disteudo && altComponent}
      {!!disteudo && <>{children}</>}
    </>
  )
}

export default DisteudoGate