/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useMemo, useRef,useEffect, Suspense } from "react";
import { useGLTF } from "@react-three/drei";
// import useWorldState from "../WorldStateManager";
import { useThree } from "@react-three/fiber";
import { useKTX2 } from "@react-three/drei";
import { MeshLambertMaterial } from "three";
import { DoubleSide } from "three";
// import {useCompoundBody} from '@react-three/cannon'
import useWorldState from "../WorldStateManager";
import { CuboidCollider, CylinderCollider, RigidBody } from "@react-three/rapier";
import { degToRad } from "three/src/math/MathUtils";
import PlantModel from '../../../assets/models/plant2_5.glb'
import PlantAlbedo from '../../../assets/tex/plantTexDiff1.ktx2';
import PlantAlpha from '../../../assets/tex/plantTexAlpha1.ktx2';
import PlantNormal from '../../../assets/tex/plantTexNorm1.ktx2';
const Plant = ({position,name=`plant_${Date.now()}`,...props})=>{
  // const worldRenderer = useWorldState(state=>state.worldRenderer)
  const {addAccelAffectedBody,removeAccelAffectedBody,gravity} = useWorldState(state=>({addAccelAffectedBody:state.addAccelAffectedBody,removeAccelAffectedBody:state.removeAccelAffectedBody,gravity:state.gravity}));
  // const plantName = useMemo(()=>{
  //   return `plant_${Date.now()}`
  // },[])
  // const [plantRef,plantApi] = useCompoundBody(() => ({
  //   mass: 30,
  //   linearDamping: 0.55,
  //   angularDamping: 0.55,
  //   shapes: [
  //     { type: 'Cylinder', mass: 24, position: [0,14.5,0], args: [1,2,18,6] },
  //     { type: 'Cylinder', mass: 2, position: [0,-0.5,0], args: [3.2,2.5,6.5,6] },
  //     { type: 'Box', mass: 2, position: [0,10,-4], rotation: [.9,0,0], args: [1,1,5] },
  //     { type: 'Box', mass: 2, position: [0,10,4], rotation: [-.9,0,0], args: [1,1,5] },
  //     // { type: 'ConvexPolyhedron', mass: 4, position: [0,0,0], args: [COLL_L_Leg] },
  //     // { type: 'ConvexPolyhedron', mass: 4, position: [0,0,0], args: [COLL_R_Leg] },
  //     // { type: 'ConvexPolyhedron', mass: 4, position: [0,0,0], args: [COLL_B_BODY] },
  //     // { type: 'Box', mass: 1, position: [2, -2.25, 2], args: [0.5, 4, 0.5] },
  //     // { type: 'Box', mass: 1, position: [-2, -2.25, -2], args: [0.5, 4, 0.5] },
  //     // { type: 'Box', mass: 1, position: [-2, -2.25, 2], args: [0.5, 4, 0.5] },
  //     // { type: 'Box', mass: 1, position: [2, -2.25, -2], args: [0.5, 4, 0.5] }
  //   ],
  //   ...props
  // }))
  useEffect(()=>{
    // plantApi.accelAmount = 1;
    addAccelAffectedBody(plantBodyRef.current)
    return ()=>{
      removeAccelAffectedBody(plantBodyRef.current)
    }
  },[])
  const plantBodyRef = useRef();
  const { nodes } = useGLTF(PlantModel);
  const [plantDiffTex, plantAlphaTex, plantNormalTex] = useKTX2([PlantAlbedo,PlantAlpha,PlantNormal])
  const plantMat = useMemo(()=>{
    return new MeshLambertMaterial({side: DoubleSide, map: plantDiffTex, alphaMap:plantAlphaTex, normalMap: plantNormalTex, transparent:false, alphaTest: 0.5,});
  },[])
  return (
    <Suspense fallback={null}>
      <RigidBody ref={plantBodyRef} colliders={false} restitution={0.09} position={position} angularDamping={5} rotation={[0,0,0]}>
        <CylinderCollider density={3.4} args={[2.2,2.2]} />
        <CylinderCollider density={3.4} args={[0.5,3.1]} position={[0,2.5,0]} />
        <CylinderCollider density={0.5} args={[8,0.01]} position={[0,12,0]} />
        <CuboidCollider density={0.5} args={[2,5,0.01]} position={[4,21,-4]} rotation={[degToRad(-45),0,degToRad(-15)]} />
        <CuboidCollider density={0.5} args={[1.3,5,0.01]} position={[0,10,-4]} rotation={[degToRad(-35),0,degToRad(0)]} />
        <CuboidCollider density={0.5} args={[1.3,5,0.01]} position={[-2,10,3]} rotation={[degToRad(35),degToRad(-30),degToRad(20)]} />
        {/* <CuboidCollider args={[1.3,7,0.01]} position={[-5,14,0]} rotation={[degToRad(90),degToRad(-60),degToRad(90)]} /> */}
        <group name={name} body={plantBodyRef.current} {...props} dispose={null} scale={[70,70,70]} >
          <mesh
            castShadow
            receiveShadow
            geometry={nodes.Plant1.geometry}
            material={plantMat}
            twoSided={true}
            name={name}
            body={plantBodyRef.current}
          />
        </group>
      </RigidBody>
    </Suspense>
  );
}
export default Plant
useGLTF.preload("/plant2.glb");