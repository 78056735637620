import { useMemo } from 'react';
import styles from './styles.module.scss';

const DotGrid=(props)=>{
  const {xCount=4,yCount=4,width=100,height=100,patternPad=0.5,..._props} = props;
  const patternDims = useMemo(()=>{
    return {
      xWidth: 1/xCount,
      yWidth: 1/yCount,
      cx: (width/xCount)/2,
      cy: (height/yCount)/2,
      cRad: ((width/xCount)/2)-(patternPad*2)
    }
  },[xCount,yCount,width,height,patternPad])
  return (
    <div className={styles.dotGrid} >
      <svg width={width} height={height} xmlns="http://www.w3.org/2000/svg" {..._props} style={{filter: `drop-shadow(rgba(0, 0, 0,0.7) -4px -4px 0px)`}}>
        <defs>
          <linearGradient id="DotGrad">
            <stop offset="0%" stopColor="#79c2e6"/>
            <stop offset="99%" stopColor="#ef41fd"/>
          </linearGradient>


          <pattern id="MaskPattern" x="0" y="0" width={patternDims.xWidth} height={patternDims.yWidth}>
            <circle cx={patternDims.cx} cy={patternDims.cy} r={patternDims.cRad} fill="#ffffff"/> 
          </pattern>
        </defs>
        <mask id="DotMask">
        <rect fill="#000000" width={width} height={height} />
        <rect fill="url(#MaskPattern)" width={width} height={height} />
        </mask>
        <rect fill="url(#DotGrad)" stroke="none" mask="url(#DotMask)" width={width} height={height} />
      </svg>
    </div>
  )
}

export default DotGrid