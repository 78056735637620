/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect, Suspense } from "react";
import { useGLTF } from "@react-three/drei";
import { MeshPhongMaterial } from "three";
import { useThree } from "@react-three/fiber";
import { useKTX2 } from "@react-three/drei";
// import { ObjectSpaceNormalMap } from "three";
// import {useCompoundBody} from '@react-three/cannon'
import useWorldState from "../WorldStateManager";
import {RigidBody, RoundCuboidCollider } from "@react-three/rapier";
import ChairAlbedo from '../../../assets/tex/plantTexDiff1.ktx2'
import ChairNormal from '../../../assets/tex/plantTexNorm1.ktx2'
import ChairModel from '../../../assets/models/chair2_2.glb'
const Chair= ({name=`chair_${Date.now()}`,position,rotation,...props})=>{
  const chairBodyRef = useRef();
  // const backColliderRef = useRef();
  // const seatCollderRef = useRef();
  // const LLegColliderRef = useRef();
  // const RLegColliderRef = useRef();
  const { nodes } = useGLTF(ChairModel); 
  const {addAccelAffectedBody,removeAccelAffectedBody,gravity} = useWorldState(state=>({addAccelAffectedBody:state.addAccelAffectedBody,removeAccelAffectedBody:state.removeAccelAffectedBody,gravity:state.gravity}));
  useEffect(()=>{
    chairBodyRef.current.userData = !!chairBodyRef.current.userData? chairBodyRef.current.userData : {};
    chairBodyRef.current.userData.accelAmount = 1;
    chairBodyRef.current.userData.name = name;
    addAccelAffectedBody(chairBodyRef.current)
    return ()=>{
      removeAccelAffectedBody(chairBodyRef.current)
    }
  },[])
  const [chairDiffTex, chairNormalTex] = useKTX2([ChairAlbedo,ChairNormal])
  const bodyMat = new MeshPhongMaterial({color:0xffffff,map: chairDiffTex,normalMap:chairNormalTex,specular:0x595959, shininess:8});
  
  return (
    /*<group ref={chairBodyRef} {...props} dispose={null} scale={[7,7,7]} position={props.position}>*/
    <Suspense fallback={null}>
    <RigidBody ref={chairBodyRef} scale={[7,7,7]} angularDamping={3} position={position} rotation={rotation} colliders={false} density={3.3} restitution={0.01}>
      <mesh
        geometry={nodes.Body.geometry}
        material={bodyMat}
        rotation={[-Math.PI / 2, 0, -1.6]}
        scale={0.9}
        body={chairBodyRef.current}
        name={name}
      />
      {/* back */}
      <RoundCuboidCollider args={[0.5, 0.01, 0.5, 0.1]} position={[0.5,0,0]} rotation={[0, 0, -1.75]} />
      {/* seat */}
      <RoundCuboidCollider args={[0.4, 0.01, 0.5, 0.1]} position={[-0.2,-0.5,0]} rotation={[0, 0, 0]} />
      <mesh
        geometry={nodes.L_Leg.geometry}
        material={nodes.L_Leg.material}
        position={[0.04, -1.1, 0.45]}
        body={chairBodyRef.current}
        name={name}
      />
      <mesh
        geometry={nodes.R_Leg.geometry}
        material={nodes.R_Leg.material}
        position={[0.04, -1.1, -0.46]}
        body={chairBodyRef.current}
        name={name}
      />
      {/* L Leg */}
      <RoundCuboidCollider args={[0.5, 0.01, 0.45, 0.09]} position={[-0.1,-1.1,0.6]} rotation={[(Math.PI/2), 0, 0]} />
      {/* R Leg */}
      <RoundCuboidCollider args={[0.5, 0.01, 0.45, 0.09]} position={[-0.1,-1.1,-0.6]} rotation={[(Math.PI/2), 0, 0]} />
    </RigidBody>
    </Suspense>
    /*</group>*/
  );
}

export default Chair;
useGLTF.preload("/chair2_1.glb");

