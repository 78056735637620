import { useCallback } from 'react';
import useWorldState from '../WorldStateManager';
import styles from './styles.module.scss';

const Stranger=({})=>{
  const {navTo} = useWorldState(state=>({navTo:state.navTo}))
  const goHome = useCallback(()=>{
    if (!navTo) return
    navTo('/')
  },[navTo])
  return (
    <div className={styles.Stranger}>
      <h1>Stranger.</h1>
      <button onClick={goHome}>Ok</button>
    </div>
  )
}

export default Stranger