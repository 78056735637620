import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useEffect, useRef, useState, Suspense, useCallback} from 'react';
import styles from './styles.module.scss'
import DiceFinalPlate from 'assets/img/diceFinalPlate_c.jpg';

import { degToRad} from 'three/src/math/MathUtils';
import Die from "./die"
import {gsap} from 'gsap';
import { Euler, Quaternion } from 'three';
import { useHelper } from '@react-three/drei';
import { SpotLightHelper } from 'three';
import useWorldState from "../WorldStateManager"
import ReactGA from 'react-ga4';
const RotationsBySide = [
  [degToRad(-90),degToRad(30),degToRad(0)], //1
  [degToRad(0),degToRad(0),degToRad(90)], //2
  [degToRad(90),degToRad(0),degToRad(0)], //3
  [degToRad(180),degToRad(0),degToRad(15.99)], //4
  [degToRad(-90),degToRad(30),degToRad(90)], //5
  [degToRad(-90),degToRad(30),degToRad(-90)], //6
]

const DynamicAxisBySide = [
  1, // 1
  2, // 2
  1, // 3
  2, // 4
  1, // 5
  1, // 6
]

const GroundPlane = (props)=>{
  return (
    <mesh
      {...props}
      receiveShadow
      >
      <planeGeometry args={[100,85, 10]} attach="geometry" />
      <shadowMaterial attach="material" color={0x1b1b1b} opacity={0.5} />
    </mesh>
  )
}

const Content = ({DieOver=()=>{},DieOut=()=>{},DiePress=()=>{}})=>{
  const dice = useRef([]);
  const {currentDieSides,setIsElevatorOpen} = useWorldState(state=>({currentDieSides:state.currentDieSides,setIsElevatorOpen:state.setIsElevatorOpen}))
  const animationsToRun = useRef([]);
  const spotLightRef = useRef();

  
  useFrame(()=>{
    if (!animationsToRun.current.length) return;
    const animTrigger = animationsToRun.current[0];
    const newSideIdx = animTrigger.newSide-1
    const die = dice.current[animTrigger.dieIdx].current;
    const targetRotation = RotationsBySide[newSideIdx];
    targetRotation[DynamicAxisBySide[newSideIdx]] = degToRad(Math.random() * 360)
    const targetQuaternion = new Quaternion().setFromEuler(new Euler(targetRotation[0],targetRotation[1],targetRotation[2]))
    // lift anim
    const upAnim = {t:0};
    const targetLiftAmount = 0.0015;
    gsap.to(upAnim,{
      t: 100,
      duration: 0.2,
      onUpdate:function(){
        die.position.set(die.position.x,die.position.y,die.position.z + (targetLiftAmount*upAnim.t))
      }
    })
    // rotation anim
    const rotAnim = {t: 0};
    gsap.to(rotAnim,{
      t:1,
      delay: 0.09,
      duration:0.7,
      onUpdate:function(e){
        die.quaternion.slerp(targetQuaternion,rotAnim.t)
      }
    });
    // down anim
    const downAnim = {t:0};
    gsap.to(downAnim,{
      t:100,
      delay: 0.6005,
      duration: 0.2,
      onUpdate: function(){
        die.position.set(die.position.x,die.position.y,die.position.z - (targetLiftAmount*downAnim.t))
      },
      onComplete: function(){
        currentDieSides[animTrigger.dieIdx] = animTrigger.newSide;
        checkDieSides();
      }
    })

    animationsToRun.current.shift();
  })

  const checkDieSides = ()=>{
    const key = [1,6,1,5,2];
    const keyMatch = currentDieSides.every((v,i)=>v===key[i])
    if (keyMatch) {
      setTimeout(openEle,333)
    }
  }

  const updateDieSide =(idx,priorSide,newSide)=>{
    currentDieSides[idx] = 0; // "dummy val" so animating dice will not accidentally trigger an unlock, i.e. all dice must be at the right state of rest before unlock will happen
    
    const newAnimationTrigger = {
      dieIdx: idx,
      priorSide: priorSide,
      newSide: newSide
    }
    animationsToRun.current.push(newAnimationTrigger);
  }

  const openEle = ()=>{
    setIsElevatorOpen(true);
  }

  const {camera} = useThree();
  
  useEffect(()=>{
    camera.position.set(0.1,-10.1,8);
    camera.rotation.set(degToRad(55.73),degToRad(0),degToRad(0));
    camera.fov=55.0;
    camera.updateProjectionMatrix();
  },[camera])

  useHelper(spotLightRef,SpotLightHelper,"teal");
  return (
    <group>
        <ambientLight intensity={0.19} />
        <spotLight castShadow  position={[-5.6,-7.3,5.2]} rotation={[0,0,0]} intensity={1.0}  />
        <directionalLight intensity={0.1} color={0xccbbaa}/>
        <Suspense fallback={null} >
          <Die receiveShadows idx={0} position={[-2.3,0.12,0]} rotation={RotationsBySide[currentDieSides[0]-1]} color={0x00ff00} dice={dice} updateDieSide={updateDieSide} startSide={currentDieSides[0]} DieOver={DieOver} DieOut={DieOut} DiePress={DiePress} />
          <Die idx={1} position={[.5,.03,0]} rotation={RotationsBySide[currentDieSides[1]-1]} color={0x0000ff} dice={dice} updateDieSide={updateDieSide} startSide={currentDieSides[1]} DieOver={DieOver} DieOut={DieOut} DiePress={DiePress} />
          <Die idx={2} position={[2.85,-0.34,0]} rotation={RotationsBySide[currentDieSides[2]-1]} color={0xff00ff} dice={dice} updateDieSide={updateDieSide} startSide={currentDieSides[2]} DieOver={DieOver} DieOut={DieOut} DiePress={DiePress} />
          <Die idx={3} position={[-1.68,-2.55,0]} rotation={RotationsBySide[currentDieSides[3]-1]} color={0xff00ff} dice={dice} updateDieSide={updateDieSide} startSide={currentDieSides[3]} DieOver={DieOver} DieOut={DieOut} DiePress={DiePress} />
          <Die idx={4} position={[0.67,-2.87,0]} rotation={RotationsBySide[currentDieSides[4]-1]} color={0xff00ff} dice={dice} updateDieSide={updateDieSide} startSide={currentDieSides[4]} DieOver={DieOver} DieOut={DieOut} DiePress={DiePress} />
        </Suspense>
        <GroundPlane receiveShadow position={[0,0,-0.8]} />
    </group>
  )
}



const Dice = ({zoomOutDice,openFetCard})=>{
  const {isElevatorOpen} = useWorldState(state=>({isElevatorOpen:state.isElevatorOpen}))
  const [canvasWrapperClass,setCanvasWrapperClass] = useState(styles.canvasWrapper);
  const DieOver = useCallback(()=>{setCanvasWrapperClass(styles.canvasWrapperHover)},[]);
  const DiePress = useCallback(()=>{setCanvasWrapperClass(styles.canvasWrapperClick)},[]);
  const DieOut = useCallback(()=>{setCanvasWrapperClass(styles.canvasWrapper)},[]);

  const showFetCard = useCallback(()=>{
    zoomOutDice();
    openFetCard();
  },[])

  // analytics
  useEffect(()=>{
    // ReactGA.modalview('briefcase-dice')
    ReactGA.event({
      category: 'briefcase',
      action: 'briefcase_view-dice',
    })
  },[])

  return (<div className={styles.diceWrapper}>
    <img src={DiceFinalPlate} alt="" />
    <div className={canvasWrapperClass}>
      <Canvas camera={{rotation:[0.73356188,0,0],position:[0,-6.6,3.4], fov:35}} colormanagement="true" shadows frameloop={(isElevatorOpen)? "never":"always"}>
        <Content DieOver={DieOver} DieOut={DieOut} DiePress={DiePress} />
      </Canvas>
    </div>
    <div className={styles.diceOverlay} onClick={zoomOutDice} />
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1920 1080"><polyline onClick={showFetCard} points="0.5 635.41 358.85 730.68 15.49 1080.5 0.5 1080.5"/></svg>
  </div>)
}

export default Dice