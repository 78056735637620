import { useCallback, useMemo } from 'react'
import baseStyles from './styles.module.scss'
import {DialogueRenderer} from './DialogueRenderers/fadeIn'
import defaultCallbacks from './defaultCallbacks';
import ReactGA from 'react-ga4';
const BlaBla = (props)=>{
  const {convo,callbacks,styles,defaultItemInDelay,defaultItemAnimInTime,defaultItemOutDelay,defaultItemAnimOutTime,defaultResponseInDelay,defaultResponseAnimInTime,defaultSectionOutDelay,defaultSectionAnimOutTime,convoStep=0,setConvoStep=()=>{}} = props;
  const _callbacks = useMemo(()=>{return Object.assign(defaultCallbacks,callbacks)},[callbacks])
  const handleAction = useCallback((action,convoStep)=>{
    //analytics
    ReactGA.event({
      category: 'BlaBla',
      action: 'BlaBla_handleAction',
      label: `action:${JSON.stringify(action)},convoName:${convo.name},convoStep:${convoStep}`
    })
    if (action.type === "END") {
      // CLEANUP AND END CONVO
      _callbacks.end();
    }
    else if (action.type === "GOTO") {
      // GOTO SPECIFIC CONVO STEP 
      _callbacks.goto(action.val);
      setConvoStep(action.val);
    }
    else if (action.type === "CALLBACK") {
      // RUN NAMED CALBACK FROM WHITELIST OF _callbacks
      _callbacks[action.val](action.params);
    }
    else if (action.type === "NEXT") {
      // ADVANCE TO NEXT STEP IN CONVO
      if (convoStep + 1 > convo.contentItems.length-1) {
        handleAction({type:"END"});
      }
      else {
        _callbacks.next(convoStep+1);
        setConvoStep(convoStep+1);
      }
    }
    else if (action.type === "PREVIOUS") {
      // STEP BACK TO PREVIOUS STEP IN CONVO
      if (convoStep - 1 > 0) {
        _callbacks.previous(convoStep-1);
        setConvoStep(convoStep-1);  
      }
    }
    else if (action.type === "RESTART") {
      // RUN NAMED CALBACK FROM WHITELIST OF _callbacks
      _callbacks.restart();
      setConvoStep(0);
    }
    
  },[])

  return (
    <>
      <div className={`${baseStyles.BlaBlaWrapper} ${styles.BlaBlaWrapper}`}>
        <DialogueRenderer contentItem={convo.contentItems[convoStep]} convo={convo} handleAction={handleAction} convoStep={convoStep} styles={styles}/>
        
      </div>
    </>
  )
}

export default BlaBla