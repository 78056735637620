// import styles from './styles.module.scss';
import IOSPermissionButton from "../IOSPermissionButton";
import useUIState from "../UIStateManager";
import { useState } from "react";
const ConditionalButton=({buttonText})=>{
  const [interacted,setInteracted] = useState(false);
  const {orientationPermission,orientationPermsReqd} = useUIState((state)=>({orientationPermission:state.orientationPermission,orientationPermsReqd:state.orientationPermsReqd}));
  if (orientationPermsReqd && !orientationPermission && !interacted) {
    return (<IOSPermissionButton buttonText={buttonText} setInteracted={setInteracted} />)
  }
  else {
    return null;
  }

}

export default ConditionalButton