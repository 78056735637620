import { useMemo } from "react"
// import { PlaneBufferGeometry, Vector3 } from "three"
import { FrontSide, BackSide } from "three";

const RectLightArray = ({count=1,color=0xfefed1,width=30,position=[0,0,0],zTravel=false,depth=1,isCeil=false,skip=[]})=>{
  const lightWidth = useMemo(()=>{return (width / (count)) * 0.8;},[width,count]) 
  const gap = useMemo(()=>{return lightWidth * .25;},[lightWidth])
  const groupPosition = useMemo(()=>{
   return (zTravel)?  [position[0],position[1],position[2]] : [position[0]+(lightWidth/2)+gap/2,position[1],position[2]]
  },[zTravel]) 
  const lightArray = useMemo(()=>{
    const meshes = []
    for (let i = 0; i <= count-1; i++) {
      if (skip.indexOf(i) !== -1) continue;
      const _position = (zTravel)? [0,0,(i*(lightWidth+gap))] : [(i*(lightWidth+gap)),0,0]
      const _rotation = (zTravel)? [-1.57,0,0]:[-1.57,0,0];
      const _side = (isCeil)? BackSide : FrontSide;
      const _scale = (zTravel)? [depth,lightWidth,1,1] : [lightWidth,depth,1,1]
      const mesh = <mesh key={i}  rotation={_rotation} position={_position}>
        <planeGeometry attach='geometry' args={_scale} />
        <meshBasicMaterial attach='material' unlit color={color} side={_side}/>
      </mesh>

      meshes.push(mesh)
    }
    return meshes;
  },[count,color,position])

  return (<>

    <group position={groupPosition} rotation={[0,0,0]}>
      {lightArray}
    </group>
  </>)
}

export default RectLightArray