import useWorldState from 'Components/World/WorldStateManager';
import styles from './styles.module.scss';
import { useCallback } from 'react';
import DisteudoGate from 'Components/World/DisteudoGate';

const L2SubscriptionConfirmation=({})=>{
  const {navTo} = useWorldState(state=>({navTo:state.navTo}))
  const navHome = useCallback(()=>{
    if (!navTo) return
    navTo('/')
  },[navTo])
  return (
    <div className={styles.L2SubscriptionConfirmation}>
      <DisteudoGate>
        <p>You should have an email to verify your subscription.</p> 
        <p>Once you click the link in there you'll be all set.</p>
        <button onClick={navHome}>OK</button>
      </DisteudoGate>
    </div>
  )
}

export default L2SubscriptionConfirmation