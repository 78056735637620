import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useRef } from "react";
import BasicBall from "../../BasicBall";
import { Vector3 } from "three";
import useWorldState from "Components/World/WorldStateManager";
import ReactGA from 'react-ga4';
const Launcher=({emitter,lastLaunchTime,setLastLaunchTime,...props})=>{
  const {camera} = useThree();
  const launchPosition = useRef({coords:{x:0,y:0}})
  const firingTimer = useRef(null);
  const launchedRef = useRef([]);
  const emitterInited = useRef(false);

  const {addItemToRenderList} = useWorldState((state)=>({addItemToRenderList:state.addItemToRenderList}))

  function updateLaunchPosition(e){ 
    launchPosition.current = e
  }

  const startFiring = useCallback((e)=>{
    launchPosition.current = e
    emitter.on('move',updateLaunchPosition);
    fire();
    firingTimer.current = window.setInterval(fire,110);
  },[])

  const stopFiring = useCallback((e)=>{
    window.clearInterval(firingTimer.current)
    emitter.off('move',updateLaunchPosition)
    firingTimer.current = null;
  },[])

  const fire = useCallback(()=>{
    let _x = (window.innerWidth/2 - launchPosition.current.coords.x)/-(window.innerWidth/window.innerHeight)//(e.coords.x - (window.innerWidth/2)) //need to normalize to screen middle?
    let _y = window.innerHeight/2 - launchPosition.current.coords.y;
    let _z = camera.near //(camera.far - camera.near) /2
    let _vec = new Vector3(_x,_y,_z);
    let _pos = _vec.unproject(camera)
    // let _vel = new CANNON.Vec3(0,0,-210)
    // let _vel = new CANNON.Vec3(0,0,0)
    const _vel = ({x:0,y:0,z:-210})
    const key = `launched_basicBall_${Date.now()}`
    addItemToRenderList(<BasicBall position={[_pos.x,_pos.y,0]} vel={_vel} key={key} name={key}/>)
    setLastLaunchTime(Date().now)

    //analytics
    ReactGA.event({
      category: 'tools-interaction',
      action: 'launcher-fired',
    })
  },[])


  useEffect(()=>{
    if (!emitter || !!emitterInited.current) return;
    emitter.on('start',(e)=>{  
      startFiring(e);
    })

    emitter.on('stop',(e)=>{
      stopFiring();
    })
    emitterInited.current = true;

  },[emitter])


  return (
    <>
      {/* {launchedRef.current} */}
    </>
  )
}

export default Launcher