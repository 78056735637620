
export const RoomVerts = (
  halfWidth,
  halfHeight,
  roomDepth,
  lightCutDepth,
  lightCutWidth
) => {
  const halfDepth = roomDepth / 2;
  return new Float32Array([
    // L1
    -halfWidth,
    -halfHeight,
    roomDepth,
    -halfWidth,
    -halfHeight,
    -roomDepth,
    -halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,
    // L2
    -halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,
    -halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    roomDepth,
    -halfWidth,
    -halfHeight,
    roomDepth,
    // Left light channel top
    -halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight + halfHeight * lightCutDepth,
    roomDepth,
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight + halfHeight * lightCutDepth,
    roomDepth,
    -halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    roomDepth,
    -halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,
    // left light channel inner
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight + halfHeight * lightCutDepth,
    roomDepth - halfDepth * lightCutWidth,
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight,
    roomDepth - halfDepth * lightCutWidth,
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight,
    -roomDepth + halfDepth * lightCutWidth,
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight,
    -roomDepth + halfDepth * lightCutWidth,
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth + halfDepth * lightCutWidth,
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight + halfHeight * lightCutDepth,
    roomDepth - halfDepth * lightCutWidth,
    // B1
    -halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,

    -halfWidth,
    -halfHeight,
    -roomDepth,

    halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,
    // B2
    halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,

    -halfWidth,
    -halfHeight,
    -roomDepth,

    halfWidth,
    -halfHeight,
    -roomDepth,

    // back light channel top
    -halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,
    halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,
    halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth + halfDepth * lightCutWidth,
    halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth + halfDepth * lightCutWidth,
    -halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth + halfDepth * lightCutWidth,
    -halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,
    // back light channel inner
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth + halfDepth * lightCutWidth,
    halfWidth - halfWidth * lightCutWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth + halfDepth * lightCutWidth,
    halfWidth - halfWidth * lightCutWidth,
    halfHeight,
    -roomDepth + halfDepth * lightCutWidth,
    halfWidth - halfWidth * lightCutWidth,
    halfHeight,
    -roomDepth + halfDepth * lightCutWidth,
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight,
    -roomDepth + halfDepth * lightCutWidth,
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth + halfDepth * lightCutWidth,
    // R1
    halfWidth,
    -halfHeight,
    -roomDepth,
    halfWidth,
    -halfHeight,
    roomDepth,
    halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    roomDepth,
    // R2
    halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    roomDepth,

    halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,

    halfWidth,
    -halfHeight,
    -roomDepth,
    // right light channel top
    halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    roomDepth,
    halfWidth - halfWidth * lightCutWidth,
    halfHeight + halfHeight * lightCutDepth,
    roomDepth,
    halfWidth - halfWidth * lightCutWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,
    halfWidth - halfWidth * lightCutWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,
    halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    -roomDepth,
    halfWidth,
    halfHeight + halfHeight * lightCutDepth,
    roomDepth,
    // right light channel inner
    // halfWidth - halfWidth * lightCutWidth,
    // halfHeight + halfHeight * lightCutDepth,
    // roomDepth - halfDepth * lightCutWidth,
    // halfWidth - halfWidth * lightCutWidth,
    // halfHeight + halfHeight * lightCutDepth,
    // -roomDepth + halfDepth * lightCutWidth,
    // halfWidth - halfWidth * lightCutWidth,
    // halfHeight,
    // -roomDepth + halfDepth * lightCutWidth,
    // halfWidth - halfWidth * lightCutWidth,
    // halfHeight,
    // -roomDepth + halfDepth * lightCutWidth,
    // halfWidth - halfWidth * lightCutWidth,
    // halfHeight,
    // -roomDepth + halfDepth * lightCutWidth,
    // halfWidth - halfWidth * lightCutWidth,
    // halfHeight,
    // roomDepth - halfDepth * lightCutWidth,
    // floor 1
    // -halfWidth,
    // -halfHeight,
    // roomDepth,

    // halfWidth,
    // -halfHeight,
    // roomDepth,

    // halfWidth,
    // -halfHeight,
    // -roomDepth,

    // // floor 2
    // halfWidth,
    // -halfHeight,
    // -roomDepth,

    // -halfWidth,
    // -halfHeight,
    // -roomDepth,

    // -halfWidth,
    // -halfHeight,
    // roomDepth,
    // // ceil 1
    // -halfWidth + halfWidth * lightCutWidth,
    // halfHeight,
    // -roomDepth + halfDepth * lightCutWidth,

    // halfWidth - halfWidth * lightCutWidth,
    // halfHeight,
    // -roomDepth + halfDepth * lightCutWidth,

    // halfWidth - halfWidth * lightCutWidth,
    // halfHeight,
    // roomDepth - halfDepth * lightCutWidth,
    // // ceil 2
    // halfWidth - halfWidth * lightCutWidth,
    // halfHeight,
    // roomDepth - halfDepth * lightCutWidth,

    // -halfWidth + halfWidth * lightCutWidth,
    // halfHeight,
    // roomDepth - halfDepth * lightCutWidth,

    // -halfWidth + halfWidth * lightCutWidth,
    // halfHeight,
    // -roomDepth + halfDepth * lightCutWidth
  ]);
};

export const CeilVerts = (
  halfWidth,
  halfHeight,
  roomDepth,
  lightCutDepth,
  lightCutWidth
)=>{
  const halfDepth = roomDepth / 2;
  return new Float32Array([
  // ceil 1
    -halfWidth + halfWidth * lightCutWidth,
    halfHeight,
    -roomDepth + halfDepth * lightCutWidth,

    halfWidth - halfWidth * lightCutWidth,
    halfHeight,
    -roomDepth + halfDepth * lightCutWidth,

    halfWidth - halfWidth * lightCutWidth,
    halfHeight,
    roomDepth - halfDepth * lightCutWidth,
    // ceil 2
    halfWidth - halfWidth * lightCutWidth,
    halfHeight,
    roomDepth - halfDepth * lightCutWidth,

    -halfWidth + halfWidth * lightCutWidth,
    halfHeight,
    roomDepth - halfDepth * lightCutWidth,

    -halfWidth + halfWidth * lightCutWidth,
    halfHeight,
    -roomDepth + halfDepth * lightCutWidth
])}

export const FloorVerts = (
  halfWidth,
  halfHeight,
  roomDepth
)=>{
  return new Float32Array([
    // floor 1
    -halfWidth,
    -halfHeight,
    roomDepth,

    halfWidth,
    -halfHeight,
    roomDepth,

    halfWidth,
    -halfHeight,
    -roomDepth,

    // floor 2
    halfWidth,
    -halfHeight,
    -roomDepth,

    -halfWidth,
    -halfHeight,
    -roomDepth,

    -halfWidth,
    -halfHeight,
    roomDepth,
])}

export const RoomNormals = new Float32Array([
  //L1
  1,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  //L2
  1,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  // LEFT LIGHT CHANNEL TOP
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  //LEFT LIGHT CHANNEL INNER
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  //B1
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  1,
  //B2
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  1,
  // BACK LIGHT CHANNEL TOP
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  // BACK LIGHT CHANNEL INNER
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  1,
  0,
  0,
  1,
  //R1
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  //R2
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  //R Light Channel top
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  0,
  0,
  -1,
  // //R LIGHT CHANNEL INNER
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // 0,
  // //FLOOR 1
  // 0,
  // -1,
  // 0,
  // 0,
  // -1,
  // 0,
  // 0,
  // -1,
  // 0,
  // //FLOOR 2
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // //CEIL1
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // //CEIL2
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0,
  // 0,
  // 1,
  // 0
]);

export const CeilNormals = new Float32Array([
    //CEIL1
    0,
    1,
    0,
    0,
    1,
    0,
    0,
    1,
    0,
    //CEIL2
    0,
    1,
    0,
    0,
    1,
    0,
    0,
    1,
    0
])

export const FloorNormals = new Float32Array([
    //FLOOR 1
    0,
    1,
    0,

    0,
    1,
    0,

    0,
    1,
    0,
    //FLOOR 2
    0,
    1,
    0,

    0,
    1,
    0,

    0,
    1,
    0,
])

export const RoomUVs = new Float32Array([
  //L1
  0.005,
  0.987,

  0.969,
  0.987,

  0.969,
  0.670,

  //L2
  0.969,
  0.670,

  0.011,
  0.670,

  0.011,
  0.987,

  //LIGHT CHANNEL L TOP
  0.447,
  0.17,
  0.447,
  0.08,
  0.457,
  0.16,

  0.447,
  0.17,
  0.447,
  0.08,
  0.457,
  0.16,
  //LIGHT CHANNEL L INNER
  0.339,
  0.995,
  0.664,
  0.628,
  0.339,
  0.628,

  0.339,
  0.995,
  0.664,
  0.628,
  0.339,
  0.628,

  //B1
  0.009,
  0.346,
  0.009,
  0.659,
  0.967,
  0.346,

  //B2
  0.967,
  0.346,
  0.009,
  0.659,
  0.967,
  0.659,

  //Back Light channel top
  1,
  1,

  0,
  1,

  1,
  0,

  1,
  1,

  0,
  1,

  1,
  0,

  //Back Light channel inner
  1,
  1,

  0,
  1,

  1,
  0,

  1,
  1,

  0,
  1,

  1,
  0,

  //R1
  0.008,
  0.334,
  0.969,
  0.334,
  0.969,
  0.015,
  //R2
  0.969,
  0.015,
  0.008,
  0.015,
  0.008,
  0.334,
  //R Light channel top
  1,
  1,

  0,
  1,

  1,
  0,

  1,
  1,

  0,
  1,

  1,
  0,

  // //LIGHT CHANNEL R INNER
  // 1,
  // 1,
  // 0,
  // 1,
  // 1,
  // 0,
  // 1,
  // 1,
  // 0,
  // 1,
  // 1,
  // 0,
  // 1,
  // 1,
  // 0,
  // 1,
  // 1,
  // 0,
  // 1,
  // 1,
  // 0,
  // 1,
  // 1,
  // 0,
  // 1,
  // 1,
  // 0,
  // floor 1
  0.674,
  0.994,

  0.994,
  0.994,

  0.994,
  0.663,

  // floor 2
  0.994,
  0.663,

  0.674,
  0.663,

  0.674,
  0.995//,

  // // ceil 1
  // 0.999,
  // 0.273,

  // 0.99999,
  // 0.273,

  // 0.669,
  // 0.604,

  // //ceil 2
  // 0.669,
  // 0.604,

  // 0.69,
  // 0.273,

  // 0.999999,
  // 0.273
]);

export const CeilUVs = new Float32Array([
    // ceil 1
    1,
    0,
  
    1,
    1,
  
    0,
    1,
  
    //ceil 2
    0,
    1,
  
    0,
    0,
  
    1,
    0
])

export const FloorUVs = new Float32Array([
  // ceil 1
  1,
  0,

  1,
  1,

  0,
  1,

  //ceil 2
  0,
  1,

  0,
  0,

  1,
  0
])
export const FloorLightmapUVs = new Float32Array([
  // ceil 1
  0,
  1,

  1,
  1,

  1,
  0,

  //ceil 2
  1,
  0,

  0,
  0,

  0,
  1
])