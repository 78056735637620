import React, { useRef, useEffect, useState, useCallback } from 'react';
import {useThree, useFrame} from '@react-three/fiber'
// import {useCannon} from './useCannon.js';
// import * as CANNON from 'cannon'
import * as THREE from 'three';
// import AppContext from './AppContext';
import useWorldState from 'Components/World/WorldStateManager/index.jsx';
// import { useSphere, usePointToPointConstraint, usePlane } from '@react-three/cannon';
import { RigidBody, useRapier } from '@react-three/rapier';
import {
  JointData
} from "@dimforge/rapier3d-compat";

// analytics
import ReactGA from 'react-ga4';

const Grabber = ({emitter,...props}) => {
  const { world } = useRapier(); 
  const {camera,scene} = useThree();
  const radius = 0.5;
  const {roomScale}=useWorldState((state)=>({roomScale:state.roomScale}))
  const raycaster = useRef(new THREE.Raycaster())
  const pointerPos = useRef([0,0]);
  const pickerBodyRef = useRef();
  const pickerMeshRef = useRef();
  const pickerJointRef = useRef();
  const dragPlaneRef = useRef();
  const [currentGrabbedObject,setCurrentGrabbedObject] = useState(null);
  const constraintDown = useRef(false);
  const mouseConstraint = useRef();
  
  

  useEffect(()=>{
    if (!pickerBodyRef.current) return;
    pickerBodyRef.current.setBodyType(2)
  },[])

  useEffect(()=>{
    if (!emitter) return
    emitter.on('down',(e)=>{
      let collision = findNearestIntersectingObject(e.coords.x,e.coords.y,camera,scene.children)
      if (!collision) return
      let point = collision.point;
      setPickMarker(point.x,point.y,point.z)
      constraintDown.current = true;
      pointerPos.current = [e.coords.x,e.coords.y]      
      if (!!point && !!collision?.object?.body) {
        setMovementPlane(point.x,point.y,point.z);
        addMouseConstraint(collision);
      }
    })
    emitter.on('move',(e)=>{
      if (constraintDown.current) {
        // NOTE this is just the placeholder, using onframe to fix
        pointerPos.current = [e.coords.x,e.coords.y]   
      }
    })
    emitter.on('up',(e)=>{
      constraintDown.current = false;
      removeMouseConstraint();
    })
  },[emitter])


  useFrame(()=>{
    if (constraintDown.current) {
      if (!!mouseConstraint.current) {
        projectOntoPlane();  
      }
      else {
        let collision = findNearestIntersectingObject(pointerPos.current[0],pointerPos.current[1],camera,scene.children);
        if (!collision) return
        let point = collision.point;
        setPickMarker(point.x,point.y,point.z)
        if (point && !!collision?.object?.body) {
          setMovementPlane(0,0,point.z);
          addMouseConstraint(collision);
          setCurrentGrabbedObject({pos:[point.x,point.y,point.z],mesh:collision.object})
        }
      }
    }
  })

  const setPickMarker = useCallback((x,y,z)=>{
    pickerMeshRef.current.visible = true;
    pickerBodyRef.current.setNextKinematicTranslation({x:x,y:y,z:z})
  },[])

  const findNearestIntersectingObject = useCallback((clientX,clientY,camera,objects)=>{
    // needs normalized device coords
    let _x = (clientX / window.innerWidth) * 2 - 1;
    let _y = (clientY / window.innerHeight) * -2 + 1;
    let _mouse = new THREE.Vector2(_x,_y)
    raycaster.current && raycaster.current.setFromCamera(_mouse,camera)
    let intersects = raycaster.current && raycaster.current.intersectObjects(objects)
    intersects = intersects && intersects.filter((intersection)=>{
      return (!!intersection?.object?.name?.length && intersection.object.name !== 'pickMarker' && (mouseConstraint.current !== undefined || intersection.object.name !=='grabberPlane'))
    })
    var closest = false;
    if (intersects && intersects.length > 0) {
        closest = intersects[0];
    }
    
    return closest;
  },[])

  const setMovementPlane = (x,y,z)=>{
    dragPlaneRef.current.position.set(0,0,z);
    dragPlaneRef.current.quaternion.copy(camera.quaternion)
  }

  const addMouseConstraint = (collision)=>{
    const objectGrabPoint = collision.object.worldToLocal(collision.point)
    const jointParams = JointData.spherical({x:0,y:0,z:0},{x:objectGrabPoint.x,y:objectGrabPoint.y,z:objectGrabPoint.z});
    const joint = world.createImpulseJoint(jointParams,pickerBodyRef.current,collision?.object?.body,true)
    pickerJointRef.current = joint;
    mouseConstraint.current = joint;

    // analytics
    ReactGA.event({
      category: 'tools-interaction',
      action: 'item-grab',
      label: collision.object.name
    })
  }

  const removeMouseConstraint = ()=>{
    // pickerMeshRef.current.position.set(9999,9999,9999)
    try {
      world.impulseJoints.remove(0)
      world.impulseJoints.remove(1)
      world.impulseJoints.remove(2)
      if (mouseConstraint?.current?.handle) world.impulseJoints.remove(mouseConstraint.current.handle)
    }
    catch(err){
      console.log(`err removing impulseJoint: ${err}`)
    }
    pickerJointRef.current = null;
    mouseConstraint.current = null;
    pickerMeshRef.current.visible = false;
    dragPlaneRef.current.visible=false;
    try {dragPlaneRef.current.position.set(0,0,-999);}
    catch (err) {console.log('!M error relocating dragplane in removeMouseConstraint:',err)}
  }

  const projectOntoPlane = ()=>{
    dragPlaneRef.current.visible = true;
    let point = findNearestIntersectingObject(pointerPos.current[0],pointerPos.current[1],camera,[dragPlaneRef.current]).point;
    dragPlaneRef.current.visible = false;
    if (point) setPickMarker(point.x,point.y,point.z);
  }

  return (
  <>
    <RigidBody ref={pickerBodyRef} colliders={"ball"} density={0}>
      <mesh name="pickMarker" ref={pickerMeshRef} body={pickerBodyRef.current} visible={false}>
        <sphereGeometry attach='geometry' args={[radius,5,5]} />
        <meshPhongMaterial attach='material' specular={"#dd0000"} shininess={14} color={'#ff0000'} />
      </mesh>
    </RigidBody>

    {/* drag plane */}
    <mesh ref={dragPlaneRef} name="grabberPlane" visible={false}>
      <planeGeometry attach="geometry" args={[roomScale.w*2,roomScale.h*2]} />
      <meshLambertMaterial attach='material' wireframe={false} opacity={0.5} transparent color={'#0000ff'} />
    </mesh>
    
  </> 
  )
}

export default Grabber