import useWorldState from 'Components/World/WorldStateManager'
import useUIState from 'Components/UI/UIStateManager'
import { useState } from 'react'
import SimpleModal from 'Components/UI/SimpleModal';
import MainButtonOverlay from "Components/UI/MainButtonOverlay"
import BriefcaseOverlay from 'Components/World/BriefcaseOverlay';
import ElevatorAccess from 'Components/World/ElevatorAccess'
import Hub from 'Components/UI/Hub';
import WorldBase from 'Components/World/WorldBase';
import { Suspense } from 'react';
import MainLoadAnim from 'Components/UI/MainLoadAnim';
import ConditionalButton from 'Components/UI/ConditionalButton';
import { useLocation } from 'react-router-dom';
const Home=({})=>{
  const {isElevatorOpen,lastWorldRender} = useWorldState(state=>({frameloop:state.frameloop, isElevatorOpen:state.isElevatorOpen,lastWorldRender:state.lastWorldRender}))
  const {isBriefcaseOpen} = useUIState(state=>({isBriefcaseOpen:state.isBriefcaseOpen,isHubShown:state.isHubShown}))
  const [preloadDone,setPreloadDone] = useState(true) 
  const route = useLocation();
  // return <MainLoadAnim />
  return (
    <>
    <ConditionalButton />
    <Suspense fallback={<MainLoadAnim />}>
      {/* <Preloader setResolved={setPreloadDone}/> */}
      {preloadDone&& <>
        { (route.pathname.indexOf('/hub')!==-1) && 
          <div style={{width:'100vw',height:'100vh',overflow:'hidden'}}>
            <Hub />
          </div>
        }
        {((route.pathname.indexOf('/hub') === -1 || !!lastWorldRender) && !isElevatorOpen) && <WorldBase />}
        {isBriefcaseOpen && !isElevatorOpen && <BriefcaseOverlay />}
        {isElevatorOpen && <ElevatorAccess />}
        <MainButtonOverlay />
        <SimpleModal />
      </>}
    </Suspense>  
    </>
  )
}

export default Home