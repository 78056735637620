import { useThree } from '@react-three/fiber';
import useWorldState from 'Components/World/WorldStateManager';
import { useCallback, useEffect, useRef } from 'react';
// import { Decal } from '@react-three/drei';
import { Raycaster, Vector2 } from 'three';
// import BurnDecal from './burnDecal';

//analytice
import ReactGA from 'react-ga4';
const Blaster=({emitter,...props})=>{
  const emitterInited = useRef(false);
  // const blasterPos= useRef({coords:{x:0,y:0}});
  const {camera,scene} = useThree(); 
  const {removeItemFromRenderList} = useWorldState((state)=>({removeItemFromRenderList:state.removeItemFromRenderList}))
  const raycaster = useRef(new Raycaster())

  const findNearestIntersectingObject = useCallback((clientX,clientY,camera,objects)=>{
    // needs normalized device coords
    let _x = (clientX / window.innerWidth) * 2 - 1;
    let _y = (clientY / window.innerHeight) * -2 + 1;
    let _mouse = new Vector2(_x,_y)
    raycaster.current && raycaster.current.setFromCamera(_mouse,camera)
    let intersects = raycaster.current && raycaster.current.intersectObjects(objects)
    intersects = intersects && intersects.filter((intersection)=>{
      return (!!intersection?.object?.visible && !!intersection?.object?.name?.length && intersection.object.name !=='grabberPlane' && !intersection.object.isSprite)
    })
    var closest = false;
    if (intersects && intersects.length > 0) {
        closest = intersects[0];
    }
    
    return closest;
  },[])

  const fire = useCallback((e)=>{
    let collision = findNearestIntersectingObject(e.coords.x,e.coords.y,camera,scene.children);
    if (!collision.object) return
    if(collision.object.name === "walls" || collision.object.name === "floor" || collision.object.name === "ceiling") {
      // do decal
      // debugger
      // collision.object.decals.push(<BurnDecal key={`burnDecal_${Date.now()}`} />)
      // const decals = collision.object.decals;
      // decals.push(<BurnDecal key={`burnDecal_${Date.now()}`} position={collision.point} />)
      // collision.object.setDecals(decals)
    }
    else{
      try {
        // analytics
        ReactGA.event({
          category: 'tools-interaction',
          action: 'blaster-blasted',
          label: collision.object.name
        })
        collision.object.vaporize(()=>{removeItemFromRenderList(collision.object)},collision.point)
      }
      // try {removeItemFromRenderList(collision.object)}
      catch(err){console.log('!B error vaporizing',err)}
    }
  },[findNearestIntersectingObject,removeItemFromRenderList])

 
  useEffect(()=>{
    if (!emitter || !!emitterInited.current) return;
    emitter.on('fire',(e)=>{  
      fire(e);
    })

    emitterInited.current = true;

  },[emitter])

  return (
    <>

    </>
  )
}

export default Blaster