import useWorldState from 'Components/World/WorldStateManager';
import styles from './styles.module.scss';
import { useEffect } from 'react';

const NotifyForm=({})=>{
  const {disteudo} = useWorldState(state=>({disteudo:state.disteudo}));
  useEffect(()=>{
    if (!disteudo) return
    window.localStorage.setItem('disteudo',JSON.stringify(disteudo));
  },[disteudo])
  return (
    <>


<div id="mc_embed_shell">
  <div id="mc_embed_signup">
      <form action="https://ultraplush.us21.list-manage.com/subscribe/post?u=9a280f3c94a31dc054262001d&amp;id=898bd490c7&amp;f_id=002159e1f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_self" noValidate="">
          <div id="mc_embed_signup_scroll">
              <div className={styles.mcFieldGroup}>
                <label htmlFor="mce-EMAIL" className={styles.emailLabel}>Email Address</label>
                <input type="email" name="EMAIL" className={styles.emailInput} id="mce-EMAIL" required="" />
                <span id="mce-EMAIL-HELPERTEXT" className="helper_text"></span>
              </div>
          <div id="mce-responses" className="clearfalse">
              <div className="response" id="mce-error-response" style={{display: "none"}}></div>
              <div className="response" id="mce-success-response" style={{display: "none"}}></div>
          </div>
          <div aria-hidden="true" style={{position: "absolute", left: "-5000px"}}>
            <input type="text" name="b_9a280f3c94a31dc054262001d_898bd490c7" tabIndex="-1" /></div>
            <div className={styles.mcSubmitGroup}>
              <input type="submit" name="subscribe" id="mc-embedded-subscribe"  className={styles.subscribeBtn} value="Subscribe" />  
            </div>
      </div>
      </form>
  </div>
</div>
  





  
    </>
  )
}

export default NotifyForm