import {create} from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware'
import {Euler,Quaternion,Vector3,MathUtils} from 'three';
import useUIState from 'Components/UI/UIStateManager'
import ReactGA from 'react-ga4';
import { cryptoChipChop } from 'utils/generalUtils';

const disteudoApiRoot = process.env.NODE_ENV === "development"? 'https://cjqhmo34dl.execute-api.us-west-1.amazonaws.com/dev' : 'https://txswgu8sch.execute-api.us-west-1.amazonaws.com'
// TODO RESTORE PROPER VERSION ABOVE, BELOW VERSION IS FOR BUILDING OUT ENVS
// const disteudoApiRoot = process.env.NODE_ENV !== "development"? 'https://cjqhmo34dl.execute-api.us-west-1.amazonaws.com' : 'https://txswgu8sch.execute-api.us-west-1.amazonaws.com'



const useWorldState = create(subscribeWithSelector((set,get)=>({
  roomScale:{w:1920,h:1080},
  setRoomScale: (newRoomScale)=>set(state=>({roomScale:newRoomScale})),
  // orientationPermsReqd: (typeof DeviceOrientationEvent !== 'undefined' && !!DeviceOrientationEvent && !!DeviceOrientationEvent.requestPermission),
  // orientationPermission: !(typeof DeviceOrientationEvent !== 'undefined' && !!DeviceOrientationEvent && !!DeviceOrientationEvent.requestPermission), //inverse of the above - if orientationPerms are required we need them so false, if not required we don't need them so true by default
  // setOrientationPermission: (newPermissionState)=>{set({orientationPermission:newPermissionState})},
  gravity: [0,-60,0],
  accel: [0,0,0],
  currentSelectedTool: 'grabber',
  setCurrentSelectedTool: (newTool)=>(set({currentSelectedTool:newTool})),
  initMotionListeners: ()=>{
    const {rotationInitializer,motionHandler} = get();
    window.addEventListener('deviceorientation',rotationInitializer,false);
    window.addEventListener('devicemotion',motionHandler,false);
  },
  deInitMotionListeners: ()=>{
    const {rotationInitializer,motionHandler,rotationHandler} = get();
    window.removeEventListener('deviceorientation',rotationInitializer);
    window.removeEventListener('deviceorientation',rotationHandler);
    window.removeEventListener('devicemotion',motionHandler);
  },
  reInitMotionListeners: ()=>{
    const {rotationHandler,motionHandler} = get();
    window.addEventListener('deviceorientation',rotationHandler,false);
    window.addEventListener('devicemotion',motionHandler,false);
  },
  rotationHandler: (e)=>{
    if (!e.alpha || !e.beta || ! e.gamma) return;
    let alpha = MathUtils.degToRad(e.alpha);
    let beta = MathUtils.degToRad(e.beta);
    let gamma = MathUtils.degToRad(e.gamma);
    let eul = new Euler();
    eul.set (-beta,-gamma,alpha, 'YXZ') // line up our rotations such that they suit the negative z-axis setup we want
    let finalQuat = new Quaternion();
    finalQuat.setFromEuler(eul); // convert our rotation to quat
    let dir = new Vector3(0,0,-1); // define our negative z vector
    dir.applyQuaternion(finalQuat); // rotate it by our orientation quat
    dir.multiplyScalar(60); //TODO make gravity amplitude a prop?
    set(state=>({gravity:[dir.x,dir.y,dir.z]}))
  },
  rotationInitializer: (e)=>{
    // this is necessary because many browsers throw an inital empty "deviceorientation event" which can foul gravity on desktop, so we ignore first deviceorientation event before attaching real handler
    const initializer = get().rotationInitializer;
    const handler = get().rotationHandler;
    window.removeEventListener('deviceorientation',initializer);
    window.addEventListener('deviceorientation',handler,false)
  },
  motionHandler: (e)=>{
    let newAccel = [e.acceleration.x,e.acceleration.y,e.acceleration.z]
    if (newAccel[0] === null || newAccel[1] === null && newAccel[2] === null) {newAccel = [0,0,0]}
    set(state=>({accel:newAccel}))
  },
  accelAffectedBodies: [],
  setAccelAffectedBodies: (newAccelAffectedBodies)=>{
    set(state=>({accelAffectedBodies:newAccelAffectedBodies}))
  },
  addAccelAffectedBody: (body)=>{
    set(state=>({accelAffectedBodies:[...state.accelAffectedBodies,body]}))
  },
  removeAccelAffectedBody: (body)=>{
    const filteredAccelAffectedBodies = get().accelAffectedBodies.filter(accelAffectedBody=>accelAffectedBody!==body)
    set(state=>({accelAffectedBodies:filteredAccelAffectedBodies}))
  },
  renderList: [],
  setRenderList: (newRenderList)=>{
    set(state=>({renderList:newRenderList}))
  },
  addItemToRenderList: (component)=>{
    set(state=>({renderList:[...state.renderList,component]}))
  },
  removeItemFromRenderList: (mesh)=>{
    const origRenderList = get().renderList;
    // debugger;
    const filteredRenderList = origRenderList.filter(renderListItem=>renderListItem.props.name!==mesh.name)
    set(state=>({renderList:filteredRenderList}))
  },
  worldRenderer: null,
  setWorldRenderer: (newRenderer)=>set(state=>({worldRenderer:newRenderer})),
  isWorldPaused: false,
  setIsWorldPaused: (newPauseState)=>{
    // debugger;
    const {deInitMotionListeners,reInitMotionListeners} = get();
    if (!!newPauseState){
      deInitMotionListeners()
      set(state=>({isWorldPaused:newPauseState,/*frameloop:"never"*/}))
    }
    else {
      reInitMotionListeners()
      set(state=>({isWorldPaused:newPauseState,/*frameloop:"always"*/}))
    }
    
  },
  frameloop:"demand",
  setFrameloop: (newFrameloop)=>{set({frameloop:newFrameloop})},
  currentDieSides: [1,5,3,4,5],
  isElevatorOpen: false,
  setIsElevatorOpen: (newElevatorOpenState)=>set(state=>({isElevatorOpen:newElevatorOpenState})),
  requestFace: async()=>{
    fetch(`${disteudoApiRoot}/requestFace`)
    .then(resp=>resp.json()).then(data=>{console.log('requestFace response',data)})
  },
  disteudo: null,
  seekDisteudo: ()=>{
    // let _disteudo = window.localStorage.getItem('disteudo');
    let _disteudo = window.localStorage.getItem('disteudo');
    if (_disteudo) {
      // console.log('dehydrated disteudo pulled from localstorage',_disteudo)
      _disteudo = JSON.parse(_disteudo)
      window.localStorage.removeItem('disteudo');
      // console.log('disteudo retrieved from localstorage. _disteudo is',_disteudo )
      set({disteudo:_disteudo})
    }
    else {
      let _disteudo = window.sessionStorage.getItem('disteudo');
      if (_disteudo) {
        // console.log('dehydrated disteudo pulled from localstorage',_disteudo)
        _disteudo = JSON.parse(_disteudo)
        // console.log('disteudo retrieved from localstorage. _disteudo is',_disteudo )
        set({disteudo:_disteudo})
      }
      else {
        return null
      }
    }
    
    // window.localStorage.setItem('disteudo',_disteudo);
    window.sessionStorage.setItem('disteudo',JSON.stringify(_disteudo));
    // console.log('WSM returning disteudo',_disteudo)
    return _disteudo
  },
  setDisteudo: (newDisteudo)=>{
    // console.log('writing newDisteudo to session',newDisteudo)
    // window.localStorage.setItem('disteudo',JSON.stringify(newDisteudo));
    window.sessionStorage.setItem('disteudo',JSON.stringify(newDisteudo));
    set(state=>({disteudo:newDisteudo}))
  },
  fetchDisteudo: async (disteudoKey)=>{
    // hit fetchDisteudo endpoint
    // console.log('fetchDisteudo, disteudoKey:',disteudoKey)
    return new Promise(async (res,rej)=>{
      const setDisteudo = get().setDisteudo;
      const navTo = get().navTo;
      const writeUrl = `${disteudoApiRoot}/fetchDisteudo`;      
      
      fetch(writeUrl,{
        method: "POST",
        // mode: "no-cors",
        // cache: "no-cache",
        // credentials: "omit",
        // headers: {
        //   "Content-Type": "application/json"
        // },
        // referrerPolicy: "no-referrer",
        body: JSON.stringify({key:disteudoKey})
      })
      .then(resp=>resp.json()).then((data)=>{
        if(data==="notFound"){
          rej('notFound');
        }
        else{
          res(data)
        }
        // console.log('fetchDisteudo resp',data)
      })
      .catch((err)=>{
        rej(err);
        console.log('fetchDisteudo err',err)
      })
      // .then((resp)=>{
      //   console.log('success writing disteudo, resp was',resp)
      //   res(resp);
      // })
      // .catch((err)=>{console.log('err writing disteudo:',err)})
    })
    
  },
  navTo: (dest,replace=false)=>{
    ReactGA.event({
      category: 'navigation',
      action: 'navTo',
      label: dest
    })
    const nav = get().navigationUtil;
    nav(dest,replace)
  },
  writeDisteudo: ({key,name,faceUrl,createdOn,lastSeen,lastSeenIn})=>{
    // fetch(`${disteudoApiRoot}/writeDisteudo`)
    // .then(resp=>resp.json()).then(data=>{console.log('writeDisteudo response',data)})
    return new Promise(async (res,rej)=>{
      const setDisteudo = get().setDisteudo;
      const navTo = get().navTo;
      const writeUrl = `${disteudoApiRoot}/writeDisteudo`;
      let token = faceUrl.slice(faceUrl.lastIndexOf('/')+1,faceUrl.lastIndexOf('.'))
      token = cryptoChipChop(token,key);
      
      const newDisteudo = {
        key: key,
        name: name,
        faceUrl: faceUrl,
        createdOn: createdOn,
        lastSeen: lastSeen,
        lastSeenIn: lastSeenIn
      }

      fetch(writeUrl,{
        method: "POST",
        // mode: "no-cors",
        // cache: "no-cache",
        // credentials: "omit",
        // headers: {   
        //   "Content-Type": "application/json"
        // },
        // referrerPolicy: "no-referrer",
        body: JSON.stringify({...newDisteudo, token:token.h})
      }).then(resp=>resp.json()).then((data)=>{
        // console.log('writeDisteudo resp',data)
        ReactGA.event({
          category: 'store-disteudo-success',
          action: 'store-new-disteudo',
          label: `disteudo.face:${JSON.stringify(newDisteudo.face)},disteudoName:${newDisteudo.name}`
        })
        get().setDisteudo(newDisteudo)
        res();
      })
      .catch((err)=>{
        //shh
        rej();
      })
    })
  },
  navigationUtil: null,
  setNavigationUtil: (newUtil)=>{
    set({navigationUtil:newUtil})
  },
  lastWorldRender: 0,
  setLastWorldRender: (timestamp)=>{
    set({lastWorldRender:timestamp})
  }
})));

const _UIState = useUIState.getState();
const _worldState = useWorldState.getState();

// init motion listeners
if (!!_UIState.orientationPerms) { // os with default true orientation perms, init motion listeners
  _worldState.initMotionListeners();
}
else { // we need to request orientation perms
  const unsubOrientationPerms = useUIState.subscribe(state => state.orientationPerms,(newPerms)=>{ 
    if (!!newPerms) {
      unsubOrientationPerms();
      _worldState.initMotionListeners();
    }
  }) 
}

// //pause room rendering + physics when hub is open
// useUIState.subscribe(state=>state.isHubShown,(isHubShown)=>{
//   useWorldState.setState({frameloop:isHubShown? 'never':'demand'})
// })


export default useWorldState;