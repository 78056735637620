import { RoomVerts, RoomUVs, RoomNormals } from "./verts";
import React, { useRef, useEffect, useLayoutEffect, useCallback, useState, Suspense } from "react";
import {useLoader, useThree } from "@react-three/fiber";
import { RepeatWrapping,TextureLoader, BufferAttribute, Vector3 } from "three";
import TestMap3 from "assets/tex/newTex-2-14-21-c_curves-1.jpg";
import CollisionPlane from './collisionPlane';
import throttle from 'lodash/throttle';
import TileCeiling from "./TileCeiling";
import TileFloor from "./TileFloor";
import RectLightArray from "Components/RectLightArray";
import WallLightmap from '../../../assets/tex/newLightmap3_3-01-22.bmp'
import WallText from "../WallText";
import Chair from 'Components/World/Chair'
import Plant from "../Plant";
import BasicBall from 'Components/World/BasicBall'
import ElePlane from "../ElePlane";
import { useKTX2 } from "@react-three/drei";
import RenderList from "../RenderList";
import cyanExplosion from '../../../assets/sprite/cyanExplosionSpriteSheet256.png'
import magentaExplosion from '../../../assets/sprite/magentaExplosionSpriteSheet256.png';
// import _burnTex from '../../../assets/tex/burnDecal.png'
import BriefcaseRender from 'assets/tex/briefcaseExtraction1_c.png';
import SmokePuffDark from '../../../assets/sprite/smokePuffDark1.png'
import SmokePuffLight from '../../../assets/sprite/smokePuffLight1.png'

const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

const getHeight = () => window.innerHeight 
|| document.documentElement.clientHeight 
|| document.body.clientHeight;

function AspectRoom({useWorldState,...props}) {
  //base fundamental units on screen dims
  const setRoomScale = useWorldState(state=>state.setRoomScale)
  const setWorldRenderer = useWorldState(state=>state.setWorldRenderer)
  const aspect = window.innerWidth / window.innerHeight;
  const defaultFit = aspect > 1 ? 1.03 : 1.05 ;
  const fitRatio = props.fitRatio ? props.fitRatio : defaultFit;
  const roomDepth = props.roomDepth ? props.roomDepth : 50;
  const pxToUnit = 10;
  const lightCutDepth = 0.046774;
  const lightCutWidth = 0.1;
  const [halfDims,setHalfDims] = React.useState({x:0,y:0});
  const {camera,gl,invalidate} = useThree();
  const geoRef = useRef();

  const imageCache = useRef([])

  const {setRenderList,addItemToRenderList} = useWorldState((state)=>({setRenderList:state.setRenderList,addItemToRenderList:state.addItemToRenderList}));
  const renderListInited = useRef(false);


  //PRELOAD TEX TEST
  const vaporCacheCyan=useLoader(TextureLoader,cyanExplosion)
  const vaporCacheMagenta=useLoader(TextureLoader,magentaExplosion)
  const vaporCacheBlack=useLoader(TextureLoader,SmokePuffDark)
  const vaporCacheWhite=useLoader(TextureLoader,SmokePuffLight)
  
  // const briefcaseCache = useLoader(TextureLoader,BriefcaseRender)
  useEffect(()=>{
    let img = new Image();
    img.src = BriefcaseRender;
    imageCache.current.push(img);

    // img = new Image();
    // img.src = DiceFinalPlate;
    // imageCache.current.push(img);
  },[])

  
  // useEffect(()=>{
    
  // },[])

  useEffect(()=>{
    if (!setWorldRenderer || ! gl) return;
    setWorldRenderer(gl);
  },[gl,camera, setWorldRenderer])
  const [wallAlbedoTex] = useKTX2([WallLightmap])

  const setSizes = ()=>{
    setHalfDims({
      x: getWidth() / pxToUnit / 2,
      y: getHeight()/pxToUnit/2
    })
    setRoomScale({w:getWidth() / pxToUnit / 2,d:-roomDepth/2,h:getHeight()/pxToUnit/2})
  }

  const positionCam = ()=>{
    // when browser window size is changed - dolly cam to fit new geo
    
    if (!geoRef?.current) return;
    geoRef.current.computeBoundingBox();
    const box = geoRef.current.boundingBox;
    const size = new Vector3();
    box.getSize(size); 
    const maxSize = Math.max( size.x, size.y );
    const fitHeightDistance = maxSize / ( 2 * Math.atan( Math.PI * camera.fov / 360 ) );
    const fitWidthDistance = fitHeightDistance / camera.aspect;
    const distance = fitRatio * Math.min( fitHeightDistance, fitWidthDistance );
    camera.position.z = distance;
  }

  // add resize handlers
  useEffect(()=>{
    const _setSizesDB = throttle(setSizes,100);
    window.addEventListener('resize',_setSizesDB)
    return ()=>{
      window.removeEventListener('resize',_setSizesDB)
    }
  },[])

  // respond halfDims change
  useEffect(()=>{
    positionCam();
  },[halfDims])
  
  //set base sizes
  useEffect(()=>{
    setSizes();
  },[])

  
  useLayoutEffect(()=>{
    if (!geoRef.current) return;
    // size room to cam aspect and position cam to fit

    // set visible geo verts
    geoRef.current.setAttribute(
      "position",
      new BufferAttribute(
        RoomVerts(
          halfDims.x,
          halfDims.y,
          roomDepth,
          lightCutDepth,
          lightCutWidth
        ),
        3
      )
    );
    geoRef.current.setAttribute("uv", new BufferAttribute(RoomUVs, 2));
    geoRef.current.setAttribute("normal", new BufferAttribute(RoomNormals, 3));
    
    // init renderList with staged geo
    if (!!renderListInited.current || halfDims.x == 0 || halfDims.y == 0) return
    initRenderList();
    renderListInited.current = true;
    setTimeout(initBalls,3500)
  },[halfDims])

  const initRenderList = useCallback(()=>{
    setRenderList([
      <Plant key={`staged_plant_1`} name={`staged_plant_1`} position={[halfDims.x-10,-halfDims.y+8,-roomDepth + 0]}/>,
      <Chair key={`staged_chair_1`} name={`staged_chair_1`} position={[halfDims.x-10,-halfDims.y+8,-roomDepth + 20]}/>,
      <Chair key={`staged_chair_2`} name={`staged_chair_2`} position={[halfDims.x-10,-halfDims.y+8,-roomDepth + 40]}/>,
      <Chair key={`staged_chair_3`} name={`staged_chair_3`} position={[halfDims.x-10,-halfDims.y+8,-roomDepth + 60]}/>
    ])
  },[halfDims,roomDepth])
  

  const initBalls = useCallback(()=>{
    const toAdd = [
      <BasicBall key={'ballBurst_1'} name={'ballBurst_1'} position={[0,0,0]} />,	
      <BasicBall key={'ballBurst_2'} name={'ballBurst_2'} position={[0,8,-10]} />,	
      <BasicBall key={'ballBurst_3'} name={'ballBurst_3'} position={[7,8,2]} />,	
      <BasicBall key={'ballBurst_4'} name={'ballBurst_4'} position={[-7,8,0]} />,	
      <BasicBall key={'ballBurst_5'} name={'ballBurst_5'} position={[8,0,-10]} />,	
      <BasicBall key={'ballBurst_6'} name={'ballBurst_6'} position={[-8,8,-10]} />,	
      <BasicBall key={'ballBurst_7'} name={'ballBurst_7'} position={[7,3,2]} />,	
      <BasicBall key={'ballBurst_8'} name={'ballBurst_8'} position={[-7,3,0]} />,	
      <BasicBall key={'ballBurst_9'} name={'ballBurst_9'} position={[4,8,-10]} />,	
      <BasicBall key={'ballBurst_10'} name={'ballBurst_10'} position={[-4,8,-10]} />,	
      <BasicBall key={'ballBurst_11'} name={'ballBurst_11'} position={[-12,8,2]} />,	
      <BasicBall key={'ballBurst_12'} name={'ballBurst_12'} position={[12,8,0]} />,	
      <BasicBall key={'ballBurst_13'} name={'ballBurst_13'} position={[18,0,-10]} />,
      <BasicBall key={'ballBurst_14'} name={'ballBurst_14'} position={[-18,8,-10]} />,	
      <BasicBall key={'ballBurst_15'} name={'ballBurst_15'} position={[7,8,2]} />,	
      <BasicBall key={'ballBurst_16'} name={'ballBurst_16'} position={[-7,8,0]} />,	
      <BasicBall key={'ballBurst_17'} name={'ballBurst_17'} position={[1,0,-10]} />,	
      <BasicBall key={'ballBurst_18'} name={'ballBurst_18'} position={[0,8,-10]} />,	
      <BasicBall key={'ballBurst_19'} name={'ballBurst_19'} position={[7,8,2]} />,	
      <BasicBall key={'ballBurst_20'} name={'ballBurst_20'} position={[-7,8,0]} />, 
      <BasicBall key={'ballBurst_21'} name={'ballBurst_21'} position={[0,0,0]} />,	
      <BasicBall key={'ballBurst_22'} name={'ballBurst_22'} position={[0,8,-10]} />,	
      <BasicBall key={'ballBurst_23'} name={'ballBurst_23'} position={[7,8,2]} />,	
      <BasicBall key={'ballBurst_24'} name={'ballBurst_24'} position={[-7,8,0]} />,	
      <BasicBall key={'ballBurst_25'} name={'ballBurst_25'} position={[8,0,-10]} />,	
      <BasicBall key={'ballBurst_26'} name={'ballBurst_26'} position={[-8,8,-10]} />,	
      <BasicBall key={'ballBurst_27'} name={'ballBurst_27'} position={[7,3,2]} />,	
      <BasicBall key={'ballBurst_28'} name={'ballBurst_28'} position={[-7,3,0]} />,	
      <BasicBall key={'ballBurst_29'} name={'ballBurst_29'} position={[4,8,-10]} />,	
      <BasicBall key={'ballBurst_30'} name={'ballBurst_30'} position={[-4,8,-10]} />,	
      <BasicBall key={'ballBurst_31'} name={'ballBurst_31'} position={[-12,8,2]} />,	
      <BasicBall key={'ballBurst_32'} name={'ballBurst_32'} position={[12,8,0]} />,	
      <BasicBall key={'ballBurst_33'} name={'ballBurst_33'} position={[18,0,-10]} />,
      <BasicBall key={'ballBurst_34'} name={'ballBurst_34'} position={[-18,8,-10]} />,	
      <BasicBall key={'ballBurst_35'} name={'ballBurst_35'} position={[7,8,2]} />,	
      <BasicBall key={'ballBurst_36'} name={'ballBurst_36'} position={[-7,8,0]} />,	
      <BasicBall key={'ballBurst_37'} name={'ballBurst_37'} position={[1,0,-10]} />,	
      <BasicBall key={'ballBurst_38'} name={'ballBurst_38'} position={[0,8,-10]} />,	
      <BasicBall key={'ballBurst_39'} name={'ballBurst_39'} position={[7,8,2]} />,	
      <BasicBall key={'ballBurst_40'} name={'ballBurst_40'} position={[-7,8,0]} />, 
    ]

    const delayPeriod = 20;
    let i = 0;
    const addNextBall = ()=>{
      addItemToRenderList(toAdd[i]);
      invalidate();
      if (i < toAdd.length - 1) {
        i++;
        setTimeout(addNextBall,delayPeriod)
      }
    }

    addNextBall();

  },[])

  const testMap = useLoader(TextureLoader, TestMap3);
  testMap.wrapS = RepeatWrapping;
  testMap.wrapT = RepeatWrapping;
  // const wallsDecals = useRef([]);
  const [wallsDecals,setWallsDecals] = useState([])
  const RoomGeo = useCallback(()=>{
    if (!halfDims.x || !halfDims.y) return null
    return (
      <>
        <CollisionPlane rotation={[0,1.5708,0]} position={[-halfDims.x,0,0]} name="wallNegX"/> 
        <CollisionPlane rotation={[0,-1.5708,0]} position={[halfDims.x,0,0]} name="wallPosX"/>
        <Suspense fallback={null}>
          <TileCeiling roomDepth={roomDepth} halfDims={halfDims} lightCutDepth={lightCutDepth} lightCutWidth={lightCutWidth} />
          <TileFloor roomDepth={roomDepth} halfDims={halfDims} lightCutDepth={lightCutDepth} lightCutWidth={lightCutWidth} />
        </Suspense>
        <CollisionPlane rotation={[0,0,0]} position={[0,0,-roomDepth * 1.35]} name="wallNegZ"/> 
        <CollisionPlane rotation={[0,-3.14159,0]} position={[0,0,roomDepth *0.65]} name="wallPosZ"/>
        <Suspense>
          <ElePlane position={[-halfDims.x*0.99,-halfDims.y/1.9,-30.5]} />
        </Suspense>
        <mesh position-z={-roomDepth / 2.75} {...props} name="walls">
          <bufferGeometry ref={geoRef} attach="geometry" />
          <meshBasicMaterial attach="material" map={wallAlbedoTex} />
          
          {/* <BurnDecal />  */}
          {/* {wallsDecals.current} */}
        </mesh>
        {/* FLOOR RECT LIGHT ARRAY */}
        <RectLightArray count={Math.round(halfDims.x/5)} depth={5} width={halfDims.x*2} position={[-halfDims.x,-halfDims.y+2.8,-roomDepth*1.3]} rotation={[-1.57,0,0]}/>
        <RectLightArray count={Math.round((roomDepth*2)/13)} depth={3} width={roomDepth*2} position={[halfDims.x - 3, -halfDims.y+3,-roomDepth-3]} rotation={[-1.57,0,-1.57]} zTravel={true}/>
        <RectLightArray count={Math.round((roomDepth*2)/13)} depth={3} width={roomDepth*2} position={[-halfDims.x + 3, -halfDims.y+3,-roomDepth-3]} rotation={[-1.57,0,-1.57]} zTravel={true} skip={[1,2,3]}/>

        {/* CEIL RECT LIGHT ARRAY */}
        <RectLightArray count={Math.round(halfDims.x/5)} depth={3} width={halfDims.x*2} position={[-halfDims.x,halfDims.y+1,-roomDepth*1.3]} rotation={[-1.57,0,0]} isCeil={true} color={0xedf4ff}/>
        <RectLightArray count={Math.round((roomDepth*2)/13)} depth={3} width={roomDepth*2} position={[halfDims.x - 2, halfDims.y+1,-roomDepth-6]} rotation={[-1.57,0,-1.57]} zTravel={true} isCeil={true} color={0xedf4ff}/>
        <RectLightArray count={Math.round((roomDepth*2)/13)} depth={3} width={roomDepth*2} position={[-halfDims.x + 2, halfDims.y+1,-roomDepth-6]} rotation={[-1.57,0,-1.57]} zTravel={true} isCeil={true} color={0xedf4ff}/>
        <Suspense fallback={null}>
          <WallText position={[0,0,-roomDepth * 1.3]} xDim={halfDims.x * 1.65} yDims={1} />
        </Suspense>
      </>
    )
  },[halfDims,roomDepth,lightCutDepth,lightCutWidth])

  if (!halfDims.x || !halfDims.y) return null
  return (
    <>
      <RoomGeo />
      <RenderList />
    </>
  );
}

export default AspectRoom;
