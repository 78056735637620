// import { useState } from 'react'
import gsap from 'gsap';
import {useRef,useEffect,useState } from 'react';
import baseStyles from './styles.module.scss'


const DialogueRenderer = (props)=>{
  const {contentItem,convo,handleAction,convoStep,defaultItemAnimInDelay=0.3,defaultItemAnimInTime=2,defaultItemAnimOutDelay=3,defaultItemAnimOutTime=0.5,styles,defaultResponseAnimInDelay=1,defaultResponseAnimInTime=1,defaultSectionAnimOutDelay=0.3,defaultSectionAnimOutTime=2,defaultItemMaxBlurEm=2,defaultAutoFadeDelay=1,autoAdvance=true,..._props} = props;
  const wrapperRef= useRef();
  const outputWrapperRef= useRef();
  const inputWrapperRef= useRef();
  const [wrappedContent,setWrappedContent] = useState(null)
  const [wrappedResponses,setWrappedResponses] = useState(null)
  const timelineRef = useRef();
  const lastContentItem = useRef();
  const hasJustFadedOut = useRef();
  //when we get new dialogue or convoStep, we append dialogue[convoStep].output items to the dom with 0 opacity each
  //we then create a timeline that fades them all in, I think we can get away with using querySelector/children to do this, as long as we have the proper wrapper refs in place

  useEffect(()=>{
    
    if (lastContentItem.current?.forceFadeout && !hasJustFadedOut.current) {
      doFadeOut().then(()=>{
        hasJustFadedOut.current = false;
        lastContentItem.current = contentItem;
        setWrappedContent(null)
        setWrappedResponses(null)
        createWrappedContent();
      })
    }
    else {
      hasJustFadedOut.current = false;
      lastContentItem.current = contentItem;
      setWrappedContent(null)
      setWrappedResponses(null)
      createWrappedContent();
    }
  },[contentItem])

  const doFadeOut = ()=>{
    return new Promise((res,rej)=>{
    const itemsToFade = [...outputWrapperRef.current.querySelectorAll("[data-purpose='itemFadeWrapper']"),inputWrapperRef.current.querySelector("[data-purpose='groupFadeWrapper']")];
      gsap.to(itemsToFade,{
        opacity: 0,
        duration: (convo.hasOwnProperty('sectionAnimOutTime'))? convo.sectionAnimOutTime : defaultSectionAnimOutTime,
        delay: (convo.hasOwnProperty('sectionAnimOutDelay'))? convo.sectionAnimOutDelay : defaultSectionAnimOutDelay,
        onComplete: function(e){
          hasJustFadedOut.current = true;
          res();
        }
      })
    })
  }

  const _handleAction = (action,convoStep)=>{
    if (action.type === "NEXT" || action.type === "PREVIOUS" || action.type === "GOTO" || action.type === "RESTART" || action.shouldFadeOutPrior ) {
      const _action = action;
      // fade out existing content and responses.
      doFadeOut().then(()=>{
        //SCROLL TO 0 HERE
        try {
          wrapperRef.current.parentElement.parentElement.scrollTop = 0; 
        }
        catch(err){
          //shhhh
        }
        handleAction(_action,convoStep)
      })
    }
    else {
      handleAction(action,convoStep)
    }
  }

  const createWrappedContent = ()=>{    
    const _wrappedContent = contentItem.content.map((item,idx)=>{
      const customWrapperClass = (item.parentClass)? item.parentClass:'';
      return <div data-purpose="itemFadeWrapper" className={customWrapperClass} key={`step${convoStep}_${idx}`} style={{opacity:0}}>{item.item}</div>
    })
    setWrappedContent(<div data-purpose="groupFadeWrapper" style={{opacity:1}}>{_wrappedContent}</div>);
    if (contentItem.responses && !!contentItem.responses.length)  {
      const _wrappedResponses = contentItem.responses.map((response,idx)=>{
        return <div data-purpose="responseItemFadeWrapper" key={`resp${convoStep}_${idx}`}><button className={`${baseStyles.BlaBlaButton} ${styles.BlaBlaButton}`} onClick={()=>{_handleAction(response.action,convoStep)}}>{response.text}</button></div>
      })
      setWrappedResponses(<div data-purpose="groupFadeWrapper" style={{opacity:0}}>{_wrappedResponses}</div>)
    }
  }

  useEffect(()=>{
    populateTimeline();
  },[wrappedContent,wrappedResponses])

  useEffect(()=>{
    //cleanup 
    return ()=>{
      timelineRef.current.kill();
      timelineRef.current = null;
    }
  },[convoStep])

  const populateTimeline = ()=>{
    if (!wrappedContent) return;
    //content 
    const itemsToFade = [...outputWrapperRef.current.querySelectorAll("[data-purpose='itemFadeWrapper']")]
    const _tl = gsap.timeline({
      paused:true,
      onComplete: timelineComplete,
    })
    itemsToFade.map((content,idx)=>{
      const itemAnimInDelay = (contentItem?.content[idx]?.hasOwnProperty('animInDelay'))? contentItem.content[idx].animInDelay : defaultItemAnimInDelay;
      const itemAnimInTime = (contentItem?.content[idx]?.hasOwnProperty('animInTime'))? contentItem.content[idx].animInTime : defaultItemAnimInTime;
      const itemMaxBlur = (contentItem?.content[idx]?.hasOwnProperty('animMaxBlurEm'))? contentItem.content[idx].animMaxBlurEm : defaultItemMaxBlurEm;
      let fadeAnim = {t:0}
      _tl.to(fadeAnim,{
        t:1,
        duration: itemAnimInTime,
        delay: itemAnimInDelay,
        onUpdate:function(e){
          content.style.opacity = fadeAnim.t;
          content.style.filter = `blur(${itemMaxBlur-(fadeAnim.t*itemMaxBlur)}em)`;
        },
        onComplete:function(e){
          if (contentItem?.content[idx]?.animOutTime) {
            const itemAnimOutDelay = (contentItem?.content[idx]?.hasOwnProperty('animOutDelay'))? contentItem.content[idx].animOutDelay : defaultItemAnimOutDelay;
            const itemAnimOutTime = (contentItem?.content[idx]?.hasOwnProperty('animOutTime'))? contentItem.content[idx].animOutTime : defaultItemAnimOutTime;
            gsap.to(content,{
              opacity: 0,
              duration: itemAnimOutTime,
              delay: itemAnimOutDelay
            })
          }
        }
      })
      timelineRef.current = _tl;
      return null;
    })

    //responses
    if (contentItem.responses && contentItem.responses.length) {
      let responseAnimInDelay = (contentItem.hasOwnProperty('responseAnimInDelay'))? contentItem.responseAnimInDelay : defaultResponseAnimInDelay;
      let responseAnimInTime = (contentItem.hasOwnProperty('responseAnimInTime'))? contentItem.responseAnimInTime : defaultResponseAnimInTime;
      const responsesGroupToFade = inputWrapperRef.current.querySelector("[data-purpose='groupFadeWrapper']");
      _tl.to(responsesGroupToFade,{
        opacity: 1,
        duration: responseAnimInTime,
        delay: responseAnimInDelay,
        // onUpdate: function(){
        // }
      })
    }

    _tl.play();
  }

  const timelineComplete = ()=>{
    if (!contentItem.responses && autoAdvance) {
      /* auto advance logic here */
      let autoFadeDelay = (convo.hasOwnProperty('autoFadeDelay'))? convo.autoFadeDelay * 1000 : defaultAutoFadeDelay * 1000;
      autoFadeDelay = contentItem.hasOwnProperty('autoFadeDelay')? contentItem.autoFadeDelay * 1000 : defaultAutoFadeDelay * 1000;
      setTimeout(()=>{
        _handleAction({type:'NEXT'},convoStep)
      },autoFadeDelay)
    }
  }
  
  return (
    <>
       <div className={`${baseStyles.BlaBlaDialogueRendererWrapper} ${styles.BlaBlaDialogueRendererWrapper}`} {..._props} ref={wrapperRef}>
         <div className={`${baseStyles.BlaBlaDialogueOutputWrapper} ${styles.BlaBlaDIalogueOutputWrapper}`} ref={outputWrapperRef}>
           {wrappedContent}
         </div>
         <div className={`${baseStyles.BlaBlaDialogueInputWrapper} ${styles.BlaBlaDialogueInputWrapper} ${contentItem.customResponsesWrapperClass}`} ref={inputWrapperRef} data-utility="responseButtonsWrapper">
           {wrappedResponses}
         </div>
       </div>
    </>
  )
}



export {DialogueRenderer}