import { Canvas, useFrame } from '@react-three/fiber'
import DynamicPhysics from 'Components/World/DynamicPhysics'
import AspectRoom from 'Components/World/AspectRoom'
import { NoToneMapping } from 'three'
import useWorldState from '../WorldStateManager'
import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react'
import EventEmitter from 'eventemitter3'
import Grabber from '../InteractionTools/Grabber/Grabber'
import Launcher from '../InteractionTools/Launcher'
import Blaster from '../InteractionTools/Blaster'

const WorldBase=({})=>{
  const {frameloop,currentSelectedTool,setLastWorldRender,isWorldPaused} = useWorldState(state=>({frameloop:state.frameloop,currentSelectedTool:state.currentSelectedTool,setLastWorldRender:state.setLastWorldRender,isWorldPaused:state.isWorldPaused}))
  const canvasRef = useRef();
  const eventsInited = useRef(false);
  const launcherEmitter =  useRef(new EventEmitter());
  const grabberEmitter = useRef(new EventEmitter());
  const blasterEmitter = useRef(new EventEmitter());
  const currentToolRef = useRef('launcher');
  const [lastLaunchTime,setLastLaunchTime] = useState(0); //need state to force re-render upon launch 

  useEffect(()=>{
    setLastWorldRender(Date.now())
  },[])

  useEffect(()=>{
    currentToolRef.current=currentSelectedTool;
  },[currentSelectedTool])

  const onMouseDown = useCallback((e)=>{
    if (e.touches && e.touches.length) return
    if (currentToolRef.current === "launcher") {
      launcherEmitter.current.emit('start',{coords:{x:e.clientX,y:e.clientY}})
    }
    else if (currentToolRef.current === "grabber") {
      const coords = {x:e.clientX,y:e.clientY}
      grabberEmitter.current.emit('down',{coords:coords})
    }
    else if (currentToolRef.current === "blaster") {
      const coords = {x:e.clientX,y:e.clientY}
      blasterEmitter.current.emit('fire',{coords:coords})
    }

  },[])

  const onTouchStart = useCallback((e)=>{
    e.preventDefault();
    e.stopPropagation();
    if (e.nativeEvent) {
      e.nativeEvent.stopImmediatePropagation();
      e.nativeEvent.stopPropagation();
      e.nativeEvent.preventDefault();
    }
    
    if (currentToolRef.current === "launcher") {
      launcherEmitter.current.emit('start',{coords:{x:e.touches[0].clientX,y:e.touches[0].clientY}})
    }
    else if (currentToolRef.current === "grabber") {
      grabberEmitter.current.emit('down',{coords:{x:e.touches[0].clientX,y:e.touches[0].clientY}})
    }
    else if (currentToolRef.current === "blaster") {
      blasterEmitter.current.emit('fire',{coords:{x:e.touches[0].clientX,y:e.touches[0].clientY}})
    }
    return false;
  },[]);

  const onMouseMove = useCallback((e)=>{
    if (currentToolRef.current === "launcher") {
      launcherEmitter.current.emit('move',{coords:{x:e.clientX,y:e.clientY}})
    }
    else if (currentToolRef.current === "grabber") {
      e.preventDefault();
      grabberEmitter.current.emit('move',{coords:{x:e.clientX,y:e.clientY}})
    }
  },[]);

  const onTouchMove = useCallback((e)=>{
    e.preventDefault();
    if (currentToolRef.current === "launcher") {
      launcherEmitter.current.emit('move',{coords:{x:e.touches[0].clientX,y:e.touches[0].clientY}})
    }
    else if (currentToolRef.current === "grabber") {
      grabberEmitter.current.emit('move',{coords:{x:e.touches[0].clientX,y:e.touches[0].clientY}})
    }
  },[])

  const onTouchEnd = useCallback((e)=>{
    e.stopPropagation();
    e.preventDefault();
    if (currentToolRef.current === "launcher") {
      launcherEmitter.current.emit('stop',{coords:{x:e.clientX,y:e.clientY}})
    }
    else if (currentToolRef.current === "grabber") {
      grabberEmitter.current.emit('up',{coords:{x:e.clientX,y:e.clientY}})
    }
  },[])

  const onMouseUp = useCallback((e)=>{
    if (currentToolRef.current === "launcher") {
      launcherEmitter.current.emit('stop',{coords:{x:e.clientX,y:e.clientY}})
    }
    else if (currentToolRef.current === "grabber") {
      grabberEmitter.current.emit('up',{coords:{x:e.clientX,y:e.clientY}})
    }
  },[])

  useLayoutEffect(()=>{
    if (!canvasRef.current || !!eventsInited?.current) return;
    //init input events
    canvasRef.current.addEventListener('mousedown', onMouseDown, {passive: false});	
    canvasRef.current.addEventListener('mouseup', onMouseUp, {passive: false});	
    canvasRef.current.addEventListener('mousemove', onMouseMove, {passive: false});	
    canvasRef.current.addEventListener('touchstart', onTouchStart, {passive: false});	
    canvasRef.current.addEventListener('touchend', onTouchEnd, {passive: false});	
    canvasRef.current.addEventListener('touchmove', onTouchMove, {passive: false});	
    eventsInited.current=true;
    return ()=>{
      //dismount listeners
      canvasRef.current.removeEventListener('mousedown', onMouseDown);	
      canvasRef.current.removeEventListener('mouseup', onMouseUp);	
      canvasRef.current.removeEventListener('mousemove', onMouseMove);	
      canvasRef.current.removeEventListener('touchstart', onTouchStart);	
      canvasRef.current.removeEventListener('touchend', onTouchEnd);	
      canvasRef.current.removeEventListener('touchmove', onTouchMove);	
      eventsInited.current=false;
    }
  },[])
  const DisableRender = () => useFrame(() => null, 1000) 
  return (
    <>
      
        
          <Canvas ref={canvasRef} shadows srgb="true" gl={{ alpha: false, toneMapping: NoToneMapping }} camera={{ position: [0, 0, 3.55], fov: 50 }} frameloop={frameloop}>
            {isWorldPaused && <DisableRender />}
            {/* <hemisphereLight intensity={0.05} /> */}
            <pointLight intensity={1} position={[0,50,0]} color={0xe5ddc4} />
            {/* <spotLight position={[10, 10, 10]} angle={0.3} penumbra={2} intensity={2} castShadow /> */}
            
              <DynamicPhysics>
                {/* <Plane />
                <Cube />
                <Cube position={[0, 10, -2]} />
                <Cube position={[0, 20, -2]} /> */}
                <Grabber emitter={grabberEmitter.current}/>
                <Launcher emitter={launcherEmitter.current} lastLaunchTime={lastLaunchTime} setLastLaunchTime={setLastLaunchTime}/>
                <Blaster emitter={blasterEmitter.current} />
                <AspectRoom name="aspectRoom" useWorldState={useWorldState}/>	
            
                {/* <Balloon /><Balloon /><Balloon /><Balloon /> */}
              </DynamicPhysics>
            
            
            {/* <EffectComposer>
              <SSAO
                blendFunction={BlendFunction.MULTIPLY} // blend mode
                samples={30} // amount of samples per pixel (shouldn't be a multiple of the ring count)
                rings={4} // amount of rings in the occlusion sampling pattern
                distanceThreshold={1.0} // global distance threshold at which the occlusion effect starts to fade out. min: 0, max: 1
                distanceFalloff={0.0} // distance falloff. min: 0, max: 1
                rangeThreshold={0.5} // local occlusion range threshold at which the occlusion starts to fade out. min: 0, max: 1
                rangeFalloff={0.1} // occlusion range falloff. min: 0, max: 1
                luminanceInfluence={0.9} // how much the luminance of the scene influences the ambient occlusion
                radius={20} // occlusion sampling radius
                scale={0.5} // scale of the ambient occlusion
                bias={0.5} // occlusion bias
              />
              <Vignette offset={0.1} darkness={0.71} />
            </EffectComposer> */}
          </Canvas>
    </>
  )
}

export default WorldBase