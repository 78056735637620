import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from './styles.module.scss';
import {PortableText} from '@portabletext/react'
import useUIState from 'Components/UI/UIStateManager';
import {ReactComponent as ShareIcon} from '../../assets/img/shareIcon.svg'
import {ReactComponent as Clipboard} from '../../assets/img/clipboard.svg'
import ReactGA from 'react-ga4';

const ExpandedPostContent=({post})=>{
  const [showShareBar,setShowShareBar] = useState(false) 
  const [clipboardCopied,setClipboardCopied] = useState(false)
  const shareBarRef = useRef();
  const hideShareBar = useCallback((e)=>{
    if (!shareBarRef.current?.contains(e.target)){
      setShowShareBar(false)
      window.removeEventListener('click',hideShareBar)
    }
  },[showShareBar,setShowShareBar])
  const {urlForSanityImage} = useUIState(state=>({urlForSanityImage:state.urlForSanityImage}))
  const renderedPostContent = useMemo(()=>{
    if (!post) return null;
    return <PortableText
      value={post.content}
      components={{
        types: {
          image: ({value})=>{return <img src={urlForSanityImage(value)} />}
        }
      }}
    />
  },[post])
  const sharePost = useCallback((e)=>{
    e.preventDefault();
    e.stopPropagation();
    ReactGA.event({
      category: 'share',
      action: 'share-post',
      label: post.slug.current
    })
    if (navigator.share) {
      navigator.share({
        title: post.title,
        url: `${window.location.href}/${post.slug.current}`
      })
    }
    else {
      setShowShareBar(true)
      window.addEventListener('click',hideShareBar)
    }
  },[post]) 

  useEffect(()=>{
    if (!!clipboardCopied) {
      setTimeout(()=>{
        setShowShareBar(false);
        setClipboardCopied(false);
      },1000)
    }
  },[clipboardCopied])


  return (
    <div className={styles.ExpandedPostContent}>
      <div className={styles.stickyHeader}>
        <h1 className={styles.ExpandedPostTitle}>{post.title}<ShareIcon onClick={sharePost} /></h1>  
        
        {showShareBar &&
        <div ref={shareBarRef} className={styles.ExpandedPostPreviewShareBar}>
          {!!clipboardCopied && <span className={styles.clipboardCopiedIndicator}>Copied to clipbard!</span>}
          <input type="text" value={`${window.location.href}/${post.slug.current}`} />
          {!!navigator?.clipboard?.writeText && <Clipboard onClick={()=>{
            navigator.clipboard.writeText(`${window.location.href}/${post.slug.current}`)
            setClipboardCopied(true);
          }}/>}
        </div>}
      </div>
      <span className={styles.ExpandedPostContentDate}>{new Date(post._createdAt).toLocaleDateString('en-US')}</span>
      {renderedPostContent}
    </div>
  )
}

export default ExpandedPostContent