
import useWorldState from 'Components/World/WorldStateManager'

const DisteudoGreeting=({addPeriod})=>{
  const {disteudo} = useWorldState((state)=>({disteudo:state.disteudo}))
  if (!disteudo) return null;
  if (disteudo.name === "One-lifer"){
    return 'Welcome...one-lifer...'
  } 
  else if (disteudo.name.length) {
    return <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>
    <p>{`Welcome, ${disteudo.name}.`}</p>
    <img src={disteudo.faceUrl} alt={disteudo.name} style={{width:'215px'}} />
    </div>
  }
  else {
    return <div style={{display:'flex',flexDirection:'column', alignItems:'center'}}>
    <p>{`Welcome.`}</p>
    <img src={disteudo.faceUrl} alt={disteudo.name} style={{width:'215px'}} />
    </div>
  }
}

export default DisteudoGreeting