import useWorldState from 'Components/World/WorldStateManager';
import styles from './styles.module.scss';
import { useCallback } from 'react';

const WelcomeBack=({})=>{
  const {disteudo,navTo} = useWorldState(state=>({disteudo:state.disteudo,navTo:state.navTo}))
  const doNav = useCallback(()=>{
    if (!navTo) return
    let navDest
    switch (disteudo.lastSeenIn) {
      case "L2":
        navDest = "2";
        break;
      default:
        navDest = "2";
    }
    navTo(navDest)
  },[navTo])
  if (!disteudo) return;
  return (
    <div className={styles.WelcomeBack}>
      <h1>Welcome back, {disteudo.name}</h1>
      <img src={disteudo.faceUrl} />
      <h3>The elevator will now take you to {disteudo.lastSeenIn}</h3>
      <button onClick={doNav}>OK</button>
    </div>
  )
}

export default WelcomeBack