import styles from './styles.module.scss';

const PostLoadAnim=({})=>{
  return (
    <>
      <div className={styles.PostLoadAnim}>
       <div className={styles.LoadTile}></div>
      </div>
    </>
  )
}

export default PostLoadAnim