import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './styles.module.scss';
import useUIState from 'Components/UI/UIStateManager';
import { PortableText } from '@portabletext/react';
import {ReactComponent as EmailRush} from '../../assets/img/emailRush.svg';
import LinkedInLogo from '../../assets/img/LinkedInLogo.png';
import PostLoadAnim from 'Components/UI/PostLoadAnim';
import UPLogo from '../../assets/img/UP-lockup-square-black.png'
const WhoContent=({scrollRef=null,doScrollRef})=>{
  const {sanityClient,urlForSanityImage} = useUIState(state=>({sanityClient:state.sanityClient,urlForSanityImage:state.urlForSanityImage}))
  const [posts,setPosts] = useState(null)

  useEffect(()=>{
    if (!sanityClient) return
    const fetchPosts = async ()=>{
      const posts = await sanityClient.fetch('*[category=="who"]')
      setPosts(posts)
      setTimeout(()=>{
        try {
          doScrollRef.current(999999);
        }
        catch(err){
          //shhh
        }
      },100)
    }
    fetchPosts();
  },[sanityClient,doScrollRef])

  const doMailTo = useCallback(()=>{
    window.location.href = "mailto:teo@teolitto.com"
  },[])

  
  const contactLinks = useMemo(()=>{
    if (!doMailTo) return null;
    return (<>
      <div className={styles.contactIcons}>
        <a onClick={doMailTo}><EmailRush /></a>
        <a href="https://linkedin.com/in/teolitto" target="_blank"><img src={LinkedInLogo} /></a>
        <a href="https://ultraplush.io" target="_blank"><img src={UPLogo} /></a>
      </div>
    </>)
  },[doMailTo])

  const renderedContent = useMemo(()=>{
    if (!posts) return null
    const post = posts[0];
    return (<div className={styles.WhoContent}>
      <div className={styles.headshotWrapper}>
        <img className={styles.headshot} src={urlForSanityImage(post.coverImage).url()} />
      </div>
      <div>
        <PortableText
          value={post.content}
          components={{
            types: {
              image: ({value})=>{return <img src={urlForSanityImage(value)} />}
            }
          }}
        />
        {contactLinks}
      </div>
    </div>)
  },[posts,contactLinks]) 



  return (
    <div className={styles.panelBody}>
        <h1>Who?</h1>
        {renderedContent}
        {!renderedContent && <PostLoadAnim />}
    </div>
  )
}

export default WhoContent