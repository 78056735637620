import CryptoJS from "crypto-js";

const cryptoChipChop = (text,s)=>{
  let h = CryptoJS.AES.encrypt(text,s).toString();
  // const split = h.slice(0,5)
  // h = split[1]+split[0];
  const s1 = s.slice(0,5);
  const s2 = s.slice(5,s.length)
  const s3 = s2+s1
  return {h:h,s:s3}
}

const toMatrix = (arr, width) => 
arr.reduce((rows, key, index) => (index % width == 0 ? rows.push([key]) 
  : rows[rows.length-1].push(key)) && rows, []);


  
export {toMatrix,cryptoChipChop}