import useWorldState from "Components/World/WorldStateManager";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const HooksForZustand=({})=>{
  const navigate = useNavigate();
  const {navigationUtil, setNavigationUtil} = useWorldState(state=>({navigationUtil:state.navigationUtil,setNavigationUtil:state.setNavigationUtil}))
  useEffect(()=>{
    if (!!navigationUtil || !navigate || !setNavigationUtil) return
    setNavigationUtil(navigate)
  },[navigate,setNavigationUtil])
  return null
}

export default HooksForZustand