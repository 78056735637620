import styles from './styles.module.scss';
import GroundPlaneImg from 'assets/img/hubGroundPlate_compressed.png'
const GroundPlane=({})=>{
  return (
    <>
      <div className={styles.GroundPlane}>
        <img src={GroundPlaneImg} alt="" />
      </div>
    </>
  )
}

export default GroundPlane