import { useCallback, useRef } from "react";
import convoStyles from './convoStyles.module.scss'
import { gsap } from "gsap";
import BurnVid from 'assets/vid/burn.mp4';
const ConfirmDumpKeyModal = ({init1Lifer,closeModal})=>{
  const vidRef = useRef();

  const imgsWrapperRef = useCallback((node)=>{
    try {
      node.appendChild(document.querySelector('[data-purpose="keyWrapper"] svg').cloneNode(true))
      node.appendChild(document.querySelector('[data-purpose="faceWrapper"] img').cloneNode())
      const textInputValue = document.querySelector(`.${convoStyles.keyDispenseBottomRow} input`)?.value
      if (textInputValue) {
        const p = document.createElement('p')
        p.innerText = textInputValue
        node.appendChild(p)
      }
    }
    catch(err){
      //shh
    }
  },[]);

  const doBurn = useCallback(()=>{
    const vid = vidRef.current;
    const imgs = vidRef.current.parentElement.querySelectorAll('img,svg')
    const overlays = vidRef.current.parentElement.querySelector('div').querySelectorAll('span')
    const tl = gsap.timeline({
      paused: true,
      onComplete: ()=>{
        init1Lifer();
      }
    })
    tl.to(vid,{
      opacity: 1,
      duration: 0.25
    })
    tl.to(imgs,{
      opacity: 0,
      duration: 2.5,
    },"<")
    tl.to(vid,{
      opacity: 0,
      duration: 1.8
    },"<50%")
    tl.to(overlays[0],{
      opacity: 1,
      duration: 1.5,
      onUpdate:function(e){
        overlays[0].style.filter = `blur${ 20 - (this.ratio * 20) }px`
      }
    })
    tl.to(overlays[1],{
      opacity: 1,
      duration: 1.5,
      onUpdate:function(e){
        overlays[0].style.filter = `blur${ 20 - (this.ratio * 20) }px`
      },
    })
    tl.play()
    vidRef.current.play()
  },[]) 

  return (
    <div>
      <h2>Are you sure?</h2>
      <p>This will permanently destroy this key; beyond any recovery.</p>
      <div ref={imgsWrapperRef}>
        <div>
          <span>Key destroyed...</span>
          <span>Face destroyed...</span>
        </div>
        <video ref={vidRef} muted preload="true" playsInline={true}>
          <source src={BurnVid} type="video/mp4" />
        </video>
      </div>
      <div>
        <button onClick={closeModal}>Wait! I've changed my mind...</button><button onClick={doBurn}>I feel fear and/or ennui. Destroy.</button>
      </div>
    </div>
  )
}

export default ConfirmDumpKeyModal