import styles from './styles.module.scss';
import useUIState from 'Components/UI/UIStateManager'
import useWorldState from 'Components/World/WorldStateManager';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
const MainButtonOverlay = ()=>{
  const {isBriefcaseOpen,setIsBriefcaseOpen, isHubShown, setIsHubShown,currentHubPage} = useUIState(state=>({isBriefcaseOpen:state.isBriefcaseOpen,setIsBriefcaseOpen:state.setIsBriefcaseOpen,isHubShown:state.isHubShown,setIsHubShown:state.setIsHubShown,currentHubPage:state.currentHubPage,setCurrentHubPage:state.setCurrentHubPage}))
  const {isWorldPaused, setIsWorldPaused, isElevatorOpen, navTo, setFrameloop} = useWorldState(state=>({isWorldPaused:state.isWorldPaused,setIsWorldPaused:state.setIsWorldPaused,isElevatorOpen:state.isElevatorOpen,navTo:state.navTo,setFrameloop:state.setFrameloop}))
  const [isInited,setIsInited] = useState(false) 
  const route = useLocation();
  const {subpage} = useParams()
  const toggleBriefcase = useCallback(()=>{
    setIsBriefcaseOpen(!isBriefcaseOpen);
    // setIsWorldPaused(!isBriefcaseOpen);
  },[isBriefcaseOpen,setIsBriefcaseOpen])
  const toggleHub = useCallback(()=>{
    // setIsWorldPaused(!isWorldPaused);
    // setIsHubShown(!isHubShown);
    if(route.pathname.indexOf('/hub') !== -1) { // we are showing hub, close hub
      setIsWorldPaused(false);
      setFrameloop("demand");
      navTo('/')
    }
    else { // we are showing world hide hub
      setIsWorldPaused(true);
      setIsBriefcaseOpen(false);
      setTimeout(()=>{
        setFrameloop("never");
      },100)
      
      navTo(`/hub/${currentHubPage}`)
    }
  },[isWorldPaused,isHubShown,setIsWorldPaused,setIsHubShown,route,subpage])
  useEffect(()=>{
    if (route.pathname.indexOf('/hub') !== -1){
      setFrameloop("never")
    }
    else {
      setFrameloop("demand")
    }
  },[route])
  useEffect(()=>{
    setTimeout(()=>{
      setIsInited(true)
    },2500)
  },[])
  return (
    <div className={`${isInited? styles.MainButtonOverlay : styles.MainButtonOverlayHidden} ${isElevatorOpen? styles.disabledMainButtonOverlay : ''}`}>
      <button onClick={toggleHub} className={(isElevatorOpen)? styles.hiddenButton:null}><img src="https://assets.codepen.io/95637/trimmedEyeIcon.gif"/></button>
      <button onClick={toggleBriefcase}  className={(isElevatorOpen || route.pathname.indexOf('/hub') !== -1)? styles.hiddenButton:null}  ><img src="https://assets.codepen.io/95637/trimmedBriefcaseIcon.gif" /></button>
    </div>
  )
}

export default MainButtonOverlay;