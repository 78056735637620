import { useState } from 'react';
import KeyGiver from '../KeyGiver';
import styles from './styles.module.scss';
import ElevatorPanel from './ElevatorPanel';
const ElevatorInterior = ()=>{
  const [eleSolved,setEleSolved] = useState(false)
  return (
    <>
      <div className={styles.outerWrapper}>
        {!eleSolved && <div className={styles.wrapper}>
            <ElevatorPanel setEleSolved={setEleSolved}/>
          </div>}
        {eleSolved && <KeyGiver />}
      </div>
    </>
  )
}

export default ElevatorInterior