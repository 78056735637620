import styles from './styles.module.scss';

const ContentOverlay=({children})=>{
  return (
    <div className={styles.ContentOverlay}>
      {children}
    </div>
  )
}

export default ContentOverlay