import styles from './styles.module.scss';
import Briefcase from 'Components/World/Briefcase'
import { useCallback } from 'react';
import useUIState from 'Components/UI/UIStateManager';
import useWorldState from '../WorldStateManager';
const BriefcaseOverlay = ({})=>{
  const {setIsBriefcaseOpen} = useUIState(state=>({isBriefcaseOpen:state.isBriefcaseOpen,setIsBriefcaseOpen:state.setIsBriefcaseOpen}))
  const {setIsWorldPaused} = useWorldState(state=>({setIsWorldPaused:state.setIsWorldPaused}))
  const closeBriefcase = useCallback(()=>{
    setIsBriefcaseOpen(false)
    setIsWorldPaused(false)
  },[setIsBriefcaseOpen,setIsWorldPaused])
  return (
    <div className={styles.wrapper}>
      <Briefcase closeBriefcase={closeBriefcase} /> 
      <div className={styles.overlay} onClick={closeBriefcase}/>
    </div>
  )
}


export default BriefcaseOverlay