import useUIState from 'Components/UI/UIStateManager';
import styles from './styles.module.scss';
import { useCallback, useEffect, useRef, useState } from 'react';
import {ReactComponent as Thumbtack} from '../../assets/img/thumbtack.svg'
import {ReactComponent as ShareIcon} from '../../assets/img/shareIcon.svg'
import {ReactComponent as Clipboard} from '../../assets/img/clipboard.svg'
import useWorldState from 'Components/World/WorldStateManager';
import ReactGA from 'react-ga4';

const BasePostPreview=({post,expandPost,_key})=>{
  const {urlForSanityImage} = useUIState(state=>({urlForSanityImage:state.urlForSanityImage}))
  const {navTo} = useWorldState(state=>({navTo:state.navTo})) 
  const [showShareBar,setShowShareBar] = useState(false) 
  const [clipboardCopied,setClipboardCopied] = useState(false)
  const shareBarRef = useRef();
  const hideShareBar = useCallback((e)=>{
    if (!shareBarRef.current?.contains(e.target)){
      setShowShareBar(false)
      window.removeEventListener('click',hideShareBar)
    }
  },[showShareBar,setShowShareBar])

  const sharePost = useCallback((e)=>{
    e.preventDefault();
    e.stopPropagation();
    ReactGA.event({
      category: 'share',
      action: 'share-post',
      label: post.slug.current
    })
    if (navigator.share) {
      navigator.share({
        title: post.title,
        url: `${window.location.href}/${post.slug.current}`
      })
    }
    else {
      // console.log('!sf no navigator.share url is',`${window.location.href}/${post.slug.current}`)
      setShowShareBar(true)
      window.addEventListener('click',hideShareBar)
    }
  },[post,setShowShareBar,hideShareBar])

  useEffect(()=>{
    if (!!clipboardCopied) {
      setTimeout(()=>{
        setShowShareBar(false);
        setClipboardCopied(false);
      },1000)
    }
  },[clipboardCopied])

  return (
    <div className={styles.BasePostPreview} key={post.slug.current}>
      <div className={post.hasExpandableContent? styles.BasePostPreviewRowOneLinked : styles.BasePostPreviewRowOne}>
        <h3 style={{cursor:(post.hasExpandableContent)?'pointer':'unset'}} onClick={post.hasExpandableContent? ()=>{expandPost(post.slug.current)}:null}>{post.title}</h3>
        <span style={{cursor:(post.hasExpandableContent)?'pointer':'unset'}} onClick={post.hasExpandableContent? ()=>{expandPost(post.slug.current)}:null}> {new Date(post._createdAt).toLocaleDateString('en-US')}</span>
        {post.pinned? <Thumbtack className={styles.pinnedIndicator}/> : null}
        <ShareIcon className={styles.BasePostPreviewShareIcon} onClick={sharePost}/>
        {showShareBar &&
        <div ref={shareBarRef} className={styles.BasePostPreviewShareBar}>
          {!!clipboardCopied && <span className={styles.clipboardCopiedIndicator}>Copied to clipbard!</span>}
          <input type="text" value={`${window.location.href}/${post.slug.current}`} />
          {!!navigator?.clipboard?.writeText && <Clipboard onClick={()=>{
            navigator.clipboard.writeText(`${window.location.href}/${post.slug.current}`)
            setClipboardCopied(true);
          }}/>}
        </div>}
      </div>
      {!!post.coverImage && <img src={urlForSanityImage(post.coverImage).width(900).url()} onClick={post.hasExpandableContent? ()=>{expandPost(post.slug.current)} : null} />}
      <p>{post.excerpt}{post.hasExpandableContent? <span className={styles.expandPostCTA} onClick={()=>{expandPost(post.slug.current)}}> Read More »</span>: ''}</p>
    </div>
  )
}

export default BasePostPreview