import { useLayoutEffect, useRef, useState, useEffect, useCallback } from 'react';

import styles from './styles.module.scss';
// import EleTestPanel1 from 'assets/img/panel5x5_2_trim.png'
import ElePanelTrim from 'assets/img/elePanelTrim.png';
import {gsap} from 'gsap';
import ReactGA from 'react-ga4';
const solve = [
  [1,1,1,1,1],
  [0,0,1,0,0],
  [0,0,1,1,0],
  [0,0,1,1,0],
  [0,0,1,1,1]
]
const scanRowHangtime = 230;
const postScanSolveDelay = 700;
const scanResetTime = 400;
const baseImgWidth = 1000;

const ElevatorPanel=({setEleSolved})=>{
  const canvasRef = useRef();
  const imageRef = useRef();
  const rowsRef = useRef([]);
  const rowAnimsRef = useRef([]);
  const panelMargins = useRef({x:0,y:0});
  const panelGutters = useRef({x:0,y:0});
  const circleRadius = useRef(0);
  const circleScanOverlayMaxAlpha = useRef(1);
  const circleHoverOverlayMaxAlpha = useRef(0.7);
  const [ctx,setCtx] = useState();
  const buttonFadeInSpeed = useRef(0.01);
  const buttonFadeOutSpeed = useRef(2.5);
  const wrapperHasListener = useRef(false);
  const currentlyHoveredButton = useRef();
  const activeButtons = useRef([]);
  const actionButtonRef = useRef();
  const isScanning = useRef();
  const isSolved = useRef(false);
  const baseImgScale = useRef();


  useEffect(()=>{
    if (!ctx) return;
    window.addEventListener('resize',resetCanvasDims)
    return ()=>{window.removeEventListener('resize',resetCanvasDims)}
  },[ctx])
  // get/set context once on init
  useLayoutEffect(()=>{
    setCtx(canvasRef.current.getContext("2d"));
  },[])

  //once context + scale are set we can draw
  const wrapperRef = (node)=>{
    if (!node || !ctx) return;
    if (!wrapperHasListener.current) {
      node.addEventListener('mousemove',checkCursorMoveAgainstButtons)
      wrapperHasListener.current = true;
    }
    // populateCanvas();
  }

  const setCanvasDims = (e)=>{
    ctx.canvas.width = imageRef.current.width;
    ctx.canvas.height = imageRef.current.height;
    populateCanvas();
  }

  const setBaseDimensions = ()=>{
    
    panelMargins.current = {x:ctx.canvas.width*0.216,y:ctx.canvas.height*0.150};
    panelGutters.current = {x:0.140 * ctx.canvas.width,y:(0.127 * ctx.canvas.height)};
    circleRadius.current = (ctx.canvas.width*0.088)/2;
    baseImgScale.current = ctx.canvas.width / baseImgWidth;
  }

  const populateCanvas = ()=>{

    setBaseDimensions();

    ctx.clearRect(0,0,ctx.canvas.width,ctx.canvas.height)
    // ctx.drawImage(imageRef.current,0,0,ctx.canvas.width,ctx.canvas.height)
    initRows();
    initActionButton();
  }

  const resetCanvasDims = useCallback(()=>{
    if (!ctx) return;
    ctx.canvas.width = imageRef.current.width;
    ctx.canvas.height = imageRef.current.height;
    setBaseDimensions();
    initRows();
    initActionButton();
    redrawActiveButtons();
  },[ctx])

  const redrawActiveButtons = useCallback(()=>{
    if (!ctx) return;
    activeButtons.current.map((row,idx)=>{
      if (!row?.length) return
      redrawRowButtonsPostScan(idx)
    })
  },[ctx])

  const checkCursorMoveAgainstButtons = (e)=>{
    if (isScanning.current) {return}
    const cursorX = e.offsetX;
    const cursorY = e.offsetY;
    let isHovering = false;
    rowsRef.current.map((row,rowIdx)=>{
      row.map((circle,colIdx)=>{
        if (ctx.isPointInPath(circle,cursorX,cursorY)) {
          // addHoveredButton(circle,rowIdx,colIdx)
          isHovering = true;
          if (currentlyHoveredButton.current === circle) {return null} 
          if (activeButtons.current[rowIdx] && activeButtons.current[rowIdx][colIdx]) {return null}
          if (currentlyHoveredButton.current) {
            const currentlyHovered = currentlyHoveredButton.current
            drawDehoveredButton(currentlyHovered,currentlyHoveredButton.current.rowIdx,currentlyHoveredButton.current.colIdx)
          }
          currentlyHoveredButton.current = circle;
          drawHoveredButton(circle,rowIdx,colIdx);
        }
        return null;
      })
      if (ctx.isPointInPath(actionButtonRef.current,cursorX,cursorY)) {
        isHovering = true;
      }
      return null
    })
    if (isHovering) {
      // add cursor pointer class
      canvasRef.current.className = styles.hover;
    }
    else {
      // remove cursor pointer class
      canvasRef.current.className = '';
      // fade out currently hovered button and clear ref
      if (!!currentlyHoveredButton.current) {
        drawDehoveredButton(currentlyHoveredButton.current,currentlyHoveredButton.current.rowIdx,currentlyHoveredButton.current.colIdx);
        currentlyHoveredButton.current = null;
      }
    }
    return null;
  }

  const checkCanvasClickAgainstButtons = (e)=>{
    if (isScanning.current) {return}
    const cursorX = e.nativeEvent.offsetX;
    const cursorY = e.nativeEvent.offsetY;
    rowsRef.current.map((row,rowIdx)=>{
      row.map((circle,colIdx)=>{
        if (ctx.isPointInPath(circle,cursorX,cursorY)) {
          if (activeButtons.current[rowIdx] && activeButtons.current[rowIdx][colIdx]) {
            unsetActiveButton(circle,rowIdx,colIdx)
          }
          else {
            setActiveButton(circle,rowIdx,colIdx)
          }
          if (activeButtons.current[rowIdx] && activeButtons.current[rowIdx][colIdx]) {return null}
        }
        return null;
      });
      return null;
    })
    if (ctx.isPointInPath(actionButtonRef.current,cursorX,cursorY)) {
      doScan();
    }
  }

  const drawHoveredButton = (circle,rowIdx,colIdx)=>{
    if (activeButtons.current[rowIdx] && activeButtons.current[rowIdx][colIdx]) return;
    if (isScanning.current || isSolved.current) {return}
    // const offsetX = (panelMargins.current.x) + (panelGutters.current.x * circle.colIdx) - circleRadius.current;
    // const offsetY = (panelMargins.current.y) + (panelGutters.current.y * circle.rowIdx) - circleRadius.current
    let startAt = 0;
    const alreadyRunningAnim = rowAnimsRef.current[rowIdx][colIdx];
    if (alreadyRunningAnim) {
      startAt = 1 - alreadyRunningAnim.progress();
      alreadyRunningAnim.kill();
    }
    
    const hoverAnim = {t:0};
    const offsetX = (panelMargins.current.x) + (panelGutters.current.x * circle.colIdx) - circleRadius.current;
    const offsetY = (panelMargins.current.y) + (panelGutters.current.y * circle.rowIdx) - circleRadius.current;
    const scaledOffsetX = (offsetX-8)/baseImgScale.current;
    const scaledOffsetY = (offsetY-8)/baseImgScale.current;
    const scaledRadius = ((2*circleRadius.current)+20)/baseImgScale.current;
    rowAnimsRef.current[rowIdx][colIdx] = gsap.to(hoverAnim,{
      t: 1,
      duration: buttonFadeInSpeed.current,
      // ease: "linear",
      onUpdate: function(e){
        if (isScanning.current || isSolved.current) {return}
        // ctx.globalCompositeOperation = 'source-over'
        // ctx.drawImage(imageRef.current, scaledOffsetX, scaledOffsetY,scaledRadius,scaledRadius,offsetX-8,offsetY-8,((circleRadius.current*2)+(10/baseImgScale.current)),((circleRadius.current*2)+(10/baseImgScale.current)))
        // ctx.globalCompositeOperation = 'screen'
        ctx.fillStyle = `rgba(0,252,255,${hoverAnim.t*circleHoverOverlayMaxAlpha.current})`;
        ctx.fill(circle);
        // ctx.globalCompositeOperation = 'source-over'
        // ctx.drawImage(imageRef.current, scaledOffsetX, scaledOffsetY,scaledRadius,scaledRadius,offsetX-1,offsetY-1,((circleRadius.current*2)+2),((circleRadius.current*2)+2))
      },
      onComplete: function(e){
        
      }
    })
  }

  const drawDehoveredButton = (circle,rowIdx,colIdx)=>{
    if (activeButtons.current[rowIdx] && activeButtons.current[rowIdx][colIdx]) return;
    if (isScanning.current || isSolved.current) {return}
    let startAt = 0;
    const alreadyRunningAnim = rowAnimsRef.current[rowIdx][colIdx];
    if (alreadyRunningAnim) {
      startAt = 1 - alreadyRunningAnim.progress();
      alreadyRunningAnim.kill();
    }
    const fadeAnim = {t:startAt};
    const offsetX = (panelMargins.current.x) + (panelGutters.current.x * circle.colIdx) - circleRadius.current;
    const offsetY = (panelMargins.current.y) + (panelGutters.current.y * circle.rowIdx) - circleRadius.current;
    const scaledOffsetX = (offsetX-8)/baseImgScale.current;
    const scaledOffsetY = (offsetY-8)/baseImgScale.current;
    const scaledRadius = ((2*circleRadius.current)+20)/baseImgScale.current;
    // ctx.globalCompositeOperation = 'source-over'
    rowAnimsRef.current[rowIdx][colIdx]= gsap.to(fadeAnim,{
      t:1,
      duration:buttonFadeOutSpeed.current * (1 - startAt),
      // ease: "linear",
      onUpdate: function(e){
        if (isScanning.current || isSolved.current) {return}
        // ctx.globalCompositeOperation = 'source-over'
        // ctx.drawImage( imageRef.current, offsetX, offsetY, (circleRadius.current+7) * 2, (circleRadius.current+7) * 2,offsetX, offsetY, (circleRadius.current+7) * 2, (circleRadius.current+7) * 2,ctx.canvas.width,ctx.canvas.height);
        // ctx.drawImage(imageRef.current,offsetX,offsetY,circleRadius.current * 2, circleRadius.current * 2, 0,0, 10,10)
        // ctx.drawImage(imageRef.current, scaledOffsetX, scaledOffsetY,scaledRadius,scaledRadius,offsetX-8,offsetY-8,((circleRadius.current*2)+(10/baseImgScale.current)),((circleRadius.current*2)+(10/baseImgScale.current)))
        // ctx.globalCompositeOperation = 'screen'
        // ctx.fillStyle = `rgba(255,0,0,0.7)`;
        // ctx.fillRect(offsetX,offsetY,(circleRadius.current*2),(circleRadius.current*2))
        ctx.clearRect(offsetX,offsetY,circleRadius.current*2.1,circleRadius.current*2.1)
        ctx.fillStyle = `rgba(0,252,255,${circleHoverOverlayMaxAlpha.current-(fadeAnim.t*circleHoverOverlayMaxAlpha.current)})`; 
        ctx.fill(circle);
      },
      onComplete: function(e){
        const offsetX = (panelMargins.current.x) + (panelGutters.current.x * circle.colIdx) - circleRadius.current;
        const offsetY = (panelMargins.current.y) + (panelGutters.current.y * circle.rowIdx) - circleRadius.current;
        const scaledOffsetX = (offsetX-8)/baseImgScale.current;
        const scaledOffsetY = (offsetY-8)/baseImgScale.current;
        const scaledRadius = ((2*circleRadius.current)+20)/baseImgScale.current;
        ctx.clearRect(offsetX,offsetY,circleRadius.current*2.1,circleRadius.current*2.1)
        // ctx.globalCompositeOperation = 'source-over'
        // ctx.drawImage(imageRef.current, scaledOffsetX, scaledOffsetY,scaledRadius,scaledRadius,offsetX-8,offsetY-8,((circleRadius.current*2)+(10/baseImgScale.current)),((circleRadius.current*2)+(10/baseImgScale.current)))
      }
    })
  }

  const setActiveButton = (circle,rowIdx,colIdx)=>{
    const offsetX = (panelMargins.current.x) + (panelGutters.current.x * circle.colIdx) - circleRadius.current;
    const offsetY = (panelMargins.current.y) + (panelGutters.current.y * circle.rowIdx) - circleRadius.current;
    const scaledOffsetX = (offsetX)/baseImgScale.current;
    const scaledOffsetY = (offsetY)/baseImgScale.current;
    const scaledRadius = (2*circleRadius.current)/baseImgScale.current;
    // ctx.globalCompositeOperation = 'source-over';
    // ctx.drawImage(imageRef.current, scaledOffsetX, scaledOffsetY,scaledRadius,scaledRadius,offsetX,offsetY,circleRadius.current*2,circleRadius.current*2)
    // ctx.globalCompositeOperation = 'screen';
    ctx.fillStyle = "rgba(0,252,255,1)";
    ctx.fill(circle);
    if (!activeButtons.current[rowIdx]) {activeButtons.current[rowIdx] = []}
    activeButtons.current[rowIdx][colIdx] = 1
  }

  const unsetActiveButton = (circle,rowIdx,colIdx)=>{
    const offsetX = (panelMargins.current.x) + (panelGutters.current.x * circle.colIdx) - circleRadius.current;
    const offsetY = (panelMargins.current.y) + (panelGutters.current.y * circle.rowIdx) - circleRadius.current;
    const scaledOffsetX = (offsetX)/baseImgScale.current;
    const scaledOffsetY = (offsetY)/baseImgScale.current;
    const scaledRadius = (2*circleRadius.current)/baseImgScale.current;
    // ctx.globalCompositeOperation = 'source-over';
    // ctx.drawImage(imageRef.current, scaledOffsetX, scaledOffsetY,scaledRadius,scaledRadius,offsetX,offsetY,circleRadius.current*2,circleRadius.current*2)
    ctx.clearRect(offsetX,offsetY,circleRadius.current*2.1,circleRadius.current*2.1)
    // ctx.fillStyle = "rgb(0,0,255)";
    // ctx.fill(circle);
    if (!activeButtons.current[rowIdx]) {activeButtons.current[rowIdx] = []}
    activeButtons.current[rowIdx][colIdx] = 0
  }
  
    


  const drawRows = ()=>{
    // ctx.globalCompositeOperation = 'screen'
    ctx.fillStyle = "rgba(0,0,0,0)";
    rowsRef.current.map((row,rowIdx)=>{
      row.map((circle, colIdx)=>{
        ctx.fill(circle)
        return null
      })
      return null
    })
  }

  const initRow=(yPos,idx)=>{ // idx=y, i=x
    for (let i = 0; i < 5; i++) {
      const circle = new Path2D();
      circle.arc(panelMargins.current.x + (i * (panelGutters.current.x)), yPos, circleRadius.current, 0, 2 * Math.PI, false);
      // circle.arc(panelMargins.current.x, panelMargins.current.y, circleRadius.current, 0, 2 * Math.PI, false);
      circle.rowIdx = idx;
      circle.colIdx = i;
      rowsRef.current[idx][i] = circle;
      rowAnimsRef.current[idx][i] = null;
      // ctx.fillStyle='rgba(255,0,0,0.7)'
      // ctx.fill(circle);
    }
  }

  const initRows=()=>{
    rowsRef.current = [[]];
    rowAnimsRef.current = [[]]
    initRow(panelMargins.current.y + (0 * panelGutters.current.y),0)
    for (let i=1; i < 5; i++) {
      rowsRef.current[i] = [];
      rowAnimsRef.current[i] = [];
      initRow(panelMargins.current.y + (i * panelGutters.current.y),i)
    }
    drawRows();
  }

  const initActionButton = ()=>{
    const circle = new Path2D();
    circle.arc(ctx.canvas.width*0.496, ctx.canvas.height*.839, circleRadius.current, 0, 2 * Math.PI, false);
    actionButtonRef.current = circle;
  }

  const doScan = ()=>{
    if (isScanning.current || isSolved.current) {return}
    isScanning.current = true;
    const scanAnims = [];
    //check each row against solve and determine which animation to run (right,wrong,partial)
    let solveFailed = false;
    // let numWrong = 0;
    let rowMatchCounts = [];
    solve.map((row,rowIdx)=>{
      let thisRowMatches = 0;
      row.map((thisColSolve,colIdx)=>{
        // if (activeButtons.current[rowIdx]) {console.log(`!ds activeButtons.current[rowIdx][colIdx]${activeButtons.current[rowIdx][colIdx]} solve[rowIdx][colIdx]:${solve[rowIdx][colIdx]}`)}
        if (activeButtons.current[rowIdx] && activeButtons.current[rowIdx][colIdx] === thisColSolve) { 
          ++thisRowMatches;
        }
        else if (thisColSolve === 0 && (!activeButtons.current[rowIdx] || !activeButtons.current[rowIdx][colIdx])  ) {
          ++thisRowMatches;
        }
        else {
          solveFailed = true;
        }
        return null
      })
      rowMatchCounts.push(thisRowMatches);
      return null
    })
    //scan anim timeline
    //now that we know how many are correct on each row, we can generate the correct animation for each row and push it into scan timeline
    let scanTL = new gsap.timeline({
      paused: true,
      onComplete:function(e){
        if (!solveFailed){
          setTimeout(()=>{
            isScanning.current = false;
            isSolved.current = true;
            doSuccessfulSolve();
          },scanRowHangtime+postScanSolveDelay)
        }
        else {
          setTimeout(()=>{
            isScanning.current = false;
          },scanResetTime)
        }
      }
    })
    rowMatchCounts.map((rowMatchCount,rowIdx)=>{
      if (rowMatchCount === rowsRef.current[0].length) {
        drawRowCorrectOrIncorrect(scanTL,rowIdx,true);
      }
      else if (rowMatchCount === 0) {
        drawRowCorrectOrIncorrect(scanTL,rowIdx,false);
      }
      else {
        drawRowPartiallyCorrect(scanTL,rowIdx,rowMatchCount)
      }
    })
    scanTL.play();

    //analytics
    ReactGA.event({
      category: 'elevator-panel',
      action: 'elevator_panel-scan',
      label: `buttonStates:${JSON.stringify(activeButtons.current)}`
    })
  }

  const drawRowCorrectOrIncorrect = (scanTL,rowIdx,isCorrect)=>{
    //for each col in the row we need to tl.to with <
    rowsRef.current[rowIdx].map((circle,colIdx)=>{
      const fadeAnim = {t:0}
      const insertPos = (colIdx===0)? "<100%" : "<";
      const offsetX = (panelMargins.current.x) + (panelGutters.current.x * circle.colIdx) - circleRadius.current;
      const offsetY = (panelMargins.current.y) + (panelGutters.current.y * circle.rowIdx) - circleRadius.current;
      const scaledOffsetX = (offsetX-8)/baseImgScale.current;
      const scaledOffsetY = (offsetY-8)/baseImgScale.current;
      const scaledRadius = ((2*circleRadius.current)+20)/baseImgScale.current;
      const fill = (isCorrect)? `rgba(0,255,96,` : `rgba(255,0,0,`
      scanTL.to(fadeAnim,{
        t: 1,
        duration: 0.05,
        onUpdate: function(e){
          // ctx.globalCompositeOperation = 'source-over'
          // ctx.drawImage(imageRef.current, scaledOffsetX, scaledOffsetY,scaledRadius,scaledRadius,offsetX-8,offsetY-8,((circleRadius.current*2)+(10/baseImgScale.current)),((circleRadius.current*2)+(10/baseImgScale.current)))
          // ctx.globalCompositeOperation = 'screen'
          ctx.fillStyle = `${fill}${fadeAnim.t*circleScanOverlayMaxAlpha.current})`;
          ctx.fill(circle);
        },
        onComplete: function(){
          setTimeout(()=>{
            redrawRowButtonsPostScan(rowIdx);
          },scanRowHangtime)
        }
      }, insertPos)
    })
  }


  const drawRowPartiallyCorrect = (scanTL,rowIdx,correctCount)=>{
    rowsRef.current[rowIdx].map((circle,colIdx)=>{
      const fadeAnim = {t:0}
      const insertPos = (colIdx===0)? "<100%" : "<";
      const offsetX = (panelMargins.current.x) + (panelGutters.current.x * circle.colIdx) - circleRadius.current;
      const offsetY = (panelMargins.current.y) + (panelGutters.current.y * circle.rowIdx) - circleRadius.current;
      const scaledOffsetX = (offsetX-8)/baseImgScale.current;
      const scaledOffsetY = (offsetY-8)/baseImgScale.current;
      const scaledRadius = ((2*circleRadius.current)+20)/baseImgScale.current;
      const fill = (colIdx <= correctCount-1)? `rgba(0,255,96,` : `rgba(255,0,0,`
      scanTL.to(fadeAnim,{
        t: 1,
        duration: 0.05,
        onUpdate: function(e){
          // ctx.globalCompositeOperation = 'source-over'
          // ctx.drawImage(imageRef.current, scaledOffsetX, scaledOffsetY,scaledRadius,scaledRadius,offsetX-8,offsetY-8,((circleRadius.current*2)+(10/baseImgScale.current)),((circleRadius.current*2)+(10/baseImgScale.current)))
          // ctx.globalCompositeOperation = 'screen'
          ctx.fillStyle = `${fill}${fadeAnim.t*circleScanOverlayMaxAlpha.current})`;
          ctx.fill(circle);
        },
        onComplete: function(){
          setTimeout(()=>{
            redrawRowButtonsPostScan(rowIdx);
          },scanRowHangtime)
        }
      }, insertPos)
    })
  }

  const redrawRowButtonsPostScan = (rowIdx)=>{
    if (!activeButtons.current[rowIdx]) {
      // draw all blank btns
      rowsRef.current[rowIdx].map((col,colIdx)=>{
        const circle = rowsRef.current[rowIdx][colIdx];
        const offsetX = (panelMargins.current.x) + (panelGutters.current.x * circle.colIdx) - circleRadius.current;
        const offsetY = (panelMargins.current.y) + (panelGutters.current.y * circle.rowIdx) - circleRadius.current;
        const scaledOffsetX = (offsetX-8)/baseImgScale.current;
        const scaledOffsetY = (offsetY-8)/baseImgScale.current;
        const scaledRadius = ((2*circleRadius.current)+20)/baseImgScale.current;
        ctx.clearRect(offsetX,offsetY,circleRadius.current*2,circleRadius.current*2)
        // ctx.globalCompositeOperation = 'source-over';
        // ctx.drawImage(imageRef.current, scaledOffsetX, scaledOffsetY,scaledRadius,scaledRadius,offsetX-8,offsetY-8,((circleRadius.current*2)+(10/baseImgScale.current)),((circleRadius.current*2)+(10/baseImgScale.current)))
      })
    }
    else {
      //draw some active some blank
      rowsRef.current[rowIdx].map((circle,colIdx)=>{
        if(activeButtons.current[rowIdx] && activeButtons.current[rowIdx][colIdx]) {
          setActiveButton(rowsRef.current[rowIdx][colIdx],rowIdx,colIdx);
        }
        else {
          const offsetX = (panelMargins.current.x) + (panelGutters.current.x * circle.colIdx) - circleRadius.current;
          const offsetY = (panelMargins.current.y) + (panelGutters.current.y * circle.rowIdx) - circleRadius.current;
          const scaledOffsetX = (offsetX-8)/baseImgScale.current;
          const scaledOffsetY = (offsetY-8)/baseImgScale.current;
          const scaledRadius = ((2*circleRadius.current)+20)/baseImgScale.current;
          ctx.clearRect(offsetX,offsetY,circleRadius.current*2.1,circleRadius.current*2.1)
          // ctx.globalCompositeOperation = 'source-over';
          // ctx.drawImage(imageRef.current, scaledOffsetX, scaledOffsetY,scaledRadius,scaledRadius,offsetX-8,offsetY-8,((circleRadius.current*2)+(10/baseImgScale.current)),((circleRadius.current*2)+(10/baseImgScale.current)))
        }
      })
    }
  }

  const doSuccessfulSolve = ()=>{
    setEleSolved(true);

    //analytics
    ReactGA.event({
      category: 'elevator-panel',
      action: 'elevator_panel_successful-solve'
    })
  }

  return (
    <>
      <div className={styles.outerWrapper}>
        <div className={styles.wrapper} ref={wrapperRef}>
          <canvas ref={canvasRef} onClick={checkCanvasClickAgainstButtons} />
          <img src={ElePanelTrim} alt="" ref={imageRef} onLoad={setCanvasDims} />
        </div>
      </div>
    </>
  )
}

export default ElevatorPanel