import { useRef } from "react";
import { useLayoutEffect } from "react";
import { CeilVerts,CeilNormals,CeilUVs } from "./verts";
import { RepeatWrapping, BufferAttribute } from "three";
import CollisionPlane from './collisionPlane';
// import { BasisTextureLoader } from 'three/examples/jsm/loaders/BasisTextureLoader'
// import * as testTex from 'assets/tex/tile/sample_uastc_zstd.ktx2'
import useWorldState from "../WorldStateManager";
import { clamp,debounce } from "lodash";
import { useKTX2 } from "@react-three/drei";
import tileAlbedo from '../../../assets/tex/albedoCompressionTest2048.ktx2'
import tileRoughness from '../../../assets/tex/roughnessCompressionTest2048.ktx2'
import tileNormal from '../../../assets/tex/normalCompressionTest2048.ktx2'
import tileLighting from '../../../assets/tex/ceil_lightmap_w_bricklines_2048.ktx2'
const TileCeil = ({halfDims,roomDepth,lightCutDepth,lightCutWidth})=>{
  const worldRenderer = useWorldState(state=>state.worldRenderer)
  const geoRef = useRef();
  const matRef = useRef();
  const [tileAlbedoTex,tileRoughnessMap,tileNormalMap,tileLightmap] = useKTX2([tileAlbedo,tileRoughness,tileNormal,tileLighting])
  // const [maps,setMaps] = useState({albedo:null,roughness:null,normal:null,light:null})
  const colPlane = useRef();
  // const _setMaps = ()=>{
  //   setMaps({
  //     albedo: tileAlbedoTex.clone(),
  //     roughness: tileRoughnessMap.clone(),
  //     normal: tileNormalMap.clone(),
  //     light: tileLightmap
  //   })
  // }

  const setTiling = debounce(()=>{
    // return
    if (!tileAlbedoTex || !tileRoughnessMap || !tileNormalMap || !tileLightmap || !matRef.current) return;
    let tileS = clamp(roomDepth/ 40.25,1,5);
    let tileT = clamp(halfDims.x / 40.15,1,5);
    tileAlbedoTex.wrapS = RepeatWrapping
    tileAlbedoTex.wrapT = RepeatWrapping
    tileAlbedoTex.repeat.set(tileS,tileT)
    tileAlbedoTex.needsUpdate = true;
    tileRoughnessMap.wrapS = RepeatWrapping
    tileRoughnessMap.wrapT = RepeatWrapping
    tileRoughnessMap.repeat.set(tileS,tileT)
    tileRoughnessMap.needsUpdate = true;
    tileNormalMap.wrapS = RepeatWrapping
    tileNormalMap.wrapT = RepeatWrapping
    tileNormalMap.repeat.set(tileS,tileT)
    tileNormalMap.needsUpdate = true;

    // tie tex to matRef
    matRef.current.map = tileAlbedoTex;
    matRef.current.roughness = tileRoughnessMap;
    matRef.current.normal = tileNormalMap;
    matRef.current.light = tileLightmap;
    matRef.current.needsUpdate = true;
  },10)

  // useEffect(()=>{
  //   _setMaps();
  // },[tileAlbedoTex,tileRoughnessMap,tileNormalMap,tileLightmap])

  // useEffect(()=>{
  //   if (!maps) return
  //   setTiling();
  // },[maps])

  // size room to cam aspect and position cam to fit
  useLayoutEffect(()=>{
    if (!worldRenderer) return null;
    // set visible geo verts
    geoRef.current.setAttribute(
      "position",
      new BufferAttribute(
        CeilVerts(
          halfDims.x,
          halfDims.y,
          roomDepth,
          lightCutDepth,
          lightCutWidth
        ),
        3
      )
    );
    geoRef.current.setAttribute("uv", new BufferAttribute(CeilUVs, 2));
    geoRef.current.setAttribute("uv2", new BufferAttribute(CeilUVs, 2));
    geoRef.current.setAttribute("normal", new BufferAttribute(CeilNormals, 3));
    setTiling();
  },[halfDims,lightCutDepth,lightCutWidth,roomDepth])

  if (!worldRenderer) return null;
  return (
    <>
       {(halfDims.x === 0 && halfDims.y === 0)?null:<>
        <CollisionPlane rotation={[1.5708,0,0]} position={[0,halfDims.y,0]} name="wallNegY"/>
       </>}
       <mesh position-z={-roomDepth / 2.8} name="ceiling">
        <bufferGeometry ref={geoRef} attach="geometry" />
        <meshStandardMaterial ref={matRef} attach="material" map={tileAlbedoTex} normalMap={tileNormalMap} roughnessMap={tileRoughnessMap} lightMap={tileLightmap} color={0xcfcbbe} />
        {/* <meshBasicMaterial color={0xff0000} wireframe={true} /> */}
      </mesh>
    </>
  )


}

export default TileCeil