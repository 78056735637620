// import { UIContext } from "../UIContextManager"
import styles from './styles.module.scss';
// import { useSharedUIState } from "App";
import useUIState from "../UIStateManager";


const SimpleModal = ({modalContent})=>{
  const {simpleModalContent,setSimpleModalContent} = useUIState(state=>({simpleModalContent:state.simpleModalContent,setSimpleModalContent:state.setSimpleModalContent}))
  if (!simpleModalContent) {return null}
  const closeModal = ()=>{
    setSimpleModalContent(null);
  }

  return (<>
    <div className={styles.SimpleModal}>
      <div>
        {simpleModalContent}
      </div>
    </div>
  </>)
}

export default SimpleModal