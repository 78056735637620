import useUIState from 'Components/UI/UIStateManager';
import { useCallback, useEffect, useMemo, useState } from 'react';
import NewsPostPreview from 'Content/templates/NewsPostPreview';
import PostLoadAnim from 'Components/UI/PostLoadAnim';
import sharedContentStyles from '../sharedContentStyles.module.scss'
import ExpandedPostContent from 'Content/ExpandedPostContent';
import useWorldState from 'Components/World/WorldStateManager';

const NewsContent=({scrollRef=null,setExpandedPostContent=()=>{},postSlug,doScrollRef})=>{
  const {sanityClient} = useUIState(state=>({sanityClient:state.sanityClient}))
  const [posts,setPosts] = useState(null)
  const {navTo} = useWorldState(state=>({navTo:state.navTo})) 
  const expandPost = useCallback((postSlug)=>{
    if (!navTo) return
    navTo(`/hub/news/${postSlug}`)
  },[navTo])

  useEffect(()=>{
    if (!sanityClient) return
    const fetchPosts = async ()=>{
      const posts = await sanityClient.fetch('*[category=="news"]')
      posts.sort((a,b)=>{
        if (a.pinned && !b.pinned) {
          return -1
        }
        else if (b.pinned && !a.pinned) {
          return 1
        }
        else { // either both are pinned or both are unpinned, either way we sort by date
          return new Date(a.date).getTime() - new Date(b.date).getTime();
        }
      })
      setPosts(posts)
      console.log('posts',posts)
      setTimeout(()=>{
        try {
          doScrollRef.current(999999);
        }
        catch(err){
          //shhh
        }
      },120)
    }
    fetchPosts();
  },[sanityClient])

  const renderedPosts = useMemo(()=>{
    if (!posts) return null
    const _rendered = posts.map((post,idx)=>{
      if (idx === 0) return <NewsPostPreview key={post.slug.current} post={post} expandPost={expandPost} subpage={"news"}/>
      else return (<>
        <div className={sharedContentStyles.postSeparator}/>
        <NewsPostPreview key={post.slug.current} post={post} expandPost={expandPost} subpage={"news"}/>
      </>)
    })
    return _rendered
  },[posts])

  useEffect(()=>{
    if (!posts || !postSlug) return
    const post = posts.find(post => post.slug.current === postSlug)
    if (!post) {navTo(`/hub/news`); return}
    setExpandedPostContent(<ExpandedPostContent post={post}/>) 
  },[posts,postSlug])

  return (
    <div className={sharedContentStyles.panelBody}>
        <h1>News:</h1>      
        {renderedPosts}
        {!renderedPosts && <PostLoadAnim />}
    </div>
  )
}

export default NewsContent