import useWorldState from "../WorldStateManager";

const RenderList=({})=>{
  const {renderList} = useWorldState((state)=>({renderList:state.renderList}));

  return (
    <>
      {renderList}
    </>
  )
}

export default RenderList