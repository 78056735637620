import { useLayoutEffect,useRef } from 'react';
import styles from './styles.module.scss';
import {gsap} from 'gsap'

const GalaxyBG=({})=>{
  const galaxyRef = useRef();
  const tl = useRef();
  useLayoutEffect(()=>{
    const _tl = gsap.timeline({
      repeat: -1,
      delay: 0,
      repeatDelay: 0
    })
    _tl.to(galaxyRef.current,{
      backgroundPosition: '1200px -1200px',
      ease: 'none',
      delay: 0,
      duration: 150,
    })
    _tl.play();
    tl.current = _tl;
  },[])
  return (
    <>
      <div ref={galaxyRef} className={styles.GalaxyBG}>
        
      </div>
    </>
  )
}

export default GalaxyBG