import useWorldState from "../WorldStateManager"
import { Physics } from "@react-three/rapier";
import { useFrame } from "@react-three/fiber"
const DynamicPhysics = ({children,...props})=>{
  const {gravity,accel,accelAffectedBodies} = useWorldState(state=>({gravity:state.gravity,accel:state.accel,accelAffectedBodies:state.accelAffectedBodies}));
  
  useFrame(({frameloop})=>{

    if (frameloop === "never") {return}
    accelAffectedBodies.map((body)=>{
      let _forcemultiplier = 150;
      let accelAmt = !!body?.userData?.accelAmt? body.userData.accelAmt : 1;
      let _force = [
        -accel[0] * _forcemultiplier * accelAmt,
        -accel[1] * _forcemultiplier * accelAmt,
        -accel[2] * _forcemultiplier * accelAmt
      ];
      
      body.applyImpulse({x:_force[0],y:_force[1],z:_force[2]})
    })
  })
  

  return (
    <>
        <Physics gravity={gravity} >
          {children}
        </Physics> 
    </>
  )
}

export default DynamicPhysics