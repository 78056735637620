import styles from './styles.module.scss';
import GalaxyBG from '../GalaxyBG';
import GroundPlane from './groundPlane';
import HubNav from '../HubNav';
import ContentBorder from '../ContentBorder';
import ContentOverlay from '../ContentOverlay';
import { useParams } from 'react-router-dom';
import WhoContent from 'Content/Who';
import NewsContent from 'Content/News';
// import WorkContent from 'Content/Work';
import {useRef,useLayoutEffect, useEffect, useState} from 'react';
import HubScroll from '../HubScroll';
import { useLocation } from 'react-router-dom';
import useUIState from 'Components/UI/UIStateManager';
import useWorldState from 'Components/World/WorldStateManager';
import HooksForZustand from 'Components/HooksForZustand';
import ExpandedPostModal from '../ExpandedPostModal';
const Hub=({})=>{
  const pageLocation = useLocation();
  const {setHubContentRef,setHubScrollZoneRef,currentHubPage,setCurrentHubPage} = useUIState(state=>({setHubContentRef:state.setHubContentRef,setHubScrollZoneRef:state.setHubScrollZoneRef,setCurrentHubPage:state.setCurrentHubPage}))
  const [expandedPostContent,setExpandedPostContent] = useState(null)
  const {navTo} = useWorldState(state=>({navTo:state.navTo}))
  const contentRef = useRef();
  const scrollZone = useRef();
  const overflowWrapperRef = useRef();
  const {subpage,postSlug} = useParams();
  const doScrollRef = useRef();

  useLayoutEffect(()=>{
    setHubContentRef(contentRef);
    setHubScrollZoneRef(scrollZone);
  },[])

  useEffect(()=>{
    setCurrentHubPage(subpage)
  },[subpage])

  useEffect(()=>{
    if (!subpage && !!navTo) {
      navTo('/hub/work',true)
    }
    else if (!postSlug && !!expandedPostContent) {
      setExpandedPostContent(null)
    }
  },[subpage,navTo,expandedPostContent,postSlug])
  
  return (
    <div className={`scrollZone`} ref={scrollZone}>
      <HooksForZustand />
      <HubNav />
      {/* overlay */}
      <div className={styles.contentWrapper}>
        <ContentBorder>
          <ContentOverlay>
            <div ref={overflowWrapperRef} className={styles.scrollOverflowWrapper}>
              <div className={styles.scrollWrap}>
                <div ref={contentRef} style={{transform:'translate3d(0,0px,0)'}} className={styles.SharedContentWrapper}>
                  {subpage === 'who' && <WhoContent scrollRef={contentRef} doScrollRef={doScrollRef} setExpandedPostContent={setExpandedPostContent} postSlug={postSlug} />}
                  {/* {subpage === 'work' && <WorkContent scrollRef={contentRef} setExpandedPostContent={setExpandedPostContent} postSlug={postSlug} />} */}
                  {subpage === 'news' && <NewsContent scrollRef={contentRef} doScrollRef={doScrollRef} setExpandedPostContent={setExpandedPostContent} postSlug={postSlug} />}
                </div>
              </div>
            </div>
          </ContentOverlay>
        </ContentBorder>
      </div>
      <HubScroll overflowWrapperRef={overflowWrapperRef} doScrollRef={doScrollRef} expandedPostContent={!!expandedPostContent}/>
      {/* pyramid */}
      <GroundPlane />
      <GalaxyBG />
      {!!expandedPostContent && <ExpandedPostModal content={expandedPostContent} setContent={setExpandedPostContent} subpage={subpage}/>}
    </div>
  )
}

export default Hub