import styles from './styles.module.scss'
import FetCard from 'assets/img/FetDabrooskiCard_c7.jpg'
import { useEffect } from 'react';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
const FetCardOverlay = ({closeFetCard})=>{
  useEffect(()=>{
    const closeOnEsc = (e)=>{
      if (e.key === "Escape") {
        closeFetCard();
      }
    }
    window.addEventListener('keyup',closeOnEsc)
    return ()=>{window.removeEventListener('keyup',closeOnEsc)}
  },[])
  return (
     <>
     <div className={styles.transformWrap}>
        <TransformWrapper>
          <TransformComponent>
            <div className={styles.cardActualWrap} onClick={closeFetCard}>
              <img className={styles.cardActual} src={FetCard} alt="Fet Dabrooski General Guidance" />
            </div>
          </TransformComponent>
        </TransformWrapper>
      </div>
      <div className={styles.backplate} />
     </>
  )
}

export default FetCardOverlay