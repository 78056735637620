
const defaultCallbacks = {
  'start': ()=>{},
  'end': ()=>{},
  'goto': ()=>{},
  'callback':()=>{},
  'next':()=>{},
  'previous':()=>{},
  'restart':()=>{}
}

export default defaultCallbacks;