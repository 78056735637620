// import WallTextTex from 'assets/tex/wallTextTex1024.ktx2';
import { useThree } from "@react-three/fiber";
import { useKTX2 } from '@react-three/drei';
import WallTexture from '../../../assets/tex/WallTextTex1024_2.ktx2'
const aspect = 2;
const WallText=(props)=>{
  const {xDim,position} = props;
  // const meshRef = useRef();
  const yDim = xDim/aspect;
  const [wallTextTex] = useKTX2([WallTexture]);

  return (
    <>
      <mesh castShadow receiveShadow position={position} scale={[1,-1,1]}>
        <planeGeometry attach="geometry" args={[xDim, yDim, 1, 1]} />
        {/* <meshLambertMaterial attach="material" color="#ff0000" /> */}
        <meshStandardMaterial attach="material" map={wallTextTex} transparent={true} opacity={0.75}/>
      </mesh>
    </>
  )
}

export default WallText