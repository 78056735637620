import {create} from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware'
import useWorldState from 'Components/World/WorldStateManager';
import {createClient} from '@sanity/client'
import imageUrlBuilder from '@sanity/image-url'


const sanityClient = createClient({
  projectId:'s55bgshr', // TODO: put in env file
  dataset: 'production',
  useCdn: true,
  apiVersion: '2023-05-03'
})

const sanityImageBuilder = imageUrlBuilder(sanityClient)

const useUIState = create(subscribeWithSelector((set,get)=>({
  orientationPerms: !(typeof DeviceOrientationEvent !== 'undefined' && !!DeviceOrientationEvent && !!DeviceOrientationEvent.requestPermission), // some browsers automatically grant orientation perms, some do not, this set detects those which do and do not
  orientationPermsReqd: (typeof DeviceOrientationEvent !== 'undefined' && !!DeviceOrientationEvent && !!DeviceOrientationEvent.requestPermission), //inverse of the above - if orientationPerms are required we need them so false, if not required we don't need them so true by default
  setOrientationPermission: (newOrientationPerms)=>set(state=>({orientationPerms:newOrientationPerms})),
  simpleModalContent: null,
  setSimpleModalContent: (newSimpleModalContent)=>set(state=>({simpleModalContent:newSimpleModalContent})),
  tiltListeners: null,
  setTiltListeners:(newTiltListeners)=>set(state=>{return state.tiltListeners? state.tiltListeners:newTiltListeners}),
  tiltDeclined: false,
  setTiltDeclined: (newTiltDeclined)=>set(state=>({tiltDeclined:newTiltDeclined})),
  requestOSTiltPerms: ()=>{
    DeviceMotionEvent.requestPermission().then((resp)=>{
      if (resp==="granted") {
        set(state=>({orientationPerms:true}))
      }
    })
  },
  isBriefcaseOpen: false,
  setIsBriefcaseOpen: (newBriefcaseState)=>set(state=>({isBriefcaseOpen:newBriefcaseState})),
  isHubShown: false,
  setIsHubShown: (newHubState)=>{
    useWorldState.setState({frameloop: !!newHubState? 'never':'demand'})
    set(state=>({isHubShown:newHubState}))
  },
  currentHubPage: 'news',
  setCurrentHubPage: (newPage)=>{
    set(state=>({currentHubPage:newPage}))
  },
  hubScrollThumbHeight: 0,
  setHubScrollThumbHeight: (newHubScrollThumbHeight)=>set(state=>({hubScrollThumbHeight:newHubScrollThumbHeight})),
  // hubMinScrollHeight: 0,
  // setHubMinScrollHeight: (newHubMinScrollHeight)=>set(state=>({hubMinScrollHeight:newHubMinScrollHeight}))
  hubContentRef: undefined,
  setHubContentRef: (newHubContentRef)=>set(state=>({hubContentRef:newHubContentRef})),
  hubScrollZoneRef: undefined,
  setHubScrollZoneRef: (newHubScrollZoneRef)=>set(state=>({hubScrollZoneRef:newHubScrollZoneRef})),
  sanityClient: sanityClient,
  sanityImageBuilder: sanityImageBuilder,
  urlForSanityImage: (imageRef)=>{
    return get().sanityImageBuilder.image(imageRef)
  }
  // setRoomScale: (newRoomScale)=>set(state=>({roomScale:newRoomScale}))
})))

export default useUIState;


