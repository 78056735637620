import styles from './styles.module.scss'
import convoStyles from './convoStyles.module.scss'
import BlaBla from 'Components/BlaBla'
import {convo as KGConvo1,callbacks as KGCallbacks1} from './keyGiverConvo1';
import {useEffect,useState,useMemo,useRef,useCallback} from 'react';
import useWorldState from 'Components/World/WorldStateManager'
import gsap from 'gsap';
import hourglass from 'assets/img/hourglass.gif'
import ConfirmDumpKeyModal from './confirmKeyDumpModal';

const KeyGiver = ()=>{
  const [modalContent,setModalContent] = useState( null);
  const {writeDisteudo,navTo,setDisteudo} = useWorldState((state)=>({writeDisteudo:state.writeDisteudo,navTo:state.navTo,setDisteudo:state.setDisteudo}))
  const [convoStep,setConvoStep] = useState(0);
  const dumpKeyModalRef = useRef();
  const wrapperRef = useRef();


  const Modal = useCallback(({children, styles})=>{return <div className={styles.confirmDumpKeyModal} ref={dumpKeyModalRef} _styles={styles}>{children}<div onClick={closeModal} className={styles.modalBackdrop}/></div>},[])

  const closeModal = ()=>{
    gsap.to(dumpKeyModalRef.current,{
      opacity: 0,
      duration: 1,
      onComplete: function(e){
        setModalContent(null)
      }
    });
  }

  const openModal = (content)=>{
    setModalContent(content)
  }

  useEffect(()=>{
    if (!!modalContent && dumpKeyModalRef.current) {
      gsap.fromTo(dumpKeyModalRef.current,{opacity:0},{
        opacity: 1,
        duration: 1,
        delay: 0.1
      });
    }
  },[modalContent])



  

  const initNewDisteudo = useCallback(()=>{
    
    const key = document.querySelector('[data-purpose="keyWrapper"]').getAttribute('data-genKey')
    const name = document.querySelector('input').value;
    const faceUrl = document.querySelector('[data-purpose="faceWrapper"] img').getAttribute('src');
    const createdOn = Date.now();
    const lastSeen = Date.now();
    const lastSeenIn = "L2";
    
    const newDisteudo = {key:key,name:name,faceUrl:faceUrl,createdOn:createdOn,lastSeen:lastSeen,lastSeenIn:lastSeenIn};
    // console.log('newDisteudo',newDisteudo)
    writeDisteudo(newDisteudo)
    .then(()=>{
      setDisteudo(newDisteudo);
      setTimeout(()=>{
        setConvoStep(convoStep+1);
      },10)
    })
    .catch((err)=>{
      console.log('wild horses have come ',err)
    })    
  },[convoStep,writeDisteudo,setConvoStep,setDisteudo]);

  const init1Lifer = useCallback(()=>{
    const OneLifer = {
      key: "1Lifer",
      name: "One-lifer",
      face: hourglass,
      createdOn: 0,
      lastSeen: Date.now(),
      lastSeenIn: "L2"
    }
    setDisteudo(OneLifer);
    setConvoStep(convoStep+1);
    closeModal();
  },[convoStep,setConvoStep,setDisteudo])

  const decoratedCallbacks = useMemo(()=>{
    const _decoratedCallbacks = {
      confirmDumpKey:  (()=>{openModal(<ConfirmDumpKeyModal styles={styles} init1Lifer={init1Lifer} closeModal={closeModal} />)}),
      writeDisteudo:  ()=>{initNewDisteudo()},
      thisIsWeird: ()=>{alert('Life is weird.')},
      goL2: ()=>{navTo('2')}
    }
    Object.assign(_decoratedCallbacks,KGCallbacks1)
    return _decoratedCallbacks
  },[convoStep,initNewDisteudo])


  return (
    <div ref={wrapperRef} className={styles.KeyGiverWrapper}>
      {modalContent && <Modal styles={styles} >{modalContent}</Modal>}
      <BlaBla convo={KGConvo1} convoStep={convoStep} setConvoStep={setConvoStep} callbacks={decoratedCallbacks} styles={convoStyles}/>
    </div>
  )
}

export default KeyGiver