import React, { useCallback, useRef } from 'react';
import styles from './styles.module.scss'
import useUIState from '../UIStateManager';
const IOSPermissionButton = function({setInteracted}){
  const ref = useRef();
  const {setOrientationPermission} = useUIState((state)=>({setOrientationPermission:state.setOrientationPermission}))
  
  const closeModal = useCallback(()=>{
    setInteracted(true)
  },[])

  const getPermission = useCallback((e)=>{
    
    DeviceMotionEvent.requestPermission().then((resp)=>{
      if (resp === 'granted') {
        setOrientationPermission(true);
        closeModal();
      }
      else {
        closeModal();
      }
    })
  },[])
  return (
    <div className={styles.IOSPermissionButton}>
      {/* <div className={styles.header}>
        <button className={styles.closeBtn} onClick={closeModal} />
      </div> */}
      <div className={styles.body}>
        <h3>Enable motion controls?</h3>
        <div className={styles.buttonGang}>
          <button onClick={getPermission}>Yeah!</button>
          <button onClick={closeModal}>No!</button>
        </div>
      </div>
    </div>
  )
  // return (
  //     <button ref={ref} className="iosPermissionButton" onClick={getPermission}><span>{buttonText}</span></button>
  // )
}

export default IOSPermissionButton;
