import {useRef, useEffect} from 'react';
import {useGLTF} from '@react-three/drei'
import { useKTX2 } from '@react-three/drei';
import useWorldState from "../WorldStateManager"; 
import DieModelGLB from '../../../assets/models/dieModel.glb'
import DieDiff from '../../../assets/tex/dieDiffHiSat.ktx2'
import DieNormal from '../../../assets/tex/dieNorm.ktx2'
import ReactGA from 'react-ga4';
const Die = ({position,rotation, color, dice, idx, startSide,updateDieSide,DieOver,DieOut,DiePress})=>{
  const worldRenderer = useWorldState(state=>state.worldRenderer)
  const ref = useRef();
  const mySide = useRef(startSide);
  const dieModel = useGLTF(DieModelGLB);
  const [dieDiffTex,dieNormalTex] = useKTX2([DieDiff,DieNormal])
  useEffect(()=>{
    dice.current.push(ref);
  },[dice]);

  const iterateSide = ()=>{
    // DiePress();
    const priorSide = mySide.current;
    let newSide = ++mySide.current;
    if (newSide > 6) newSide = 1;
    updateDieSide(idx,priorSide,newSide); 
    mySide.current = newSide;

    //analytics
    ReactGA.event({
      category: 'BriefcaseDice',
      action: 'briefcaseDice_iterateSide',
      value: newSide,
      label: idx,
    })
  }
  return(
    <>
      <mesh castShadow receiveShadow geometry={dieModel.nodes['Cube_4'].geometry} position={position} rotation={rotation} ref={ref} onClick={iterateSide} onPointerOver={DieOver} onPointerOut={DieOut} onPointerDown={DiePress} onPointerUp={DieOver}>
        <meshStandardMaterial map={dieDiffTex} normalMap={dieNormalTex} roughness={1} />
      </mesh>
    </>
  )
}

export default Die