import styles from './convoStyles.module.scss';
import TestFace from 'assets/img/testFace.png';
import {useLayoutEffect,useRef,useEffect,useState} from 'react'
import gsap from 'gsap';
import QRCodeStyling from 'qr-code-styling';
import KeyIcon from 'assets/img/keyIcon7bb.png'
import hourglass from 'assets/img/hourglass.gif'
import DisteudoGreeting from './disteudoGreeting';
const publicBucketUrl = 'https://tldc23-public.s3.us-west-1.amazonaws.com';
const disteudoApiRoot = process.env.NODE_ENV === "development"? 'https://cjqhmo34dl.execute-api.us-west-1.amazonaws.com/dev' : 'https://txswgu8sch.execute-api.us-west-1.amazonaws.com'
// TODO RESTORE ABOVE BELOW IS FOR BUILDING ENVS
// const disteudoApiRoot = process.env.NODE_ENV !== "development"? 'https://cjqhmo34dl.execute-api.us-west-1.amazonaws.com' : 'https://txswgu8sch.execute-api.us-west-1.amazonaws.com'

const KeyDispenseWrapper = ()=>{
  const topRowRef = useRef();
  const keyWrapperRef = useRef();
  const faceWrapperRef = useRef()
  const generatedKey = useRef();
  const faceImgRef = useRef();
  const facesRemaining = useRef(1);
  const name = useState();
  const generateKey = ()=>{
    var tmp = new Uint8Array(Math.max((58)/2));
    crypto.getRandomValues(tmp);
    const genKey = Array.from(tmp)
      .map(n => ('0'+n.toString(16)).substr(-2))
      .join('')
      .substr(0,58);
    // _generatedKey = genKey;
    generatedKey.current = genKey;
    // QRCode.append(keyWrapperRef.current) 
  }

  const appendKey = ()=>{
    const QRCode = new QRCodeStyling({
        width: 315,
        height: 315,
        type: "canvas",
        data: generatedKey.current,
        image: KeyIcon,
        dotsOptions: {
            color: "#000000",
            type: "rounded"
        },
        backgroundOptions: {
            color: "#ffffff",
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 2
        }
    });
    QRCode.append(keyWrapperRef.current) 
    keyWrapperRef.current.setAttribute('data-genKey',generatedKey.current)
  }

  const generateAndAppendFace = ()=>{
    fetch(`${disteudoApiRoot}/requestFace`)
    .then(resp=>resp.json()).then(fileUrl=>{
      faceImgRef.current.setAttribute('src',`${publicBucketUrl}/${fileUrl}`);
    })
  }

  const userRequestNewFace = ()=>{
    // faceImgRef.current.setAttribute('src',data.faces[0].urls[4][512]);
    facesRemaining.current--;
    if (facesRemaining.current <= 0) {
      const btn = faceWrapperRef.current.querySelector('button')
      gsap.to(btn,{
        opacity: 0,
        duration: 0.7,
        onComplete: function(e){
          btn.remove();
        }
      })
    }
    faceImgRef.current.setAttribute('src',hourglass);
    faceImgRef.current.style.backgroundColor = "#fff";
    generateAndAppendFace();
  }

  useLayoutEffect(()=>{
    generateKey();
  },[])

  useEffect(()=>{
    appendKey();
    generateAndAppendFace();
  })

  useLayoutEffect(()=>{
    // const faceEl = topRowRef.current.querySelectorAll('div')[1];
    let fadeAnim = {t:0}
    gsap.to(fadeAnim,{
      t: 1,
      duration: 2,
      delay: 4,
      onUpdate:function(e){
        faceWrapperRef.current.style.opacity = fadeAnim.t;
        faceWrapperRef.current.style.filter = `blur(${20 -  (fadeAnim.t * 20)}px)`
      },
      onComplete:function(){
        const btn = faceWrapperRef.current.querySelector('button');
        gsap.to(btn,{
          opacity: 1,
          duration: 1,
          delay: 0.2
        })
      }
    })
  },[])
  return (
    <div className={styles.keyDispenseWrapper}>
      <div className={styles.keyDispenseTopRow} ref={topRowRef}>
        <div >
          <span>Here is your key.</span>
          {/* <img src={TestKey} ref={keyImageRef} alt="A key."/> */}
          <div ref={keyWrapperRef} className={styles.keyWrapper} data-purpose="keyWrapper"> </div>
        </div>
        <div style={{opacity:0}} ref={faceWrapperRef} data-purpose="faceWrapper">
          <span>Your key has a face:</span>
          <img ref={faceImgRef} alt="A key face."/>
          <button style={{opacity:0}} onClick={userRequestNewFace}>You may request a new face</button>
        </div>
      </div>
    </div>
  )
}

const KeyNameField = ()=>{
  const [keyName,setKeyName] = useState('');
  const sanitizeName = (e)=>{
    let newName = e.target.value.replace(/[^a-zA-Z0-9@!$*_\-?.\(\)\[\]\#\~\:]/gmi,'')
    newName = newName.substr(0,58)
    setKeyName(newName);
  }
  return (
    <div className={styles.keyDispenseBottomRow}>
      <span>Give your key a name: <i>(this will be permanent.)</i></span>
      <input type="text" value={keyName} onChange={sanitizeName} />  
    </div>
  )
}

const convo = {
  name: 'key-giver-convo',
  contentItems: [
    {
      content:[
        {
          item:"Welcome.",
          animInDelay: 1.3
        },
        {
          item:"I'm glad you could make it.",
          animInDelay: 0.9,
          autoFadeDelay: 1.5
        },
      ],
    },
    {
      content: [
        {
          item:"You had no promises and little guidance.",
        },
        {
          item:"Yet you saw the thread, you took the leaps, and now",
          animInDelay: 1.2,
        },
        {
          item:"here you are.",
          animInDelay: 1.95
        }
      ],
      autoFadeDelay: 3,
    },
    {
      content: [
        {
          item:"You have produced meaning from void.",
        },
        {
          item:"Let us consider that for a moment.",
          animInDelay: 1.5
        },
      ],
      autoFadeDelay: 3
    },
    {
      content: [
        {
          item:"What a lovely thing.",
          animInDelay: 3.25
        }
      ],
      autoFadeDelay: 4
    },
    {
      content: [
        {
          item:"In a moment, I'm going to give you an experimental key.",
          animInDelay: 0.3
        },
        {
          item:"This key will allow you to return to this place at roughly the same point from which you left, should you wish to do so.",
          animInDelay: 1.8
        },
        {
          item:<span>It will also give you a place to store any items<br /> that you may find here.</span>,
          animInDelay: 2.1
        },
        {
          item:"Your key will also describe a pseudonymous identity to represent you in this place.",
          animInDelay: 2.3,
          animInTime: 2
        },
      ],
      responses: [
        {
          text: "Ok",
          action: {
            type: "NEXT",// GOTO || END || CALLBACK || NEXT || PREVIOUS || RESTART
         }
        }
      ],
      responseAnimInDelay: 3.3,
      responseAnimInTime: 1,
      autoFadeDelay: 5,
    },
    {
      forceFadeout: true,
      content: [
        {
          item:<KeyDispenseWrapper />,
          animInDelay: 0.3
        },
        {
          item: <KeyNameField />,
          animInDelay: 5,
          parentClass: styles.keyDispenseTextRowWrapper
        },
        {
          item: <span className={styles.keyInfoText}>You can take a picture of this key (not the face)  with your phone, and scan it in when you want to return at<a href="https://teolitto.com/return" target="_blank" className={styles.returnLink} >teolitto.com/return</a>

          <p>Or, you can save it to your device and upload it when you want to return.</p>
          
          <p>Or, you can click <a className={styles.returnLink} style={{display:'inline',cursor:"pointer"}} onClick={()=>{var promise = navigator.clipboard.writeText(document.querySelector('[data-purpose="keyWrapper"]').getAttribute('data-genKey'))}}>here</a> to copy your key to your clipboard, to be stored in a password manager or offline, and you can paste it in when you want to return.</p>
          
          <p>Be aware: This key works like a password. </p>
          </span>,
          animInDelay: 3
        },
        {
          item: <p>All set?</p>,
          animInDelay: 1
        }
      ],
      responses: [
        {
          text: "I've saved it",
          action: {
            type: "CALLBACK",
            val: "writeDisteudo",
            params: {},
            shouldFadeOutPrior: true
          }
        },
        {
          text: "I haven't saved it",
          action: {
            type: "CALLBACK",
            val: "confirmDumpKey",
            params: {}
          }
        },
        {
          text: "This is weird",
          action: {
            type: "CALLBACK",
            val: "thisIsWeird"
          }
        },
      ],
      customResponsesWrapperClass: styles.keyDispenserResponsesWrapper
    },
    {
      content: [
        {
          item: <DisteudoGreeting />,
          animOutDelay: 2,
        },
        {
          item: "The elevator will now take you to L2.",
          animInDelay: 1,
          animOutDelay: 3
        }
      ],
      responses: [
        {
          text:"Ok",
          action: {
            type: "CALLBACK",
            val: "goL2",
            shouldFadeOutPrior: true
          }
        }
      ]
    },
  ], 
}
 
const callbacks = {
  testCallback: ()=>{
    alert('testCallback!')
  },
}

export {convo, callbacks}