import styles from './styles.module.scss';
import useWorldState from 'Components/World/WorldStateManager';
import { useLayoutEffect, useRef, useState,useEffect } from 'react';
import { ListTablesCommand } from '@aws-sdk/client-dynamodb';
import { GetCommand } from '@aws-sdk/lib-dynamodb';
// import {ReactComponent as WebCamIcon} from 'assets/img/webcam1.svg'
// import {ReactComponent as UploadIcon} from 'assets/img/upload1.svg'
// import {ReactComponent as PasteTextIcon} from 'assets/img/clipboard4.svg'
import MarbleButtonCam from 'assets/img/marbleButtonCam.png';
import MarbleButtonUpload from 'assets/img/marbleButtonUpload.png';
import MarbleButtonClipboard from 'assets/img/marbleButtonClipboard.png';
import QrScanner from 'qr-scanner'; 
import { debounce } from 'lodash';
import WelcomeBack from 'Components/WelcomeBack';

const Return=({})=>{
  const {setDisteudo,fetchDisteudo} = useWorldState((state)=>({setDisteudo:state.setDisteudo,fetchDisteudo:state.fetchDisteudo}))
  const textInputRef = useRef();
  const [inputVisible,setInputVisible] = useState(null); 
  const [responseText,setResponseText] = useState(null);
  const fileToUpload = useRef(null);
  const [uploadPreview,setUploadPreview] = useState(null);
  const [showFileSubmit,setShowFileSubmit] = useState(false);
  const uploadedFileText = useRef(null);
  const uploadedFileURI = useRef();
  const camScanOutput = useRef();
  const vidRef = useRef();
  const camQRScanner = useRef();
  const fetchingDisteudo = useRef(null);
  const userCamStream = useRef(null);
  const [scannedDisteudo,setScannedDisteudo] = useState(null)

  // const simulateScan = ()=>{
  //   setDisteudo({disteudoKey:"lorem"})
  // }



  useEffect(()=>{
    //dismount
    return function cleanup(){
      try {
        // console.log('DISMOUNTING RETURN')
        camQRScanner.current.stop();
        userCamStream.getTracks().forEach(function(track) {
          track.stop();
        });
        vidRef.current.stop();
        vidRef.current.srcObject = null;
      }
      catch(err){
        //nah
      }
    }
  },[])

  useLayoutEffect(()=>{
    if (inputVisible==="cam") {
      vidRef.current.onloadedmetadata = function(e) {
        vidRef.current.play();
        const _camQRScanner =  new QrScanner(
          vidRef.current, 
          debounce((result) => {
            // console.log('decoded qr code:', result);
            if (result?.data?.length) {
              fetchDisteudo(result.data)
              .then((data)=>{
                // console.log('retrieved disteudo:',data)
                setDisteudo(data);
                setScannedDisteudo(data);
              })
              .catch((err)=>{
                if (err === "notFound") {
                  alert("Sorry this key was not found.")
                }
              })
            }
          },250),
          {
            highlightScanRegion:true,
            calculateScanRegion: ()=>{return {width: vidRef.current.videoWidth / 1.7 , height: vidRef.current.videoHeight, x: (vidRef.current.videoWidth / 2) - ( (vidRef.current.videoWidth / 1.7) / 2) , y:(vidRef.current.videoHeight / 2) - ( vidRef.current.videoHeight / 2),downScaledWidth: 500, downScaledHeight: 500}}
          });
        camQRScanner.current = _camQRScanner;
        _camQRScanner.start()
        // console.log('camQRScanner.current',camQRScanner.current)
      }
      // camQRScanner.current = new QrScanner(vidRef.current, result => console.log('decoded qr code:', result));
    }
  },[inputVisible])

  const onFileChange = (e)=>{
    // console.log('!db onFileChange e',e,'e.target.files[0]',e.target.files[0])
    fileToUpload.current = e.target.files[0];
    // console.log('!db onFileChange fileToUpload.current is now',fileToUpload.current.name)
    if (fileToUpload?.current?.size > 5000000) { // larger than 5MB
      // console.log('!db upload too large')
      setResponseText("Sorry, this file is too large."); 
      return
    } 
    if (fileToUpload?.current?.type === "image/png" || fileToUpload.current.type ===  "image/jpg" || fileToUpload.current.type ===  "image/jpeg") {
      // console.log('!db image type')
      setResponseText(null); 
      parseImageUpload();
    }
    else if (fileToUpload?.current?.type === "text/plain") {
      // console.log('!db text type')
      setResponseText(null); 
      parseTextUpload();
    } 
    else {
      // console.log('!db invalid type, type was', fileToUpload.current.type)
      setResponseText("Sorry, that does not appear to be an image or text file.");
      return;
    }
  }

  const parseImageUpload =()=>{
    const reader = new FileReader();
    reader.onload = (e)=>{
      // this.setState({imageURI:e.target.result});
      //e.target.result
      // setUploadPreview(e.target.result)
      // console.log('image read:',e)
      uploadedFileURI.current = e.target.result;
      const img = <img src={uploadedFileURI.current} />
      // console.log('img =',img)
      setUploadPreview(img)
      setShowFileSubmit(true);
      // DO SCAN
    };
    reader.readAsDataURL(fileToUpload.current);
  }

  const parseTextUpload = ()=>{
    // console.log('parsing text')
    const fileReader = new FileReader();
    fileReader.onload = (e)=>{
      const loadedText = e.target.result.replace(/\W/,''); // sanitize
      uploadedFileText.current = loadedText;
      const previewText = loadedText.substr(0,5);
      // console.log('parseTextUpload, loadedText:',loadedText)
      setUploadPreview(<p>{`${previewText} 	••••`}</p>)
      setShowFileSubmit(true)
      fetchDisteudo(loadedText)
      .catch((err)=>{
        if (err === "notFound") {
          alert("Sorry this key was not found.")
        }
      })
    }
    fileReader.readAsText(fileToUpload.current, "UTF-8")
    
  }

  const doScan=async ()=>{
    setResponseText(null);
    // console.log('doScan')
    // debugger;
    if (inputVisible === "none") return
    else if (inputVisible === "cam") {
      return
    }
    else if (inputVisible === "upload") {
      if (uploadedFileText.current) { //text
        const keyVal = uploadedFileText.current;
        if (!keyVal || !keyVal.length) return
        // console.log('!db uploadedFileText val',keyVal);
        // debugger;
        fetchDisteudo(`${keyVal}`)
        .then((data)=>{
          // console.log('retrieved disteudo:',data)
          setDisteudo(data);
          setScannedDisteudo(data);
        })
        .catch((err)=>{
          if (err === "notFound") {
            alert("Sorry this key was not found.")
          }
        });
      }
      else if (uploadedFileURI.current) { //img
        // console.log('uploadedFileURI.current',uploadedFileURI.current)
        QrScanner.scanImage(uploadedFileURI.current,{alsoTryWithoutScanRegion:true})
        .then((scanVal)=>{
          // console.log('QR scan of static image complete:',scanVal)
          fetchDisteudo(scanVal.data)
          .then((data)=>{
            // console.log('retrieved disteudo:',data)
            setDisteudo(data);
            setScannedDisteudo(data);
          })
          .catch((err)=>{
            if (err === "notFound") {
              alert("Sorry this key was not found.")
            }
          })
        })
        .catch((err)=>{
          console.log('err scanning image',err)
          onScanFailure();
        })
        // QrScanner.scanImage(uploadedFileURI.current).then((scanVal)=>{
        //   console.log('QR scan of static image complete:',scanVal)
        //   if (!scanVal) {
        //     return
        //   }
        //   fetchDisteudo(scanVal)
        //   .then((data)=>{
        //     console.log('retrieved disteudo:',data)
        //     setDisteudo(data);
        //     setScannedDisteudo(data);
        //   })
        // }).catch((err)=>{
        //   console.log('QR scan of static image fail',err)
        //   onScanFailure();
        // })
      }
    } 
    else if (inputVisible === "paste") {
      const keyVal = textInputRef.current.value;
      if (!keyVal || !keyVal.length) return
      // console.log('!db textInputRef val',keyVal);
      fetchDisteudo(keyVal)
      .then((data)=>{
        // console.log('retrieved disteudo:',data)
        setDisteudo(data);
        setScannedDisteudo(data);
      })
      .catch((err)=>{
        if (err === "notFound") {
          alert("Sorry this key was not found.")
        }
      })
    }
  }

  const onScanFailure=()=>{
    setResponseText("Sorry, this key did not scan.")
  }

  const toggleCam=()=>{
    if (inputVisible === "cam") {
      setInputVisible("none")
      //stop webcam
    }
    else {
      setInputVisible("cam")
      //start webcam
      navigator.mediaDevices.getUserMedia({video:{facingMode:'environment'},audio:false}).then((stream)=>{
        // debugger;
        // console.log('stream',stream)
        if ("srcObject" in vidRef.current) {
          vidRef.current.srcObject = stream;
        } else {
            vidRef.current.src = window.URL.createObjectURL(stream);
        }
        
        // vidRef.current.addEventListener('',playVid)
        // setTimeout(playVid,100)
        // vidRef.current.play();
        // camQRScanner.current = new QrScanner(vidRef.current, result => console.log('decoded qr code:', result));
      })
    }
  }
  const toggleUpload=()=>{
    if (inputVisible === "upload") {
      setInputVisible("none")
    }
    else {
      setInputVisible("upload");
    }
  }
  const togglePaste=()=>{
    if (inputVisible === "paste") {
      setInputVisible("none")
    }
    else {
      setInputVisible("paste");
    }
  }


  if (!!scannedDisteudo) {
    return <WelcomeBack />  
  }
  return (
    <>
      <div className={styles.ReturnWrapper}>
        {/* <button onClick={simulateScan}>Simulate scan-in</button> */}
        <h1>Welcome.</h1>
        <p>Please scan in.</p>
        <div className={styles.buttons}>
          <button className={styles.camBtn} onClick={toggleCam} >
            {/* <WebCamIcon title="Scan Key"/> */}
            <img src={MarbleButtonCam} alt="Scan Key" />
          </button>
          <button className={styles.uploadBtn} onClick={toggleUpload}>
            {/* <UploadIcon title="Upload Key" />   */}
            <img src={MarbleButtonUpload} alt="Upload Key" />
          </button>
          <button className={styles.pasteBtn} onClick={togglePaste}>
            {/* <PasteTextIcon title="Paste Text Key" />   */}
            <img src={MarbleButtonClipboard} alt="Paste Text Key" />
          </button>
        </div>
        <div className={styles.inputs}>
          {/* webcam view */}
          {inputVisible === "cam" && <div className={styles.camView}>
            <video className={styles.userVid} ref={vidRef}/>
            {camScanOutput.current && <>{camScanOutput.current}</>}
          </div> }
          {/* upload view */}
          {inputVisible === "upload" && <div className={styles.uploadView}>
            {/* <label for="myfile">Select a file:</label> */}
            <input type="file" id="myfile" name="myfile" onChange={onFileChange} />
            {uploadPreview && <>{uploadPreview}</>}
            {showFileSubmit && <button onClick={doScan}>Submit</button>}
          </div>}
          {/* paste view */}
          {inputVisible==="paste" && <><textarea style={{width:'100%'}} ref={textInputRef}></textarea><button onClick={doScan}>Submit</button></>}
        </div>
        {responseText &&<div className={styles.response}>
          <h2>{responseText}</h2>
        </div>}
      </div> 
    </>
  )
}

export default Return